import { FormLabel as Label } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Config from '../../../../config';
import * as W05F0005Actions from '../../../../redux/W0X/W05F0005/W05F0005_actions';
import Modal from '../../../common/modal/modal';
import { ButtonIcon, MoneyInput, TextInput } from 'diginet-core-ui/components';

const styles = {
    textBoxField: {
        height: '30px !important',
        minWidth: '100px !important',
        fontWeight: 'normal',
        margin: 0,
        backgroundColor: '#f6f6f9 !important',
        '&.quantity ': {
            width: '150px !important',
        },
        '& .DGN-UI-InputBase': {
            height: '100%',
            backgroundColor: 'transparent',
        },
        '& input': {
            fontSize: '16px !important',
            color: '#7F828E',
            lineHeight: '20px',
        },
        '& div.dx-placeholder': {
            top: '-4px !important'
        }
    },
    currentFirstField: {
        textTransform: 'uppercase',
        paddingLeft: 14,
        fontSize: '16px',
        color: '#151A30',
        lineHeight: '20px',
    }
};

class W05F0005RequestLotPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataGrid: [],
            rowData: "",
            quantity: "",
            btnSaveStatus: false,
        }
        this.displayData = [];
    }

    componentDidMount = () => {
        let { rowData } = this.props;
        const tempData = { ...rowData };
        const dataGrid = this.state.dataGrid;
        dataGrid.push(tempData);
        this.setState({ quantity: rowData.Quantity, rowData: tempData, dataGrid });
    }

    setValueLot = (e, name) => {
        const { dataGrid } = this.state;
        dataGrid.forEach(item => {
            if (item.LotNoID === name) {
                item.LotNo = e.target.value;
                return;
            }
        });
    }

    countDecimals = (value) => {
        if (Math.floor(value) === value) return 0;
        return value.toString().split(".")[1].length || 0;
    }

    setValueQuantity = async (e, name) => {
        const value = e.value;
        const { dataGrid, quantity } = this.state;
        let { isEdit, ExchangeRate, decimalScale } = this.props;
        let array = [...dataGrid];
        let rowData = array[0];
        let temp = 0;
        const dataFilter = array.filter((rs, index) => index !== 0);
        dataFilter.forEach((item, index) => {
            if (name === item.LotNoID) {
                temp += value;
            } else {
                temp += item.Quantity;
            }
        });
        if (Number(quantity) > temp && value && value.toString().indexOf('-') === -1) {
            const qty = quantity - temp;
            rowData.Quantity = Number(this.countDecimals(qty) <= decimalScale ? qty : qty.toFixed(decimalScale));
            rowData.Amount = rowData.Quantity * rowData.UnitPrice;
            rowData.CAmount = rowData.Quantity * rowData.UnitPrice * rowData.ExchangeRate;
            array.map((item) => {
                if (item.LotNoID === name) {
                    // item.fumigation = {
                    //     detail: [],
                    //     method: [],
                    // };
                    item.Quantity = value;
                    item.Amount = value * item.UnitPrice;
                    item.CAmount = isEdit ? value * item.UnitPrice * ExchangeRate : value * item.UnitPrice * item.ExchangeRate;

                } else {
                    item.CAmount = isEdit ? value * item.UnitPrice * ExchangeRate : value * item.UnitPrice * item.ExchangeRate;
                }
                return item;
            });
            this.setState({ dataGrid: array, disabled: false });
        } else {
            const current = dataFilter.find(rs => name === rs.LotNoID);
            if (e.target.value === '') {
                const index = array.findIndex(rs => rs.LotNoID === name);
                rowData.Quantity = rowData.Quantity + current.Quantity;
                rowData.Amount = rowData.Quantity * rowData.UnitPrice;
                rowData.CAmount = rowData.Quantity * rowData.UnitPrice * rowData.ExchangeRate;
                array[index].Quantity = 0;
                this.setState({ dataGrid: array, disabled: false });
                return;
            } else {
                e.target.blur();
                Config.popup.show("INFO", Config.lang("ERP_So_luong_khong_hop_le"));
                e.target.value = current.Quantity;
            }
        }
    }

    renderData = (key, quantity) => {
        const { classes, decimalScale } = this.props;
        return (
            <div key={key}>
                <div className={"center-row pd10"} style={{ justifyContent: 'space-between', backgroundColor: 'rgba(17, 29, 94, 0.04)', height: 40, }}>
                    <TextInput placeholder={Config.lang("ERP_Nhap")} className={classes.textBoxField}
                        viewType={"outlined"} onChange={(e) => this.setValueLot(e, key)} value={key} />
                    <MoneyInput placeholder={Config.lang("ERP_Nhap")} className={`${classes.textBoxField} quantity`}
                        thousandSeparator={','} decimalDigit={decimalScale} allowZero
                        viewType={"outlined"} onChange={(e) => this.setValueQuantity(e, key)} value={quantity} />
                </div>
            </div>
        )
    }

    numberFormat = (number, limitNumber = 0) => {
        const formatter = new Intl.NumberFormat("en-US", {
            minimumFractionDigits: limitNumber,
            maximumFractionDigits: limitNumber,
        });
        const _number = formatter.format(String(number));
        return !_number || _number === "NaN" ? 0 : _number;
    };

    onTachLot = () => {
        const { dataGrid } = this.state;
        let array = [...dataGrid];
        let rowData = { ...dataGrid.slice(-1).pop() };
        let LotNo = rowData.LotNo.substring(rowData.LotNo.lastIndexOf(".") + 1, rowData.LotNo.length);
        LotNo = (/^\d+$/).test(LotNo) ? Number(LotNo) + 1 : LotNo + 1;
        LotNo < 10 ? LotNo = rowData.LotNo.substring(0, rowData.LotNo.lastIndexOf(".") + 1) + "0" + LotNo : LotNo = rowData.LotNo.substring(0, rowData.LotNo.lastIndexOf('.') + 1) + LotNo
        rowData.LotNoID = LotNo;
        rowData.Quantity = 0;
        rowData.LotNo = LotNo;
        rowData.Amount = 0;
        rowData.CAmount = 0;
        array.push(rowData);
        this.setState({ dataGrid: array })
        this.displayData.push(this.renderData(LotNo, 0))
    }

    onSave = () => {
        const { dataGrid, rowData } = this.state;
        let lots = [];
        let lotNos = [];
        dataGrid.forEach(item => {
            lots.push({
                NewLotNo: item.LotNo,
                Quantity: item.Quantity,
            });
            lotNos.push(item.LotNo);
        });
        const params = {
            QuotationID: rowData.QuotationID || "",
            QuotationItemID: rowData.QuotationItemID || "",
            lots: JSON.stringify(lots),
            IsApprove: 0,
            UserName: "",
            LotNo: rowData.LotNo || "",
        };
        const isValid = dataGrid.find(rs => !rs.Quantity);
        if (isValid) {
            Config.popup.show("INFO", Config.lang("ERP_So_luong_khong_hop_le"));
            return;
        }
        const uniqueLotNo = [...new Set(lotNos)];
        if (uniqueLotNo.length < lots.length) { // KIểm tra không được trùng LotNo
            Config.popup.show("INFO", Config.lang("ERP_Khong_duoc_nhap_trung_so_lot"));
        } else {
            this.props.W05F0005Actions.saveRequestLot(params, (error, data) => {
                if (error) {
                    let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                    Config.popup.show("INFO", message);
                    return false;
                } else if (data) {
                    Config.notify.show("success", Config.lang("ERP_Luu_thanh_cong"), 2000);
                    this.props.onCloseLOT("");
                }
            })
        }
    }

    onClose = () => {
        this.props.onCloseLOT("");
    }

    showPopup = (data, dataField) => {
        const value = data ? data.replace(/,/g, ';').split(';') : [];
        this.popupCompany.tmpSelectedRowKeys = value;
        setTimeout(() => {
            this.popupCompany && this.popupCompany.open();
        }, 100);
    };

    render() {
        let { opendLot, classes, decimalScale } = this.props;
        let { dataGrid, btnSaveStatus } = this.state;
        return (
            <div>
                <Modal open={opendLot}
                    fullWidth={true}
                    maxWidth={"md"}
                >
                    <Modal.Title disableTypography>
                        <div className={"display_row align-center align-between flex-wrap"} style={{ width: '100%' }}>
                            <div className={"display_row align-center"}>
                                <Typography variant={"h6"} className={"mgr10"}>{Config.lang(`${"ERP_Yeu_cau_tach_lot"}`)}</Typography>
                            </div>
                            <div>
                                <ButtonIcon circular viewBox viewType="text" name="save" color="primary" onClick={this.onSave}
                                    className={"mgr10"} disabled={btnSaveStatus} size="small" />
                                <ButtonIcon circular viewBox viewType="text" name="close" color="primary" onClick={this.onClose} size="small" />
                            </div>
                        </div>
                    </Modal.Title>

                    <Divider />
                    <Modal.Content>
                        <div className={"pd10 "}>
                            <div style={{ height: 20 }}>
                                <div className={"pull-right"}>
                                    <Label className={"font12 text-uppercase"}>{Config.lang("ERP_So_luong_lot_tach")}:  </Label>
                                    <label style={{ paddingLeft: 10, fontSize: 16 }}>
                                        {this.numberFormat(this.state.quantity, this.state.quantity ? this.countDecimals(this.state.quantity) : decimalScale)}
                                    </label>
                                </div>
                            </div>

                            <div style={{ paddingTop: 10 }}>
                                <div className={"center-row pd10 full_w"} style={{ justifyContent: 'space-between', backgroundColor: '#E4E9F2', height: 40, borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                                    <span className={"text-uppercase font12 bold"} >{Config.lang("ERP_Ma_lot")}</span>
                                    <span className={"text-uppercase font12 bold"}>{Config.lang("ERP_So_luong")}</span>
                                </div>
                            </div>

                            <div className={"pdt5"}>
                                <div className={"center-row pd10"} style={{ justifyContent: 'space-between', backgroundColor: 'rgba(17, 29, 94, 0.04)', height: 40, }}>
                                    <span className={classes.currentFirstField} >{dataGrid.length > 0 && dataGrid[0].LotNo}</span>
                                    <MoneyInput placeholder={Config.lang("ERP_Nhap")} className={`${classes.textBoxField} quantity`}
                                        thousandSeparator={','} decimalDigit={5} allowZero
                                        viewType={"outlined"} disabled={true} value={dataGrid.length > 0 ? dataGrid[0].Quantity : 0} />
                                </div>
                            </div>

                            {this.displayData.map((item, index) => (item))}
                            <ButtonIcon circular viewBox viewType="text" name="AddCircle" color="primary" onClick={this.onTachLot} />
                        </div>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

W05F0005RequestLotPopup.propsTypes = {
    isView: PropTypes.bool,
    opendLot: PropTypes.bool,
    type: PropTypes.string,
    rowData: PropTypes.object,
    onCloseLOT: PropTypes.func
};

export default compose(
    connect((state) => ({}),
        dispatch => ({
            W05F0005Actions: bindActionCreators(W05F0005Actions, dispatch)
        })
    ), withStyles(styles, { withTheme: true }))(W05F0005RequestLotPopup);
