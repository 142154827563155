import { FormControl, FormLabel as Label, InputLabel, Tooltip, Typography } from "@material-ui/core";
import { LoadPanel } from "devextreme-react";
import _ from "lodash";
import moment from 'moment';
import React, { Component } from 'react';
import { Col, FormGroup, Row, Image } from "react-bootstrap";
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { browserHistory } from "react-router";
import { Collapse } from 'reactstrap';
import { bindActionCreators } from 'redux';
import Config from '../../../../config';
import * as W05F0005Actions from '../../../../redux/W0X/W05F0005/W05F0005_actions';
import Attachments from "../../../common/attachments/attachments";
import ButtonGeneral from "../../../common/button/button-general";
import AttachmentCustom from "../../../common/files/Attachments";
import { TextField } from "../../../common/form-material";
import { Combo } from "../../../common/form-material/";
// import { TextField } from "@material-ui/core";
import DateBoxPicker from '../../../common/form-material/date-box';
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import ViewHistory from '../../../common/view-history/ViewHistory';
import './W05F0005.scss';
import W05F0005DeliveryQuantity from './W05F0005DeliveryQuantity';
import W05F0005Popup from './W05F0005Popup';
import W05F0005TabContent from './W05F0005TabContent';
import W05F0005Tabs from './W05F0005Tabs';
import * as generalActions from '../../../../redux/general/general_actions';

class W05F0005 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CbStatusM: [],
            CbBank: [],
            CurrencyID: "",
            ExchangeRate: "",
            CustomerID: "",

            QuotationID: "",
            ContractID: "",
            ContractNo: "",
            VoucherNum: "",
            Status: "1",
            BankID: "",
            OriginalReduce: "",
            ValidTo: null,
            ValidFrom: null,
            Quantity: "",
            OTotal: "",
            CTotal: "",
            loading: false,
            opend: false,

            EStatus: 0,
            uploading: false,
            dataOldAttachments: [],

            cbStatusD: [],
            cbPackingMethod: [],
            cbPackingType: [],
            cbMarking: [],
            cbPort: [],
            cbVessel: [],
            cbUnitPack: [],
            cbEstimate: [],
            decimals: "",
            dataGrid: [],
            selectedRowKeys: [],
            tabDetailSupplement: [],
            tabDetailSupplementName: [],
            cbShippingAgent1: [],
            cbShippingAgent2: [],
            confirmDeliveryQuantity: [],
            allLotChecked: [],
            allCombinedLot: [],
            error: {
                OriginalReduce: "",
                Quantity: "",
                OTotal: "",
                CTotal: ""
            },
            errorValidDate: null,

            dataHistory: [],
            isView: false,
            isEdit: false,
            isCopy: false,
            isAdd: false,

            dataHistoryFumu: [],

            isSave: false,

            loadingCbGeneral: false,
            loadingCbVessel: false,
            isOpenHistory: false,

            isLoadForm: false,
            // isUpdated: false,
            btnDeliveryQuantity: false,
            deliveryQuantityModal: false,
            btnCombineLot: false,
            dataCbCurator: {
                rows: [],
                total: 0,
            },
            EmployeeID: '',
            loadingE: false,
            cbLocationNo: [],
            isPerW05F2004: 0,
        }
        this.isUpdated = false;
        this.ReloadRow = false;
        this.firstTimeLoad = false;
        this.key = -1;
        this.firstLotChecked = {};
        this.attachments = [];
        this.deletedFile = [];
        this.oldDataGrid = [];
        this.filterGeneral10 = {
            StrSearch: "",
            ObjectTypeID: "CC",
            skip: 0,
            limit: 10
        }
        this.filterGeneral20 = {
            StrSearch: "",
            ObjectTypeID: "CC",
            skip: 0,
            limit: 10
        }
        this.filterGeneral20 = {
            StrSearch: "",
            ObjectTypeID: "CC",
            skip: 0,
            limit: 10
        }
        this.filterGeneral30 = {
            StrSearch: "",
            O08ID: "30",
            skip: 0,
            limit: 10
        }
        this.filterFumiCompany = {
            StrSearch: "",
            ObjectTypeID: "CC",
            skip: 0,
            limit: 10
        }
        this.filterShippingAgent1 = {
            StrSearch: "",
            ObjectTypeID: "CC",
            skip: 0,
            limit: 10
        }
        this.filterShippingAgent2 = {
            StrSearch: "",
            ObjectTypeID: "CC",
            skip: 0,
            limit: 10
        }
        this.filterCbVessel = {
            StrSearch: "",
            Language: Config.language || 84,
            skip: 0,
            limit: 10
        }
        this.filterHistory = {
            keyword: "",
            condition: "",
            skip: 0,
            limit: 10,
            sort: "",
        }

        this.filterCurator = {
            StrSearch: "",
            ObjectTypeID: "NV",
            skip: 0,
            limit: 20
        }

        this.keyDetail = -1;
        this.dataInfo = {
            QuotationID: "",
            ContractID: "",
            mode: "",
        }
        this.LotTemp = null;
        this.allCombinedLotTemp = null;
    }

    getInfo = (flag) => {
        const { location } = this.props;
        if (location && location.state) {
            this.dataInfo.mode = location.state.mode || "view";
            this.dataInfo.QuotationID = location.state.QuotationID ? location.state.QuotationID : location.state.voucher_id;
            this.dataInfo.ContractID = location.state.ContractID;
            return {
                QuotationID: this.dataInfo.QuotationID || "",
                ContractID: location.state.ContractID || "",
                mode: this.dataInfo.mode,
            }
        } else {
            if (flag) {
                browserHistory.push(Config.getRootPath() + "W05F0004");
                return null;
            }
            return false;
        }
    };

    getCboCurator = (isReset) => {
        this.setState({ loadingE: true });
        this.props.W05F0005Actions.getCboCurator(this.filterCurator, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                this.setState({ loadingE: false });
                return false;
            }
            if (data) {
                const { dataCbCurator } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCbCurator: {
                        rows: isReset ? rows : dataCbCurator.rows.concat(rows),
                        total: total
                    },
                    loadingE: false
                });
            }
        });
    }

    getAdjustColumns = () => {
        // this.setState({ loadingE: true });
        this.props.W05F0005Actions.getAdjustColumns({ Language: Config.language || "84" }, (error, data) => {
            // this.setState({ loadingE: false });
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                const { dataGrid } = this.state;
                this.setState({
                    dataGrid: dataGrid.map(rs => {
                        for (let item of data) {
                            if (_.isNull(rs[item.ColID])) {
                                rs[item.ColID] = 0;
                            }
                        }
                        return rs;
                    })
                })
            }
        });
    };

    getFieldsCompare = () => {
        // this.setState({ loadingE: true });
        this.props.W05F0005Actions.getFieldsCompare({ Language: Config.language || "84" }, (error) => {
            // this.setState({ loadingE: false });
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;

            }
        });
    };

    loadPermission = () => {
        this.props.generalActions.getPermission("W05F2004", (isPer) => {
            this.setState({ isPerW05F2004: isPer });
        }, true)
    };


    componentDidMount() {
        if (!this.getInfo(true)) return;
        this.loadPermission();
        this.getCboCurator();
        this.getCbStatusM();
        this.getCbBank();
        this.getDetailSupplement();
        this.getCbStatusD();
        this.getPackingMethod();
        this.getPackingType();
        this.getCbMarking();
        this.getCbVessel();
        this.getCbPort();
        this.getCbObject();
        this.getAdjustColumns();
        this.getFieldsCompare();
        this.getCbLocationNo();
        const { QuotationID, ContractID, mode } = this.getInfo();
        if (mode === "add") {
            this.setState({ ContractID: ContractID !== undefined ? ContractID : "", isAdd: true })
        } else {
            this.setState({
                loading: mode === "view",
                QuotationID: QuotationID,
                ContractID: ContractID,
                isView: mode === "view",
                isCopy: mode === "copy",
                isEdit: mode === "edit"
            }, () => {
                this.getFormEdit(QuotationID, ContractID, true); // Load lần đầu tiên
                this.getHistory(QuotationID);
            });
        }
        if (mode !== "view") {
            Config.checkRedirect(this.props.location.pathname);
        }
    }
    componentWillUnmount() {
        if (!this.state.isView) {
            Config.unCheckRedirect();
        }
    }
    loadDeliveryQuantity = (QuotationID, VoucherNum, firstTimeLoad = false) => {
        if (this.firstTimeLoad) return;
        const params = {
            VoucherNum,
            QuotationID
        };
        this.setState({ btnDeliveryQuantity: true, VoucherNum })
        this.props.W05F0005Actions.getDeliveryQuantity(params, (error, data) => {
            if (error) {
                this.setState({ btnDeliveryQuantity: false })
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                if (this.props.location.state.form_id === "W05F0005_001" && firstTimeLoad) {  //W05F0005_001 Mở Popup Xác Nhận Giao Hàng
                    this.firstTimeLoad = true;
                    this.setState({
                        confirmDeliveryQuantity: data,
                        btnDeliveryQuantity: false,
                        deliveryQuantityModal: true,
                    });
                } else {
                    this.firstTimeLoad = false;
                    this.setState({
                        confirmDeliveryQuantity: data,
                        btnDeliveryQuantity: false
                    });
                }
            }
        });
    }
    getCbVessel = () => {
        this.props.W05F0005Actions.getCbVessel(this.filterCbVessel, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            this.setState({ cbVessel: data.rows });
        });
    }
    getCbObject = () => {
        this.props.W05F0005Actions.getObjects(this.filterShippingAgent1, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data && data.rows.length > 0) {
                const cbUnitPack = data.rows.map((item) => ({ UnitPackID: item.ObjectID, UnitPackName: item.ObjectNameU }));
                const cbEstimate = data.rows.map((item) => ({ EstimateID: item.ObjectID, EstimateName: item.ObjectNameU }));
                const cbShippingAgent1 = data.rows.map((item) => ({ ObjectID: item.ObjectID, ObjectName: item.ObjectNameU }));
                const cbShippingAgent2 = data.rows.map((item) => ({ ObjectIDCont: item.ObjectID, ObjectNameCont: item.ObjectNameU }));
                this.setState({ cbUnitPack, cbEstimate, cbShippingAgent1, cbShippingAgent2 });
            }
        });
    }
    getHistory = (QuotationID) => {
        this.filterHistory.condition = JSON.stringify({ codeID: QuotationID });
        this.props.W05F0005Actions.getHistory(this.filterHistory, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            this.setState({ dataHistory: data }, () => {

            });
        });
    }
    getFormEdit = (QuotationID, ContractID, firstTimeLoad = false) => {
        const { mode } = this.getInfo();
        const { dataAdjustColumns } = this.props;
        let params = {
            QuotationID: QuotationID,
            ContractID: ContractID,
            Language: Config.language || 84,
        }
        this.setState({ isLoadForm: true });
        this.props.W05F0005Actions.getFormEdit(params, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            let voucherNum = this.state.isCopy ? this.state.VoucherNum : data.master.VoucherNum;
            let selectedRowKeys = [];
            data.detail.forEach(rs => {
                if (selectedRowKeys.indexOf(rs["SalesItemID"]) <= -1) {
                    selectedRowKeys.push(rs.SalesItemID);
                }
            });
            if (this.state.isView && data.master.VoucherNum) {
                this.loadDeliveryQuantity(QuotationID, data.master.VoucherNum, firstTimeLoad); // Load Data Btn Delivery Quantity
            }
            this.getDecimals(data.master.CurrencyID);
            if (mode === "copy") {
                this.getVoucherNum(ContractID ? ContractID : data.master.ContractID, (VoucherNum) => {
                    this.setState({ VoucherNum })
                });
            }
            this.setState({
                ContractID: data.master.ContractID,
                QuotationID: data.master.QuotationID,
                ContractNo: data.master.ContractNo,
                VoucherNum: voucherNum,
                Status: data.master.Status,
                BankID: data.master.BankID,
                OriginalReduce: data.master.OriginalReduce,
                ValidTo: data.master.ValidTo,
                ValidFrom: data.master.ValidFrom,
                CurrencyID: data.master.CurrencyID,
                ExchangeRate: data.master.ExchangeRate,
                CustomerID: data.master.CustomerID,
                Quantity: data.master.Quantity,
                OTotal: data.master.OTotal,
                CTotal: data.master.CTotal,
                selectedRowKeys: selectedRowKeys,
                EmployeeID: data.master.EmployeeID,
                dataGrid: data.detail.map((rs, index) => {
                    this.key++;
                    rs.key = this.key;
                    for (let aCol of dataAdjustColumns) {
                        if (_.isNull(rs[aCol.ColID])) {
                            rs[aCol.ColID] = 0;
                        }
                    }
                    if (_.isNull(rs.StatusDetailName)) {
                        rs.StatusDetailName = "";
                    }
                    if (_.isNull(rs.PackingMethodName)) {
                        rs.PackingMethodName = "";
                    }
                    if (_.isNull(rs.PackingTypeName)) {
                        rs.PackingTypeName = "";
                    }
                    if (_.isNull(rs.MarkingName)) {
                        rs.MarkingName = "";
                    }
                    if (_.isNull(rs.PortToBoatName)) {
                        rs.PortToBoatName = "";
                    }
                    if (_.isNull(rs.PortFromBoatName)) {
                        rs.PortFromBoatName = "";
                    }
                    if (_.isNull(rs.PortToContName)) {
                        rs.PortToContName = "";
                    }
                    if (_.isNull(rs.PortFromContName)) {
                        rs.PortFromContName = "";
                    }
                    // rs.LotNo = index < 10 ? data.master.ContractNo + '.' + voucherNum + '.0' + (index + 1) : data.master.ContractNo + '.' + voucherNum + '.' + (index + 1);
                    rs.DeliveryDate = rs.DeliveryDateFrom + ' + ' + rs.DeliveryDateTo;
                    rs.fumigation.detail.map((rs2, indx2) => {
                        this.keyDetail++;
                        rs2.key = indx2;
                        return rs2;
                    });
                    return rs;
                }),
                dataOldAttachments: data.attachments
            }, () => {
                //Format Date luôn cả Old và New Payment Data
                this.oldDataGrid = this.state.dataGrid.map(item => ({
                    ...item, receipts: item.receipts.map(rc => ({ ...rc })), payments: item.payments.map(payment => {
                        Object.keys(payment).forEach(key => {
                            if (!_.isNumber(payment[key]) && moment(payment[key], moment.ISO_8601, true).isValid()) {
                                payment[key] = moment(payment[key]).format("YYYY-MM-DD")
                            }
                        });
                        return { ...payment };
                    })
                }));
                setTimeout(() => {
                    this.setState({ isLoadForm: false });
                }, 300);
            });
        });
    }
    getCbStatusM = () => {
        let params = {
            GroupID: "M",
            Language: Config.language || 84
        }
        this.props.W05F0005Actions.getCbStatus(params, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                this.setState({ CbStatusM: data });
            }
        });
    }
    getCbBank = () => {
        this.props.W05F0005Actions.getCbBank(null, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                this.setState({ CbBank: data });
            }
        });
    }
    getVoucherNum = async (ContractID, cb) => {
        let params = {
            ContractID
        }
        await this.props.W05F0005Actions.getVoucherNum(params, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (cb && data) cb(data && data.VoucherNum ? data.VoucherNum : "00");
        });
    }
    getDetailSupplement = () => {
        let params = {
            Language: Config.language || 84
        }
        this.props.W05F0005Actions.getDetailSupplement(params, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                let tabDetailSupplementName = [];
                if (!_.isEmpty(data)) {
                    tabDetailSupplementName = data.map(item => item.InfoID);
                }
                this.setState({
                    tabDetailSupplement: data,
                    tabDetailSupplementName
                });
            }
        });
    }
    getCbStatusD = () => {
        let params = {
            GroupID: "D",
            Language: Config.language || 84
        }
        this.props.W05F0005Actions.getCbStatus(params, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                this.setState({ cbStatusD: data });
            }
        });
    }
    getPackingMethod = () => {
        this.props.W05F0005Actions.getPackingMethod(null, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                this.setState({ cbPackingMethod: data });
            }
        });
    }
    getPackingType = () => {
        let params = {
            Language: Config.language || 84
        }
        this.props.W05F0005Actions.getPackingType(params, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                this.setState({ cbPackingType: data });
            }
        });
    }
    getDecimals = (CurrencyID) => {
        let params = {
            CurrencyID: CurrencyID
        }
        this.props.W05F0005Actions.getDecimals(params, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                this.setState({ decimals: data });
            }
        });
    }

    getCbMarking = () => {
        let params = {
            Language: Config.language || 84
        }
        this.props.W05F0005Actions.getCbMarking(params, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                this.setState({ cbMarking: data });
            }
        });
    }

    getCbPort = () => {

        this.props.W05F0005Actions.getCbPort(null, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                this.setState({ cbPort: data });
            }
        });
    }
    handleChange = async (key, e) => {
        if (!key) return false;
        const { dataGrid } = this.state;
        switch (key) {
            case "Status":
                let statusCondition = false;
                this.isUpdated = true;
                if (e.value === "3" || e.value === 3) {
                    if (!_.isEmpty(dataGrid)) {
                        const statusDetailGrid = dataGrid.map(item => item.StatusDetail);
                        const conditionChange = currentValue => currentValue === "7"; // NẾu tất cả = 7 thì cho chọn
                        if (statusDetailGrid.every(conditionChange)) {
                            statusCondition = true;
                        } else {
                            Config.popup.show("INFO", Config.lang("ERP_Tat_ca_cac_lot_phai_ket_thuc_khi_ket_thuc_dot_trien_khai"));
                        }
                    } else {
                        statusCondition = true;
                    }
                } else {
                    statusCondition = true;
                }
                if (statusCondition) {
                    this.setState({ Status: e.value });
                }
                break;
            case "BankID":
                this.isUpdated = true;
                this.setState({ BankID: e.value });
                break;
            case "OriginalReduce":
                if (this.state.isLoadForm) {
                    this.setState({ OriginalReduce: Number(e.value) });
                } else {
                    this.isUpdated = true;
                    this.setState({ OriginalReduce: Number(e.value) });
                }
                break;
            case "ValidTo":
                this.isUpdated = true;
                this.setState({ ValidTo: e.value });
                break;
            case "ValidFrom":
                this.isUpdated = true;
                this.setState({ ValidFrom: e.value });
                break;
            case "EmployeeID":
                this.isUpdated = true;
                this.setState({ EmployeeID: _.get(e.value, 'ObjectID', '') });
                break;
            default:
                break;
        }
    };
    setStateErrorText = (value) => {
        const { error } = this.state;
        this.setState({
            error: {
                ...error,
                ...value
            },
        });
        return Object.keys(value).length !== 0;
    };
    _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(this.attachments);
        let { dataOldAttachments } = this.state;
        let arrAttachment = [];
        listAttachments.forEach(att => {
            arrAttachment.push({
                URL: att.url || "",
                FileName: att.fileName || "",
                FileSize: att.fileSize || "",
                FileExt: att.fileExt || ""
            });
        });
        if (!_.isEmpty(dataOldAttachments)) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }
        return arrAttachment;
    };
    getCodeLang(ID) {
        const { tabDetailSupplement } = this.state;
        const { dataAdjustColumns } = this.props;
        const listValues = {
            //master
            LotNo: "ERP_Ma_lot",
            Status: "ERP_Trang_thai",
            BankID: "ERP_Ngan_hang",
            OriginalReduce: "ERP_Gia_mo_to_khai",
            ValidFrom: "ERP_Tu",
            ValidTo: "ERP_Den",
            EmployeeID: "ERP_Nguoi_phu_trach",

            //detail
            //tab1
            Quantity: "ERP_So_luong",
            UnitPrice: "ERP_Don_gia",
            AdjustedUnitPrice: "ERP_Don_gia_dieu_chinh",
            StatusDetail: "ERP_Trang_thai",
            DeliveryDateFrom: "ERP_Thoi_gian_giao_hang_du_kien",
            DeliveryDateTo: "ERP_Thoi_gian_giao_hang_du_kien",
            PackingMethodName: "ERP_Quy_cach_dong_goi",
            PackingTypeName: "ERP_Loai_bao_dong_goi",
            MarkingName: "ERP_Nhan_mac",
            PackNote: "ERP_Thong_tin_bao_bi",
            UnitPackName: "ERP_Don_vi_det_bao",
            EstimateName: "ERP_Giam_dinh",
            RateReduce: "ERP_Ty_le_deposit",
            NotesU: "Ghi_chu",
            ParkingDeliveryDate: "Thoi_gian_giao_bao",
            InsuranceName: "Cong_ty_bao_hiem",
            PaymentMethodName: "Phuong_thuc_thanh_toan",
            LocationNoDes: "Lo",
            ActualAmount: 'So_tien_thuc_te',
            GuaranteeName: 'Don_vi_uy_thac',
            ActualUnitPrice: 'Don_gia_thuc_te',
            ParcelInventoryName: 'Ten_hang_thuc_te',


            //tab3
            VesselID: "ERP_Ma_tau",
            VesselName: "ERP_Ten_tau",
            ObjectName: "ERP_Dai_ly_tau",
            DateVesselIn: "ERP_Ngay_tau_vao",
            DateVesselOut: "ERP_Ngay_tau_di",
            PortFromBoatName: "ERP_Cang_den",
            PortToBoatName: "ERP_Cang_di",

            //tab4
            BookingNo: "ERP_Ma_booking",
            DateActual: "ERP_Ngay_thuc_te_booking",
            QuantityBooking: "ERP_So_luong_di_cua_booking",
            VesselIDCont: "ERP_Ma_tau",
            VesselNameCont: "ERP_Ten_tau",
            ObjectNameCont: "ERP_Dai_ly_tau",
            ContWeight: "ERP_Trong_luong_cont",
            PortToContName: "ERP_Cang_di",
            PortFromContName: "ERP_Cang_den",
        };
        //tab2
        tabDetailSupplement.map(item => {
            listValues[item.InfoID] = item.Caption;
            return listValues;
        });
        dataAdjustColumns.map(item => {
            listValues[item.ColID] = item.ColDescription;
            return item;
        });
        return listValues[ID];
    }





    historyActions = (oldData, newData, key, mode) => {
        const dataKeyMoi = newData.map(d => d[key]);
        const dataKeyCu = oldData.map(d => d[key]);
        let data = [];
        switch (mode) {
            case "add":
                data = newData.filter(d => {
                    return dataKeyCu.indexOf(d[key]) < 0;
                });
                break;
            case "edit":
                data = newData.filter(d => {
                    return dataKeyCu.indexOf(d[key]) > -1;
                });
                break;
            case "delete":
                const dataDelete = oldData.filter(d => {
                    return dataKeyMoi.indexOf(d[key]) < 0;
                });
                let dataCombineLot = [];
                if (!_.isEmpty(this.state.allCombinedLot) && key === "key") { // Các Lot bị Gộp và xóa
                    dataDelete.forEach(item => {
                        this.state.allCombinedLot.forEach(obj => {
                            if (item.QuotationItemID === obj.QuotationItemID) {
                                dataCombineLot.push(obj);
                            }
                        })
                    });
                    const filterRemove = dataDelete.filter((obj) => dataCombineLot.some((obj2) => obj.QuotationItemID !== obj2.QuotationItemID));
                    data = [...dataCombineLot, ...filterRemove];
                } else { // XÓa Only
                    data = dataDelete;
                }
                break;
            default:
                break;
        }
        return data;
    }
    getCodeLangFumi = (ID) => {
        const listValues = {
            FumigationType: "ERP_Phuong_thuc",
            Quantity: "ERP_So_luong",

            FumigationCompany: "ERP_Cong_ty_phun",
            FumigationDate: "ERP_Ngay_phun",
            Notes: "Ghi_chu",
        };

        return listValues[ID];
    }

    getCodeLangPopUp = (value) => {
        const listValues = {
            IsUse: "ERP_Su_dung",
            IsComplete: "ERP_Hoan_thanh",
            PaymentName: "ERP_Ten_thong_tin_thanh_toan",
            PaymentMethodID: "ERP_Phuong_thuc_thanh_toan",
        };
        return listValues[value];
    }

    getFieldName = (oldData) => {
        let FieldName = [];
        let oldDataConvert = oldData.map(d => {
            let obj = {};
            Object.keys(d).map(key => {
                const newKey = key;
                obj[newKey] = d[key];
                return { [key]: newKey };
            });
            return obj;
        });
        FieldName = oldDataConvert.map(jaja => Object.keys(jaja))[0];
        return FieldName
    }
    saveHistory = async (newDataMaster, newAttachment, newInventory) => {
        const { oldDataMaster, oldDataAttachments, getFlexCaption, dataAdjustColumns } = this.props;
        let { isEdit, allCombinedLot } = this.state;
        let el = {};
        let status = 200;
        let data = [];

        if (isEdit) {
            const masterKey = Object.keys(newDataMaster);
            masterKey.forEach((value) => {
                if (newDataMaster[value] !== oldDataMaster[value] && value !== 'UserName') {
                    const description = this.getCodeLang(value);
                    let el = {};
                    el.codeID = newDataMaster.QuotationID;
                    el.formID = 'W05F0004';
                    el.linkedTrans = '';
                    el.action = 1;
                    el.description84 = Config.lang(description, 'vi');
                    el.description01 = Config.lang(description, 'en');
                    el.oldValue = oldDataMaster[value]
                    el.newValue = newDataMaster[value]
                    el.hasURL = false;
                    data.push(el);
                }
                return false;
            });
            if (!_.isEmpty(newInventory) && !_.isEmpty(this.oldDataGrid)) {
                const oldData = this.oldDataGrid;
                const addedGrid = this.historyActions(oldData, newInventory, 'key', 'add')
                if (addedGrid.length > 0) {
                    addedGrid.forEach((dataMoi) => {
                        let el = {};
                        el.codeID = newDataMaster.QuotationID;
                        el.formID = 'W05F0004';
                        el.linkedTrans = '';
                        el.action = 2;
                        el.description84 = 'Lot';
                        el.description01 = 'Lot';
                        el.newValue = "";
                        el.newValue = dataMoi.LotNo;
                        data.push(el);
                    });
                }
                //Lưu lịch sử xóa lưới Detail
                const deleteGrid = this.historyActions(oldData, newInventory, 'key', 'delete');
                if (deleteGrid.length > 0) {
                    deleteGrid.forEach((dataCu) => {
                        let el = {};
                        el.codeID = newDataMaster.QuotationID;
                        el.formID = "W05F0004";
                        el.linkedTrans = "";
                        el.action = 3;
                        el.description84 = "Lot";
                        el.description01 = "Lot";
                        el.oldValue = dataCu.LotNo;
                        el.newValue = "";
                        data.push(el);
                    });
                }
                const editedGrid = this.historyActions(oldData, newInventory, 'key', 'edit');
                const exceptField = ["Amount", "CAmount", "fumigation", "OriginalReduce", "CReduce", "receipts", "payments", "ObjectIDCont",
                    "ObjectID", "EstimateID", "UnitPackID", "InsuranceID", "LocationNo", "ObjectIDCont", "PackingMethodID", "PackingTypeID", "MarkingID",
                    "PortFromBoat", "PortToBoat", "PortToCont", "PortFromCont", "GuaranteeID", "GuaranteeTypeID", "ParcelInventoryID"];
                dataAdjustColumns.map(item => {
                    exceptField.push("C_" + item.ColID);
                    return item;
                });
                const vesselField = ["VesselID", "VesselName", "ObjectCont", "PortToBoat", "PortFromBoat"];
                const contField = ["VesselIDCont", "VesselNameCont", "ObjectNameCont", "PortToCont", "PortFromCont"];
                const formatField = ['DeliveryDateFrom', 'DeliveryDateTo', 'ParkingDeliveryDate', 'DateVesselIn', 'DateVesselOut', 'DateActual'];
                if (editedGrid.length > 0) {
                    const FieldName = this.getFieldName(oldData);
                    editedGrid.forEach(dataMoi => {
                        oldData.forEach(dataCu => {
                            FieldName.map(fieldName => {
                                if (dataMoi[fieldName] !== dataCu[fieldName] && dataMoi.key === dataCu.key && !exceptField.includes(fieldName)
                                ) {
                                    let tab64 = "";
                                    let tab01 = "";
                                    if (vesselField.includes(fieldName)) {
                                        tab64 = ' (Đi tàu)';
                                        tab01 = ' (Vessel)';
                                    } else if (contField.includes(fieldName)) {
                                        tab64 = ' (Đi cont)';
                                        tab01 = ' (Cont)';
                                    }
                                    const description = this.getCodeLang(fieldName);
                                    let el = {};
                                    el.codeID = newDataMaster.QuotationID;
                                    el.formID = 'W05F0004';
                                    el.linkedTrans = '';
                                    el.action = 1;
                                    el.oldValue = formatField.includes(fieldName) ? dataCu[fieldName] ? moment(dataCu[fieldName]).format('YYYY-MM-DD') : '' : dataCu[fieldName];
                                    el.newValue = formatField.includes(fieldName) ? moment(dataMoi[fieldName]).format('YYYY-MM-DD') : dataMoi[fieldName];
                                    el.description84 = Config.lang(description, 'vi') + tab64 + ' Lot ' + dataMoi.LotNo;
                                    el.description01 = Config.lang(description, 'en') + tab01 + ' Lot ' + dataMoi.LotNo;
                                    el.hasURL = false;
                                    data.push(el);
                                }
                                return false;
                            });
                        });
                    })
                }
                //Lưu lịch sử của gộp lot [EW-107] - KHÔNG ĐƯỢC XÓA
                if (!_.isEmpty(allCombinedLot)) {
                    allCombinedLot.forEach(item => {
                        let el = {};
                        el.codeID = newDataMaster.QuotationID;
                        el.formID = 'W05F0004';
                        el.linkedTrans = '';
                        el.action = 1;
                        el.description84 = Config.lang("ERP_Gop_lot", 'vi');
                        el.description01 = Config.lang("ERP_Gop_lot", 'vi');
                        el.newValue = `Lot ${item.LotNo}`;
                        el.hasURL = false;
                        data.push(el);
                    })
                }
                this.oldDataGrid.forEach(dataCu => {
                    const rowData = newInventory.find(dMoi => dMoi.key === dataCu.key);
                    if (rowData) {
                        //So sanh de luu history detail...


                        //Fumi
                        const FumiCu = dataCu.fumigation;
                        const FumiMoi = rowData.fumigation;
                        const { method: methodCu, detail: detailCu } = FumiCu;
                        const { method: methodMoi, detail: detailMoi } = FumiMoi;

                        const addedMethod = this.historyActions(methodCu, methodMoi, 'FumigationType', 'add');
                        if (addedMethod.length > 0) {
                            addedMethod.forEach(rs => {
                                let el = {};
                                el.codeID = rs.QuotationID;
                                el.formID = 'W05F0004';
                                el.linkedTrans = '';
                                el.action = 0;
                                el.newValue = rs.FumigationTypeName + ' - ' + Config.lang('ERP_So_luong') + ' :' + rs.Quantity;
                                el.description84 = 'Phương thức phun trùng của Lot ' + rs.LotNo;
                                el.description01 = 'Fumigation Type of Lot ' + rs.LotNo;
                                el.hasURL = false;
                                data.push(el);
                            })

                        }

                        const editedMethod = this.historyActions(methodCu, methodMoi, 'FumigationType', 'edit');
                        if (editedMethod.length > 0) {
                            const FieldName = this.getFieldName(methodCu);
                            editedMethod.forEach(dataMoi => {
                                methodCu.forEach(dataCu => {
                                    FieldName.map(fieldName => {
                                        if (dataMoi[fieldName] !== dataCu[fieldName] && dataCu.FumigationType === dataMoi.FumigationType) {
                                            // const description = this.getCodeLangFumi(fieldName);
                                            let el = {};
                                            el.codeID = dataMoi.QuotationID;
                                            el.formID = 'W05F0004';
                                            el.linkedTrans = '';
                                            el.action = 1;
                                            el.newValue = dataMoi[fieldName];
                                            el.oldValue = dataCu[fieldName];
                                            el.description84 = Config.lang('ERP_So_luong') + ' - ' + dataMoi.FumigationTypeName + ' của Lot ' + dataMoi.LotNo;
                                            el.description01 = Config.lang('ERP_So_luong') + ' - ' + dataMoi.FumigationTypeName + ' of Lot ' + dataMoi.LotNo;
                                            el.hasURL = false;
                                            data.push(el);
                                        }
                                        return false;
                                    });
                                });
                            })

                        }

                        const deletedMethod = this.historyActions(methodCu, methodMoi, 'FumigationType', 'delete');
                        if (deletedMethod.length > 0) {
                            deletedMethod.forEach(rs => {
                                let el = {};
                                el.codeID = rs.ContractID;
                                el.formID = 'W05F0004';
                                el.linkedTrans = '';
                                el.action = 3;
                                el.oldValue = rs.FumigationTypeName + ' - ' + Config.lang('ERP_So_luong') + ' :' + rs.Quantity;
                                el.description84 = 'Phương thức phun trùng của Lot của Lot ' + rs.LotNo;
                                el.description01 = 'Fumigation Type of Lot' + rs.LotNo;
                                el.hasURL = false;
                                data.push(el);
                            })
                        }

                        //detail
                        const addedDetail = this.historyActions(detailCu, detailMoi, 'key', 'add');
                        if (addedDetail.length > 0) {
                            addedDetail.forEach(rs => {
                                if (rs.IsNew) {
                                    let el = {};
                                    el.codeID = rs.QuotationID;
                                    el.formID = 'W05F0004';
                                    el.linkedTrans = '';
                                    el.action = 0;
                                    el.newValue = rs.FumigationTypeName + ' - ' + Config.lang("ERP_Lan") + ' ' + rs.OrderNo;
                                    el.description84 = 'Chi tiết phun trùng của Lot ' + rs.LotNo;
                                    el.description01 = 'Fumigation Details of Lot ' + rs.LotNo;
                                    el.hasURL = false;
                                    el.type = rs.FumigationType;
                                    data.push(el);
                                }
                            })
                        }

                        const editedDetail = this.historyActions(detailCu, detailMoi, 'key', 'edit');
                        if (editedDetail.length > 0) {
                            const FieldName = this.getFieldName(detailCu);
                            editedDetail.forEach(dataMoi => {
                                detailCu.forEach(dataCu => {
                                    FieldName.map(fieldName => {
                                        if (dataMoi[fieldName] !== dataCu[fieldName] && dataMoi.FumigationType === dataCu.FumigationType && dataCu.key === dataMoi.key
                                            && fieldName !== 'FumigationCompanyName' && fieldName !== 'FumigationType') {
                                            let stt = dataMoi.OrderNo === 1 ? 'stt' : dataMoi.OrderNo === 2 ? 'nd' : dataMoi.OrderNo === 3 ? 'rs' : 'th';
                                            const description = this.getCodeLangFumi(fieldName);
                                            let newValue = '';
                                            let oldValue = '';
                                            switch (fieldName) {
                                                case 'FumigationCompany':
                                                    newValue = dataMoi['FumigationCompanyName'];
                                                    oldValue = dataCu['FumigationCompanyName'];
                                                    break;
                                                case 'FumigationDate':
                                                    newValue = dataMoi['FumigationDate'] !== null ? moment(dataMoi['FumigationDate']).format('DD/MM/YYYY') : '';
                                                    oldValue = dataCu['FumigationDate'] !== null ? moment(dataCu['FumigationDate']).format('DD/MM/YYYY') : '';
                                                    break;
                                                default:
                                                    newValue = dataMoi[fieldName];
                                                    oldValue = dataCu[fieldName];
                                                    break;
                                            }

                                            let el = {};
                                            el.codeID = dataMoi.QuotationID;
                                            el.formID = 'W05F0004';
                                            el.linkedTrans = '';
                                            el.action = 1;
                                            el.newValue = newValue;
                                            el.oldValue = oldValue;
                                            el.description84 = Config.lang(description, 'vi') + ' - ' + dataMoi.FumigationTypeName + ' - lần ' + dataMoi.OrderNo + ' của Lot ' + dataMoi.LotNo;
                                            el.description01 = Config.lang(description, 'vi') + ' - ' + dataMoi.FumigationTypeName + ' - ' + dataMoi.OrderNo + stt + ' of Lot ' + dataMoi.LotNo;
                                            el.hasURL = false;
                                            data.push(el);
                                        }
                                        return false;
                                    });
                                });
                            })
                        }
                        const deletedDetail = this.historyActions(detailCu, detailMoi, 'key', 'delete');
                        if (deletedDetail.length > 0) {
                            deletedDetail.forEach(rs => {
                                let el = {};
                                el.codeID = rs.QuotationID;
                                el.formID = 'W05F0004';
                                el.linkedTrans = '';
                                el.action = 3;
                                el.oldValue = rs.FumigationTypeName + ' - ' + Config.lang('ERP_Lan') + ' ' + rs.OrderNo;
                                el.description84 = 'Chi tiết phun trùng của Lot ' + rs.LotNo;
                                el.description01 = 'Fumigation Details of Lot ' + rs.LotNo;
                                el.hasURL = false;
                                data.push(el);
                            })
                        }

                        //Save Receipts
                        if (!_.isEmpty(rowData.receipts) && !_.isEmpty(dataCu.receipts)) {
                            const receiptsCu = dataCu.receipts;
                            const receiptsMoi = rowData.receipts;
                            let IsCompleteVoucherNew = [];
                            let IsCompleteVoucherOld = [];
                            const editedReceipts = this.historyActions(receiptsCu, receiptsMoi, 'VoucherTypeID', 'edit');
                            if (editedReceipts.length > 0) {
                                const FieldName = this.getFieldName(receiptsCu);
                                editedReceipts.forEach(dataMoi => {
                                    receiptsCu.forEach(dataCu => {
                                        FieldName.forEach(fieldName => {
                                            if (dataMoi[fieldName] !== dataCu[fieldName] && dataCu.VoucherTypeID === dataMoi.VoucherTypeID) {
                                                if (fieldName !== "IsCompleteVoucher") {
                                                    const description = this.getCodeLangPopUp(fieldName);
                                                    const LotNo = newInventory.find(rs => rs.QuotationItemID === dataMoi.QuotationItemID)?.LotNo;
                                                    const description84 = fieldName !== 'VoucherTypeName' ? Config.lang(description, "vi") + ' chứng từ ' + dataMoi.VoucherTypeName :
                                                        Config.lang('Ten_dieu_chinh_chung_tu_cua_lot', 'vi') + ' ' + LotNo;
                                                    const description01 = fieldName !== 'VoucherTypeName' ? Config.lang(description, "en") + ' Lot ' + dataMoi.VoucherTypeName :
                                                        Config.lang('Ten_dieu_chinh_chung_tu_cua_lot', 'en') + ' ' + LotNo;
                                                    let el = {};
                                                    el.codeID = dataMoi.QuotationID;
                                                    el.formID = 'W05F0005';
                                                    el.linkedTrans = '';
                                                    el.action = 1;
                                                    el.newValue = fieldName !== 'VoucherTypeName' ? dataMoi[fieldName] === 0 ? 'UnChecked' : 'Checked' : dataMoi[fieldName];
                                                    el.oldValue = fieldName !== 'VoucherTypeName' ? dataCu[fieldName] === 0 ? 'UnChecked' : 'Checked' : dataCu[fieldName];
                                                    el.description84 = description84;
                                                    el.description01 = description01;
                                                    el.hasURL = false;
                                                    data.push(el);
                                                }
                                                else if (dataCu.IsCompleteVoucher !== dataMoi.IsCompleteVoucher) { //Save history btn complete
                                                    if (_.isEmpty(IsCompleteVoucherNew) && _.isEmpty(IsCompleteVoucherOld)) {
                                                        IsCompleteVoucherNew = newInventory.map(objReceipt => {
                                                            return { 'LotNo': objReceipt.LotNo, 'IsCompleteVoucher': (objReceipt.receipts[0]).IsCompleteVoucher }
                                                        });
                                                        IsCompleteVoucherOld = oldData.map(objReceipt => {
                                                            return { 'LotNo': objReceipt.LotNo, 'IsCompleteVoucher': (objReceipt.receipts[0]).IsCompleteVoucher }
                                                        });
                                                    }
                                                }
                                            }
                                        });
                                    });
                                })
                                //Save Btn Complete Of Receipt Popup Only
                                if (!_.isEmpty(IsCompleteVoucherNew) && !_.isEmpty(IsCompleteVoucherOld)) {
                                    const description84 = "Cập nhật lại trạng thái thông tin chứng từ của Lot ";
                                    const description01 = "Changed the status vourcher of Lot ";
                                    IsCompleteVoucherOld.forEach(voucherNew => {
                                        IsCompleteVoucherNew.forEach(voucherOld => {
                                            if (voucherOld.LotNo === voucherNew.LotNo && voucherOld.IsCompleteVoucher !== voucherNew.IsCompleteVoucher) {
                                                const { QuotationID, LotNo } = rowData;
                                                let obj = {};
                                                obj.codeID = QuotationID;
                                                obj.formID = "W05F0005";
                                                obj.linkedTrans = "";
                                                obj.hasURL = false;
                                                obj.description84 = description84 + LotNo;
                                                obj.description01 = description01 + LotNo;
                                                obj.action = 2;
                                                if (!_.isEmpty(data)) {
                                                    const findDataReceipt = data.find(objData => objData.description01.slice(description01.length) === LotNo);
                                                    if (_.isEmpty(findDataReceipt)) data.push(obj);
                                                } else { // add lần đầu
                                                    data.push(obj);
                                                }
                                            }
                                        })
                                    })
                                }
                            }
                        }
                        //Save Payments
                        if (!_.isEmpty(newInventory)) {
                            const paymentsMoi = rowData.payments;
                            const paymentsCu = dataCu.payments;
                            const editedPayments = this.historyActions(paymentsCu, paymentsMoi, 'PaymentInforID', 'edit');

                            const dataOldPayments = paymentsCu.map(item => item.PaymentInforID);
                            const dataNewPayments = paymentsMoi.map(item => item.PaymentInforID);
                            /// <=====================> Lưu Thêm Mới Payment <=====================>
                            paymentsMoi.forEach((dataMoi) => {
                                if (dataOldPayments.indexOf(dataMoi.PaymentInforID) === -1) {
                                    const { QuotationID, PaymentName } = dataMoi;
                                    let el = {};
                                    el.action = 2;
                                    el.codeID = QuotationID;
                                    el.formID = 'W05F0005';
                                    el.linkedTrans = '';
                                    el.newValue = PaymentName;
                                    el.oldValue = '';
                                    el.description84 = Config.lang("ERP_Thong_tin_thanh_toan_cua_lot", "vi") + " " + rowData.LotNo;
                                    el.description01 = Config.lang("ERP_Thong_tin_thanh_toan_cua_lot", "en") + " " + rowData.LotNo;
                                    data.push(el);
                                }
                            });
                            /// <=====================> Lưu Xóa Payment <=====================>
                            paymentsCu.forEach((dataCu) => {
                                if ((dataNewPayments.indexOf(dataCu.PaymentInforID) === -1)) {
                                    const { QuotationID, PaymentName } = dataCu;
                                    let el = {};
                                    el.action = 3;
                                    el.codeID = QuotationID;
                                    el.formID = 'W05F0005';
                                    el.linkedTrans = '';
                                    el.newValue = '';
                                    el.oldValue = PaymentName;
                                    el.description84 = Config.lang("ERP_Thong_tin_thanh_toan_cua_lot", "vi") + " " + rowData.LotNo;
                                    el.description01 = Config.lang("ERP_Thong_tin_thanh_toan_cua_lot", "en") + " " + rowData.LotNo;
                                    data.push(el);
                                }
                            })
                            /// <=====================> Lưu Chỉnh Sữa Payment <=====================>
                            if (editedPayments.length > 0) {
                                const FieldName = this.getFieldName(paymentsCu);
                                editedPayments.forEach(dataMoi => {
                                    paymentsCu.forEach(dataCu => {
                                        FieldName.forEach(fieldName => {
                                            if (dataMoi[fieldName] !== dataCu[fieldName] && dataCu.PaymentInforID === dataMoi.PaymentInforID && fieldName !== "keyData") {
                                                let caption84 = this.getCodeLangPopUp(fieldName);
                                                let caption01 = this.getCodeLangPopUp(fieldName);
                                                if (_.isUndefined(caption84) || _.isUndefined(caption01)) {
                                                    const flexCaptionField = getFlexCaption.find(item => item.FieldName === fieldName);
                                                    caption84 = flexCaptionField.Caption84;
                                                    caption01 = flexCaptionField.Caption01;
                                                }
                                                let newValue = dataMoi[fieldName];
                                                let oldValue = dataCu[fieldName];
                                                if (fieldName.includes('Date') && moment(newValue, moment.ISO_8601, true).isValid() && moment(oldValue, moment.ISO_8601, true).isValid()) {
                                                    newValue = moment(newValue).format("YYYY-MM-DD")
                                                    oldValue = moment(oldValue).format("YYYY-MM-DD")
                                                }
                                                let el = {};
                                                el.action = 1;
                                                el.codeID = rowData.QuotationID;
                                                el.formID = 'W05F0005';
                                                el.linkedTrans = '';
                                                el.newValue = newValue;
                                                el.oldValue = oldValue;
                                                el.description84 = Config.lang(caption84, "vi") + " " + Config.lang("ERP_Thong_tin_thanh_toan", "vi") + " " + dataMoi.PaymentMethodName + ' của LOT ' + rowData.LotNo;
                                                el.description01 = Config.lang(caption01, "en") + " " + Config.lang("ERP_Thong_tin_thanh_toan", "en") + " " + dataMoi.PaymentMethodName + ' of LOT ' + rowData.LotNo;
                                                el.hasURL = false;
                                                data.push(el);
                                            }
                                        });
                                    });
                                })
                            }
                        }
                    }
                });
            }
            if (!_.isEmpty(oldDataAttachments)) {
                /// <=====================> Lưu Xóa ATTACHMENT - MODE: "EDIT" <=====================>
                let newDataAttachments = [];
                newDataAttachments = newAttachment.map(item => item.URL);
                oldDataAttachments.forEach((dataCu) => {
                    if ((newDataAttachments.indexOf(dataCu.URL) === -1)) {
                        // console.log("Đây chính là Xóa Tệp đính kèm trong Mode EDIT");
                        let el = {};
                        el.codeID = newDataMaster.QuotationID;
                        el.formID = 'W05F0004';
                        el.linkedTrans = '';
                        el.oldValue = dataCu.FileName;
                        el.description84 = 'Đính kèm';
                        el.description01 = 'Attachment';
                        el.action = 3;
                        el.hasURL = false;
                        data.push(el);
                    }
                });
            }
            if (!_.isEmpty(newAttachment)) {
                /// <=====================> Lưu Thêm Mới ATTACHMENT - MODE: "EDIT" <=====================>
                let dataOldAttachmentURL = [];
                dataOldAttachmentURL = oldDataAttachments.map(item => item.URL);
                newAttachment.forEach((dataMoi) => {
                    if (dataOldAttachmentURL.indexOf(dataMoi.URL) === -1) {
                        // console.log("Đây chính là Add new Tệp đính kèm trong Mode EDIT");
                        let el = {};
                        el.codeID = newDataMaster.QuotationID;
                        el.formID = 'W05F0004';
                        el.linkedTrans = '';
                        el.newValue = dataMoi.FileName;
                        el.description84 = 'Đính kèm';
                        el.description01 = 'Attachment';
                        el.action = 1;
                        el.hasURL = false;
                        data.push(el);
                    }
                });
            }


        } else { //Mode Add Data
            el.codeID = newDataMaster.QuotationID;
            el.formID = 'W05F0004';
            el.action = 0;
            el.newValue = newDataMaster.VoucherNum;
            el.description84 = 'Đợt triển khai';
            el.description01 = 'Deployment’';
            el.hasURL = false;
            data.push(el);
            if (!_.isEmpty(newInventory)) {/// <=====================> Lưu Thêm Mới INVENTORIES - MODE: "ADD" <=====================>
                newInventory.forEach((dataMoi) => {
                    // console.log("Đây chính là Add new hàng hóa trong Mode ADD");
                    let el = {};
                    el.codeID = newDataMaster.QuotationID;
                    el.formID = 'W05F0004';
                    el.linkedTrans = '';
                    el.action = 2;
                    el.description84 = 'Lot';
                    el.description01 = 'Lot';
                    el.newValue = dataMoi.LotNo;
                    data.push(el);
                });
            }
            if (!_.isEmpty(newAttachment)) {/// <=====================> Lưu Thêm Mới ATTACHMENT - MODE: "ADD" <=====================>
                newAttachment.forEach((dataMoi) => {
                    // console.log("Đây chính là Add new tệp đính kèm trong Mode ADD");
                    let el = {};
                    el.codeID = newDataMaster.QuotationID;
                    el.formID = 'W05F0004';
                    el.linkedTrans = '';
                    el.action = 2;
                    el.description84 = 'Đính kèm';
                    el.description01 = 'Attachment';
                    el.newValue = dataMoi.FileName;
                    data.push(el);
                });
            }

        }
        if (data.length > 0) {
            const param = {
                attributes: JSON.stringify(data),
            };
            await this.props.W05F0005Actions.saveHistory(param, (error, data) => {
                if (error) {
                    status = 400;
                    this.setState({ isSave: false, loading: false })
                }
                if (data) {
                    this.key = -1;
                    this.keyDetail = -1;
                    this.isUpdated = false;
                    Config.notify.show("success", Config.lang("ERP_Luu_thanh_cong"), 2000);
                    this.getFormEdit(newDataMaster.QuotationID, newDataMaster.ContractID);
                    this.getHistory(newDataMaster.QuotationID);
                }
            });
        } else {
            this.setState({ loading: true, isView: true, isSave: false, isAdd: false, isCopy: false });
        }
        return status;
    };
    onSave = async () => {
        await this.refGrid.onSaveSwipeTab(() => {
            let { VoucherNum, Status, BankID, OriginalReduce, ValidTo, ValidFrom, isEdit,
                CurrencyID, ExchangeRate, CustomerID, ContractID, dataGrid,
                QuotationID, Quantity, EmployeeID, ContractNo
            } = this.state;
            // const validateRules = [
            //     {
            //         name: "OriginalReduce",
            //         rules: "isRequired",
            //         value: OriginalReduce
            //     },
            // ];
            // const validateForm = MForm.formValidation(validateRules);
            // if (this.setStateErrorText(validateForm)) return false;
            if (!ValidFrom || !ValidTo || ValidFrom > ValidTo) {
                if (ValidFrom > ValidTo) {
                    Config.popup.show('INFO', Config.lang('Ngay_tu_khong_duoc_lon_hon_ngay_den'));
                    return;
                }
                this.setState({
                    errorValidDate: Config.lang("Truong_nay_bat_buoc_nhap"),
                }, () => {
                    if (this.state.errorValidDate)
                        window.scrollTo(0, 0);
                });
                return false;
            }
            this.setState({ isSave: true })
            const attachments = this._getAttachments();
            let master = {
                ContractID: ContractID,
                VoucherNum: VoucherNum,
                Status: Status,
                BankID: BankID,
                OriginalReduce: OriginalReduce,
                ValidTo: ValidTo,
                ValidFrom: ValidFrom,
                CurrencyID: CurrencyID,
                ExchangeRate: ExchangeRate,
                CustomerID: CustomerID,
                QuotationID: QuotationID,
                Quantity: Quantity,
                EmployeeID: EmployeeID,
                ContractNo: ContractNo,
                UserName: Config.profile.UserNameU
            }
            const addedData = [];
            const editedData = [];
            let removedData = [];
            if (isEdit) {
                const oldData = this.oldDataGrid;
                const editedGrid = this.historyActions(oldData, dataGrid, "key", "edit")
                editedGrid.forEach(item => {
                    if (!_.isEmpty(item.receipts)) {
                        const IsCompleteVoucher = (item.receipts[0]).IsCompleteVoucher;
                        item.IsCompleteVoucher = IsCompleteVoucher;
                    }
                    if (item.IsUpdated === 1) {
                        editedData.push(item)
                    }
                })
                // if (editedGrid.length > 0) {
                //     const FieldName = this.getFieldName(oldData);
                //     editedGrid.forEach(dataMoi => {
                //         oldData.forEach(dataCu => {
                //             FieldName.map(fieldName => {
                //                 if (dataMoi[fieldName] !== dataCu[fieldName] && dataMoi.key === dataCu.key && fieldName !== 'fumigation' && fieldName !== 'Amount' && fieldName !== 'CAmount' &&
                //                     fieldName !== 'OriginalReduce' && fieldName !== 'CReduce'
                //                 ) {
                //                     editedData.push(dataMoi)
                //                 }
                //                 return false;
                //             });
                //         });
                //     })
                // }
                const addedGrid = this.historyActions(oldData, dataGrid, "key", "add");
                if (addedGrid.length > 0) {
                    addedGrid.forEach((dataMoi) => {
                        if (!_.isEmpty(dataMoi.receipts)) {
                            const IsCompleteVoucher = (dataMoi.receipts[0]).IsCompleteVoucher;
                            dataMoi.IsCompleteVoucher = IsCompleteVoucher;
                        }
                        addedData.push(dataMoi)
                    });
                }
                removedData = this.historyActions(oldData, dataGrid, "key", "delete");
            } else { // Add IsCompleteVoucher every inventory MODE: ADD
                dataGrid.forEach(item => {
                    if (!_.isEmpty(item.receipts)) {
                        const IsCompleteVoucher = (item.receipts[0]).IsCompleteVoucher;
                        item.IsCompleteVoucher = IsCompleteVoucher;
                    }
                })
            }
            let params = {
                master: JSON.stringify(master),
                detail: isEdit ? JSON.stringify({ added: addedData, edited: editedData, removed: removedData }) : JSON.stringify(dataGrid),
                attachments: JSON.stringify(attachments)
            }
            // console.log("=============removedData=========>", removedData);
            // this.setState({ loading: false, isSave: false })
            if (isEdit) {
                this.props.W05F0005Actions.onUpdate(params, async (error, data) => {
                    if (error) {
                        let message = "";
                        if (error.code === 'W05F0005E202') {
                            message = Config.language === "84" ? 'Tổng số lượng LOT vượt quá số lượng chưa triển khai còn lại của Hợp đồng' : 'Total Quantity LOT bigger than Quantity deployment of Contract'
                        } else {
                            message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                        }
                        Config.popup.show("INFO", message);
                        this.setState({ isSave: false });
                        return false;
                    }
                    else if (data) {
                        this.isUpdated = false;
                        await Config.unCheckRedirect();
                        await this.saveHistory(master, attachments, dataGrid);
                        this.loadDeliveryQuantity(QuotationID, VoucherNum);
                        this.setState({ loading: true, isView: true, isSave: false, isEdit: false, isAdd: false, isCopy: false });  // Chuyển sang mode = "View"
                    }
                });
            } else {
                this.props.W05F0005Actions.onInsert(params, async (error, data) => {
                    if (error) {
                        let message = "";
                        if (error.code === 'W05F0005E202') {
                            message = Config.language === "84" ? 'Tổng số lượng LOT vượt quá số lượng chưa triển khai còn lại của Hợp đồng' : 'Total Quantity LOT bigger than Quantity deployment of Contract'
                        } else {
                            message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                        }
                        Config.popup.show("INFO", message);
                        this.setState({ isSave: false });
                        return false;
                    }
                    else if (data) {
                        this.isUpdated = false;
                        await Config.unCheckRedirect();
                        master.QuotationID = data.QuotationID;
                        await this.saveHistory(master, attachments, dataGrid);
                        this.setState({ loading: true, isView: true, isSave: false, isEdit: false, isAdd: false, isCopy: false }); // Chuyển sang mode = "View"
                        this.loadDeliveryQuantity(data.QuotationID, VoucherNum);
                    }
                });
            }
        });
    }

    //CÁC FUNCTION CỦA TASK [EW-107] - KHÔNG ĐƯỢC XÓA
    handleBtnCombineLot = (status) => {
        this.refGrid.onSaveSwipeTab();
        if (_.isBoolean(status)) {
            if (!status) {// Nút hủy clear First Checked Lot
                if (!_.isEmpty(this.firstLotChecked)) {
                    this.state.dataGrid.forEach(obj => {
                        if (obj.IsChecked || obj.IsDifferentLot) { // 2 Field này chỉ dùng sử lý nội bộ nên delete nó
                            delete obj["IsChecked"];
                            delete obj["IsDifferentLot"];
                        }
                    })
                }
                this.firstLotChecked = {};
                this.setState({ btnCombineLot: status, allLotChecked: [] });
            } else {
                this.setState({ btnCombineLot: status, })
            }
        }
    }

    mathCombineLot = async (dataFilter, allCombinedLot) => { // Tính tổng lại các lot đã gộp vào thằng check đầu tiên
        if (_.isEmpty(this.firstLotChecked) || this.state.allLotChecked.length < 2 || _.isEmpty(dataFilter)) return;
        const originalDataFilter = dataFilter.map(item => ({ ...item }));
        const { dataFieldsCompare } = this.props;
        const { ListSum: sumCobineColumn } = dataFieldsCompare || {};
        let dataGrid = dataFilter;
        let allCombinedLotNew = [];
        const firstLotChecked = { ...this.firstLotChecked };
        const allColumnTabDiCont = ["BookingNo", "QuantityBooking", "DateActual", "VesselNameCont",
            "ObjectNameCont", "ObjectIDCont", "ContWeight", "PortToCont", "PortToContName", "PortName", "PortFromCont", "PortFromContName"];
        let allCombinedLotState = [...this.state.allCombinedLot, allCombinedLot[0]];
        await dataGrid.forEach(item => {
            if (item.QuotationItemID === firstLotChecked.QuotationItemID) {
                item["IsUpdated"] = 1; // Các lot đã gộp (Để lưu Save vào Detail Edit)
                sumCobineColumn.forEach(columnName => {
                    // Sum tổng lại các cột đã gộp
                    const resultSum = allCombinedLot.reduce((currentValue, sum) => _.toNumber(currentValue) + _.toNumber(sum[columnName]), 0);
                    item[columnName] = _.toNumber(item[columnName]) + resultSum;
                })
            }
        });
        if (!_.isEmpty(allCombinedLot)) { // Tất cả các cột của Tab Đi Cont lấy theo Lot Gộp chính (Lot đầu tiên)
            const allCombinedLotKeyName = Object.keys(allCombinedLot[0]);
            allCombinedLot.forEach(item => {
                allCombinedLotKeyName.forEach(name => {
                    if (allColumnTabDiCont.includes(name)) {
                        item[name] = firstLotChecked[name];
                    }
                })
                allCombinedLotNew.push(item);
            })
        }
        if (allCombinedLotNew.length > 1) {
            allCombinedLotState = [...this.state.allCombinedLot, ...allCombinedLotNew];
        }
        dataGrid = dataFilter.map(item1 => {
            originalDataFilter.forEach(item2 => {
                if (item1.LotNo === item2.LotNo && item1.LotNo === firstLotChecked.LotNo) {
                    allColumnTabDiCont.forEach(name => {
                        item1[name] = item2[name];
                    })
                }
            })
            return item1;
        })
        this.setState({ dataGrid, allCombinedLot: allCombinedLotState });
    }

    combineLotAction = () => {
        const { dataGrid } = this.state;
        const dataIsChecked = dataGrid.filter(item => item.IsChecked === 1);
        if (!_.isEmpty(dataIsChecked)) this.firstLotChecked = dataIsChecked[0]; // Set lại thằng đầu tiên của các lot đã check
        const allCombinedLot = dataGrid.filter(item => item.IsChecked === 1 && item.InventoryID === this.firstLotChecked.InventoryID && item.QuotationItemID !== this.firstLotChecked.QuotationItemID);
        allCombinedLot.forEach(item => {
            item["IsMerge"] = 1;
            item["QuotationItemIDMerge"] = this.firstLotChecked.QuotationItemID;
            item["LotNoMerge"] = this.firstLotChecked.LotNo;
        });
        // filter loại ra những thằng đã checked trừ thằng đầu tiên check và cùng InventoryID
        const dataFilter = dataGrid.filter((obj) => !allCombinedLot.some((obj2) => obj.QuotationItemID === obj2.QuotationItemID));
        // Filter tiếp loại bỏ tất cả row có cùng InventoryID trừ thằng check đầu tiên
        this.mathCombineLot(dataFilter, allCombinedLot);
    }

    setLotChecked = (status, data = {}) => {
        const { dataFieldsCompare } = this.props;
        const { dataGrid, allLotChecked } = this.state;
        let { ListEqual } = dataFieldsCompare || {};
        let allLotCheckedState = null;
        switch (true) {
            case status === true:  // Check lần đầu
                this.firstLotChecked = data;
                dataGrid.forEach(item => {
                    for (let key of ListEqual) {
                        // if (data.InventoryID !== item.InventoryID || data.SalesItemID !== item.SalesItemID || data.PackingMethodID !== item.PackingMethodID) {
                        if (data[key] !== item[key]) {
                            item.IsDifferentLot = 1; // Disable
                        }
                    }
                });
                allLotCheckedState = [data];
                break;
            case status === false:  // Check lần thứ n....
                allLotCheckedState = [...allLotChecked, data];
                break;
            case status === null:  // Bỏ check
                let allLotCheckedFilter = allLotChecked;
                allLotChecked.forEach(item => {
                    if (item.QuotationItemID === data.QuotationItemID) {
                        allLotCheckedFilter = allLotChecked.filter(obj => obj.QuotationItemID !== item.QuotationItemID);
                        return;
                    }
                });
                if (_.isEmpty(allLotCheckedFilter)) { // Nếu  bỏ check tất cả thì clear IsDifferentLot đi ;
                    this.firstLotChecked = {};
                    dataGrid.forEach(obj => {
                        if (obj.IsDifferentLot) delete obj["IsDifferentLot"];
                    });
                }
                allLotCheckedState = allLotCheckedFilter;
                break;
            default:
                break;
        }
        this.setState({ dataGrid, allLotChecked: allLotCheckedState });
    }
    //CÁC FUNCTION CỦA TASK [EW-107] - KHÔNG ĐƯỢC XÓA

    onDeliveryQuantity = (mode) => {
        const { QuotationID, ContractID } = this.state;
        if (this.ReloadRow && mode !== "open") { // Đóng và Có tác động xác nhận thì load lại lưới
            this.ReloadRow = false;
            this.getFormEdit(QuotationID, ContractID);
        }
        let status = false;
        status = mode === "open";
        this.firstTimeLoad = false;
        this.setState({ deliveryQuantityModal: status });
    }

    onAddInventory = () => {
        this.setState({ opend: !this.state.opend })
    }
    onUploading = (value) => {
        this.setState({ uploading: value });
    };
    onChangeAttachments = (e) => {
        this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            this.deletedFile = [...e.deletedFiles];
            const _arrRemove = this.deletedFile.map((d) => d.URL);
            this.setState(prevState => ({
                dataOldAttachments: prevState.dataOldAttachments.filter((att) => {
                    return _arrRemove.indexOf(att.URL) < 0;
                })
            }));
        }
    };
    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };
    getIdEstatus = async (isTab) => {
        await this.refGrid.onSaveSwipeTab(() => {
            this.setState({ EStatus: isTab })
        });
    };
    getDataSource = (data, key) => {
        let dataFilter = [...this.state.dataGrid];
        let newDataSource = [];
        if (!_.isEmpty(data) && !_.isEmpty(key)) {
            //dataFilter: grid 1
            //data: grid 2
            data.forEach((d, index) => {
                const dataValid = dataFilter.filter(df => df.SalesItemID === d.SalesItemID);
                dataValid && dataValid.map(rs => {
                    // this.key++;
                    // rs.key = this.key;
                    return rs;
                })
                if (dataValid && dataValid.length > 0) {
                    newDataSource = newDataSource.concat(dataValid);
                } else {
                    this.key++;
                    d.key = this.key;
                    newDataSource.push(d);
                }
            });
            this.getDecimals(data[0].CurrencyID);
            this.getVoucherNum(data[0].ContractID, (voucherNum) => {
                const { dataAdjustColumns } = this.props;
                let Vchernum = this.state.isCopy || this.state.isEdit ? this.state.VoucherNum : voucherNum;
                newDataSource.map((item, index) => {
                    if (_.isUndefined(item.DeliveryQuantity)) item["DeliveryQuantity"] = 0;
                    if (item.LotNo === undefined) {
                        item.LotNo = index < 10 ? data[0].ContractNo + '.' + Vchernum + '.0' + (index + 1) : data[0].ContractNo + '.' + Vchernum + '.' + (index + 1);
                    }
                    item.AdjustedUnitPrice = item.UnitPrice;
                    for (let aCol of dataAdjustColumns) {
                        if (aCol.Display === 1) {
                            item[aCol.ColID] = 0;
                        }
                    }
                    return item;
                });
                this.setState({
                    selectedRowKeys: key,
                    dataGrid: newDataSource,
                    ContractID: data[0].ContractID,
                    ContractNo: data[0].ContractNo,
                    BankID: data[0].BankID,
                    CurrencyID: data[0].CurrencyID,
                    ExchangeRate: data[0].ExchangeRate,
                    CustomerID: data[0].CustomerID,
                    VoucherNum: Vchernum
                }, () => {
                    this.setQuantity();
                })
            });
        } else { // Bổ Sung Bỏ check All
            this.key = -1;
            this.setState({
                selectedRowKeys: key,
                dataGrid: newDataSource,
                decimals: "", // getDecimals
                ContractID: "",
                ContractNo: "",
                BankID: "",
                CurrencyID: "",
                ExchangeRate: "",
                CustomerID: "",
                VoucherNum: ""
            }, () => {
                this.setQuantity();
            })
        }
    }
    reLoadDataSource = (data, type, dataHistoryFumu) => {
        if (type === 'LOT') {
            let { ExchangeRate } = this.state;
            let dataGrid = [...this.state.dataGrid];
            let array = [];
            dataGrid.map((item, index) => {
                if (item.QuotationItemID === data[0].QuotationItemID) {
                    item.Quantity = data[0].Quantity;
                    data.map((d, i) => {
                        if (i !== 0) {
                            d.QuotationItemID = i + String(new Date().getTime());
                            item.LotNo = index < 10 ? d.LotNo.substring(0, d.LotNo.lastIndexOf('.') + 1) + '0' + (index + 1) : d.LotNo.substring(0, d.LotNo.lastIndexOf('.') + 1) + (index + 1);
                            this.key++;
                            d.key = this.key;
                        }
                        array.push(d);
                        return d;
                    });
                } else {
                    array.push(item);
                }
                return item;
            });
            let setData = array;
            // let LotNo = setData[0].LotNo;
            setData.map((item, index) => {
                // item.LotNo = index < 10 ? LotNo.substring(0, LotNo.lastIndexOf('.') + 1) + '0' + (index + 1) : LotNo.substring(0, LotNo.lastIndexOf('.') + 1) + (index + 1);
                item.CAmount = item.Quantity * item.AdjustedUnitPrice * ExchangeRate;
                item.Amount = item.Quantity * item.AdjustedUnitPrice;
                item.OriginalReduce = item.Quantity * item.UnitPrice * (item.RateReduce !== undefined ? item.RateReduce : 0);
                item.CReduce = item.Quantity * item.UnitPrice * ExchangeRate * (item.RateReduce !== undefined ? item.RateReduce : 0);
                item.IsUpdated = 1;
                return item;
            });
            this.isUpdated = true;
            this.setState({ dataGrid: setData }, () => {
                this.setQuantity();
            });
        } else if (type === "receipts" || type === "payments") {
            if (type === "receipts") {
                const dataReceiptsIsUseByDesc = _.orderBy(data.receipts, ["IsUse"], ["desc"]);// Sort IsUse By Desc Checked
                data.receipts = _.orderBy(dataReceiptsIsUseByDesc, ["IsComplete"], ["desc"]); // Sort IsComplete By Desc Checked
            }
            const { dataGrid } = this.state;
            const popupData = dataGrid.map((item) => {
                if (item.QuotationItemID === data.QuotationItemID) {
                    return {
                        ...item,
                        IsUpdated: 1,
                        [type]: data[type]
                    }
                }
                return item;
            });
            this.isUpdated = true;
            this.setState({ dataGrid: popupData });
        } else {
            const { dataGrid } = this.state;
            let tmpData = [];
            tmpData = dataGrid.map((item, index) => {
                if (item.QuotationItemID === data.QuotationItemID) {
                    return {
                        ...item,
                        IsUpdated: 1,
                        fumigation: {
                            method: data.method,
                            detail: data.detail
                        }
                    }
                }
                return item;
            });
            let dataFumu = dataHistoryFumu.length > 0 ? dataHistoryFumu : [];
            this.isUpdated = true;
            this.setState({ dataGrid: tmpData, dataHistoryFumu: this.state.dataHistoryFumu.concat(dataFumu) });
        }
    }

    sumData = (array, field) => {
        const SumQ = array.reduce(
            (accumulator, currentValue) => Number(accumulator) + Number(currentValue[field]), 0
        );
        return SumQ;
    }
    setQuantity = () => {
        const { dataGrid } = this.state;
        const Quantity = this.sumData(dataGrid, "Quantity");
        const CTotal = this.sumData(dataGrid, "CAmount");
        const OTotal = this.sumData(dataGrid, "Amount");
        this.isUpdated = true;
        this.setState({ Quantity: Quantity, CTotal: CTotal, OTotal: OTotal });
    }
    onChangePageHistory = (page) => {
        this.filterHistory.skip = page * this.filterHistory.limit;
        this.getHistory(this.state.QuotationID);

    }
    onChangePerPageHistory = (perPage) => {
        this.filterHistory.skip = 0;
        this.filterHistory.limit = perPage;
        this.getHistory(this.state.QuotationID);
    }
    goBack = () => {
        if (this.isUpdated) {
            Config.popup.show('YES_NO', Config.lang("ERP_Du_lieu_chua_duoc_luu_ban_co_muon_thoat_khong"), () => {
                browserHistory.goBack();
            });
        } else {
            browserHistory.goBack();
        }
    }
    setIsUpdate = () => {
        this.isUpdated = true;
    }
    setRefreshPage = (status) => { // RefreshPage Only
        if (status) this.setState({ refreshPage: status });
    }

    getCbLocationNo = () => {
        this.props.W05F0005Actions.getCbLocationNo({ Language: Config.language || 84, FormID: 'W05F0005' }, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            this.setState({ cbLocationNo: data });
        });
    }

    render() {
        if (!this.getInfo()) return null;
        let { ContractNo, VoucherNum, Status, BankID, OriginalReduce, ValidTo, ValidFrom, Quantity, OTotal, CTotal, CbStatusM, CbBank,
            CurrencyID, ExchangeRate, CustomerID, opend, loading, QuotationID, ContractID, EStatus, dataGrid, tabDetailSupplement, cbStatusD,
            cbPackingMethod, cbPackingType, cbMarking, decimals, cbPort, errorValidDate, cbVessel, cbEstimate, cbUnitPack, confirmDeliveryQuantity,
            isView, isEdit, selectedRowKeys, dataHistory, isSave, isOpenHistory, isCopy, cbShippingAgent1, cbShippingAgent2, btnDeliveryQuantity,
            deliveryQuantityModal, btnCombineLot, isLoadForm, allLotChecked, isAdd, dataCbCurator, EmployeeID, loadingE, cbLocationNo, isPerW05F2004 } = this.state;
        const iconArrowDown = require('../../../../assets/images/general/arrow_down.svg');
        const iconArrowRight = require('../../../../assets/images/general/arrow_right.svg');
        const iconHistory = isOpenHistory ? iconArrowDown : iconArrowRight;
        return (
            <>
                {opend && <W05F0005Popup opend={opend} onClose={this.onAddInventory} isTab={EStatus} tabDetailSupplement={tabDetailSupplement}
                    dataSource={this.getDataSource} QuotationID={QuotationID} ContractID={ContractID} isEdit={isEdit} selectedRowKeys={selectedRowKeys}
                    dataGrid={dataGrid} isCopy={isCopy} isView={isView} cbStatusD={cbStatusD}
                />}
                {deliveryQuantityModal && isView &&
                    <W05F0005DeliveryQuantity
                        updateRow={() => this.ReloadRow = true}
                        ContractNo={ContractNo}
                        VoucherNum={VoucherNum}
                        QuotationID={QuotationID}
                        rowData={confirmDeliveryQuantity}
                        onOpenDeliveryQuantityModal={deliveryQuantityModal}
                        loadDeliveryQuantity={() => this.loadDeliveryQuantity(QuotationID, VoucherNum)}
                        onCloseDeliveryQuantityModal={() => this.onDeliveryQuantity("close")}
                        updateDeleveryQuantityNoti={(data) => this.setState({ confirmDeliveryQuantity: data })}
                    />}
                <LoadPanel
                    shadingColor={"rgba(0,0,0,0.4)"}
                    position={{ my: 'center', of: "#content" }}
                    visible={isSave}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                />
                <FormGroup>
                    <ActionToolbar allwaysTop style={{ padding: '6px 0 6px 4px' }} title={Config.lang("ERP_Chi_tiet_dot_trien_khai")} onBack={this.goBack} >
                        {isView ?
                            <ButtonGeneral disabled={false}
                                name={Config.lang("ERP_Sua")}
                                typeButton={"edit"}
                                style={{ textTransform: 'uppercase' }}
                                onClick={() => this.setState({ isView: false, isEdit: true, loading: false, isAdd: false, isCopy: false })}
                                icon={require("./edit.svg")}
                            /> :
                            <ButtonGeneral disabled={(dataGrid && dataGrid.length > 0 ? false : true) || btnCombineLot}
                                name={Config.lang("ERP_Luu")}
                                typeButton={"save"}
                                style={{ textTransform: 'uppercase' }}
                                onClick={this.onSave}

                            />}
                    </ActionToolbar>
                </FormGroup>
                <div className={"content"}>
                    <FormGroup >
                        <Row>
                            <Col xs={12} >
                                <TextField
                                    label={Config.lang('ERP_So_hop_dong')}
                                    className={"W05F0005-Form"}
                                    variant={"standard"}
                                    margin={"normal"}
                                    InputLabelProps={{
                                        shrink: true,
                                        readOnly: true,
                                    }}
                                    style={{ width: 264, paddingRight: 20 }}
                                    value={ContractNo}
                                    disabled={true}
                                />
                                <TextField
                                    label={Config.lang('ERP_Ma_trien_khai')}
                                    className={"W05F0005-Form"}
                                    variant={"standard"}
                                    margin={"normal"}
                                    InputLabelProps={{
                                        shrink: true,
                                        readOnly: true,
                                    }}
                                    value={String(VoucherNum)}
                                    style={{ width: 264, paddingRight: 20 }}
                                    disabled={true}
                                />
                                <Combo
                                    deferChangeValue={true}
                                    dataSource={CbStatusM}
                                    className={"W05F0005-Form"}
                                    InputLabelProps={{
                                        shrink: true,
                                        required: true
                                    }}
                                    displayExpr={"StatusName"}
                                    valueExpr={"Status"}
                                    stylingMode={'underlined'}
                                    value={Status}
                                    label={Config.lang('ERP_Trang_thai')}
                                    style={{ width: 264, paddingRight: 20, marginTop: 15 }}
                                    onValueChanged={(e) => this.handleChange("Status", e)}
                                    disabled={loading}
                                />
                                <Combo.LoadMore
                                    dataSource={dataCbCurator}
                                    displayExpr={"ObjectNameU"}
                                    valueExpr={"ObjectID"}
                                    disabled={loading}
                                    loading={loadingE}
                                    value={EmployeeID}
                                    className={"W05F0005-Form"}
                                    onValueChanged={e => this.handleChange("EmployeeID", e)}
                                    skip={this.filterCurator.skip}
                                    limit={this.filterCurator.limit}
                                    shrink
                                    showClearButton={true}
                                    keyExpr={'ObjectID'}
                                    stylingMode={'underlined'}
                                    label={Config.lang("ERP_Nguoi_phu_trach")}
                                    style={{ width: 264, paddingRight: 20, marginTop: 15 }}
                                    onInputChanged={(e) => {
                                        this.filterCurator.StrSearch = e.target.value;
                                        this.filterCurator.skip = 0;
                                        this.getCboCurator(true);
                                    }}
                                    itemRender={(e) => {
                                        const { data } = e;
                                        if (!data) return null;
                                        return `${data.ObjectID} - ${data.ObjectNameU}`;
                                    }}
                                    onLoadMore={(e) => {
                                        this.filterCurator.skip = e.skip;
                                        this.filterCurator.limit = e.limit;
                                        this.getCboCurator();
                                    }}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col xs={12} >
                                <Combo
                                    dataSource={CbBank}
                                    className={"W05F0005-Form"}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    displayExpr={(data) => {
                                        if (!data) return null;
                                        return `${data.BankName} - ${data.ShortName}`;
                                    }}
                                    valueExpr={"BankCode"}
                                    stylingMode={'underlined'}
                                    value={BankID}
                                    itemRender={data => {
                                        if (!data) return null;
                                        return `${data.BankName} - ${data.ShortName}`;
                                    }}
                                    label={Config.lang('ERP_Ngan_hang')}
                                    style={{ width: 264, paddingRight: 20, marginTop: 15 }}
                                    onValueChanged={(e) => this.handleChange("BankID", e)}
                                    disabled={loading}
                                />
                                <NumberFormat
                                    className={"W05F0005-Form"}
                                    label={Config.lang("ERP_Gia_mo_to_khai")}
                                    customInput={TextField}
                                    thousandSeparator={true}
                                    isNumericString={true}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    variant={"standard"}
                                    margin={"normal"}
                                    style={{ width: 264, paddingRight: 20 }}
                                    disabled={loading}
                                    decimalScale={decimals ? String(decimals.OriginalDecimal) : '6'}
                                    onValueChange={(e) => this.handleChange("OriginalReduce", e)}
                                    value={String(OriginalReduce) || ''}
                                />
                                {/* <TextField
                                    label={Config.lang('ERP_Gia_mo_to_khai')}
                                    variant={"standard"}
                                    margin={"normal"}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    InputProps={{
                                        inputComponent: NumberFormatCustom
                                    }}
                                    id={decimals ? String(decimals.OriginalDecimal) : '6'}
                                    style={{ width: 264, paddingRight: 20 }}
                                    value={String(OriginalReduce) || ''}
                                    onChange={(e) => this.handleChange('OriginalReduce', e)}
                                    disabled={loading}
                                /> */}
                                <FormControl variant={"standard"}
                                    margin={"normal"}
                                    className={"W05F0005-Date"}
                                >
                                    <InputLabel
                                        shrink={true}
                                        required
                                    >
                                        {Config.lang("ERP_Thoi_gian_giao_hang_du_kien")}
                                    </InputLabel>
                                    <div className={"display_row align-center"} style={{ verticalAlign: 'top', display: 'inline-flex', paddingRight: 20 }}>
                                        <Label className={"mgr5 display_row"}>{Config.lang("ERP_Tu")} <span style={{ color: 'red' }}>*</span></Label>
                                        <DateBoxPicker
                                            width={215}
                                            shrink={true}
                                            required={true}
                                            useMaskBehavior={true}
                                            showClearButton={true}
                                            value={ValidFrom}
                                            disabled={loading}
                                            max={ValidTo || ""}
                                            error={errorValidDate}
                                            stylingMode={"underlined"}
                                            placeholder={'dd/mm/yyyy'}
                                            className={"mgr10"}
                                            margin={"normal"}
                                            onValueChanged={e => this.handleChange("ValidFrom", e)}
                                        />
                                        <Label className={"mgr5 display_row"}>{Config.lang("ERP_Den")} <span style={{ color: 'red' }}>*</span></Label>
                                        <DateBoxPicker
                                            width={215}
                                            shrink={true}
                                            required={true}
                                            useMaskBehavior={true}
                                            showClearButton={true}
                                            value={ValidTo}
                                            disabled={loading}
                                            min={ValidFrom || ""}
                                            error={errorValidDate}
                                            margin={"normal"}
                                            stylingMode={"underlined"}
                                            placeholder={'dd/mm/yyyy'}
                                            onValueChanged={e => this.handleChange("ValidTo", e)}
                                        />
                                    </div>
                                </FormControl>
                            </Col>

                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col xs={12} >
                                <TextField
                                    label={Config.lang("ERP_So_luong")}
                                    className={"W05F0005-Form"}
                                    variant={"standard"}
                                    margin={"normal"}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                        inputComponent: NumberFormatCustom,

                                    }}
                                    id={decimals ? String(decimals.QuantityDecimals) : '5'}
                                    style={{ width: 264, paddingRight: 20 }}
                                    value={String(Quantity)}
                                    disabled={true}
                                />
                                <TextField
                                    label={Config.lang("ERP_Gia_tri_dot_trien_khai")}
                                    className={"W05F0005-Form"}
                                    variant={"standard"}
                                    margin={"normal"}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                        inputComponent: NumberFormatCustom
                                    }}
                                    id={decimals ? String(decimals.OriginalDecimal) : '6'}
                                    style={{ width: 264, paddingRight: 20 }}
                                    value={String(OTotal)}
                                    disabled={true}

                                />
                                <TextField
                                    label={Config.lang('ERP_Gia_tri_dot_trien_khai_QD')}
                                    className={"W05F0005-Form"}
                                    variant={"standard"}
                                    margin={"normal"}
                                    InputLabelProps={{
                                        shrink: true,
                                        readOnly: true,
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                        inputComponent: NumberFormatCustom
                                    }}
                                    id={decimals ? String(decimals.ConvertedDecimal) : '0'}
                                    style={{ width: 264, paddingRight: 20 }}
                                    value={String(CTotal)}
                                    disabled={true}
                                />
                                <TextField
                                    style={{ width: 264, paddingRight: 20 }}
                                    disabled={true}
                                    margin={"normal"}
                                    variant={"standard"}
                                    className={"W05F0005-Form"}
                                    label={Config.lang("ERP_Loai_tien")}
                                    value={CurrencyID}
                                    InputLabelProps={{
                                        shrink: true,
                                        readOnly: true,
                                    }}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup className={"hidden"}>
                        <Row>
                            <Col xs={12} >
                                <TextField
                                    label={""}
                                    value={QuotationID || ""}
                                />
                                <TextField
                                    label={""}
                                    value={ContractID || ""}
                                />
                                {/* <TextField
                                    label={Config.lang("ERP_Loai_tien")}
                                    value={CurrencyID || ""}
                                /> */}
                                <TextField
                                    label={Config.lang("ERP_Ty_gia")}
                                    value={String(ExchangeRate) || ""}
                                />
                                <TextField
                                    label={Config.lang("ERP_Khach_hang")}
                                    value={CustomerID || ""}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                </div>
                <div style={{ marginBottom: 15 }}>
                    {!isView &&
                        <ButtonGeneral disabled={false}
                            name={Config.lang("ERP_Them_hang_hoa")}
                            typeButton={"add"}
                            color={"primary"}
                            variant={"outlined"}
                            style={{ textTransform: 'uppercase', marginRight: 20 }}
                            onClick={this.onAddInventory}
                        />}
                    {isEdit && !btnCombineLot && dataGrid.length > 1 &&
                        <ButtonGeneral
                            disabled={false}
                            name={Config.lang("ERP_Gop_lot")}
                            color={"primary"}
                            variant={"outlined"}
                            style={{ textTransform: 'uppercase', marginRight: 20 }}
                            onClick={() => {
                                this.handleBtnCombineLot(true);
                                this.LotTemp = JSON.parse(JSON.stringify(dataGrid));
                                this.allCombinedLotTemp = JSON.parse(JSON.stringify(this.state.allCombinedLot));
                            }}
                        />}
                </div>
                {isView &&
                    <FormGroup>
                        <Tooltip title={Config.lang("ERP_Xac_nhan_so_luong_da_giao")}>
                            <ButtonGeneral
                                disabled={false}
                                color={"primary"}
                                variant={"custom"}
                                typeButton={"confirm_list"}
                                loading={btnDeliveryQuantity}
                                notiNumber={confirmDeliveryQuantity.length}
                                style={{ textTransform: 'uppercase' }}
                                onClick={() => this.onDeliveryQuantity("open")}
                            />
                        </Tooltip>
                    </FormGroup>}
                {dataGrid.length > 0 &&
                    <>
                        <W05F0005Tabs funcGetEStatus={this.getIdEstatus} />
                        <div style={{ marginTop: '-15px' }}>
                            <W05F0005TabContent onRef={ref => this.refGrid = ref} EStatus={EStatus} dataGrid={dataGrid} tabDetailSupplement={tabDetailSupplement}
                                cbStatusD={cbStatusD} cbPackingMethod={cbPackingMethod} cbPackingType={cbPackingType} cbMarking={cbMarking}
                                decimals={decimals} cbPort={cbPort} cbVessel={cbVessel} cbEstimate={cbEstimate} cbUnitPack={cbUnitPack}
                                filterGeneral10={this.filterGeneral10} filterGeneral20={this.filterGeneral20} filterGeneral30={this.filterGeneral30} reLoadDataSource={this.reLoadDataSource}
                                isView={isView} isAdd={isAdd} ExchangeRate={ExchangeRate} isEdit={isEdit} filterShippingAgent1={this.filterShippingAgent1}
                                filterCbVessel={this.filterCbVessel} filterShippingAgent2={this.filterShippingAgent2} filterFumiCompany={this.filterFumiCompany}
                                onRefW05F0005={this} cbShippingAgent1={cbShippingAgent1} cbShippingAgent2={cbShippingAgent2} btnCombineLot={btnCombineLot}
                                firstLotChecked={this.firstLotChecked} isLoadForm={isLoadForm} ContractNo={ContractNo} VoucherNum={VoucherNum} QuotationID={QuotationID}
                                cbLocationNo={cbLocationNo} isPerW05F2004={isPerW05F2004}
                            />
                        </div>
                    </>
                }
                {isEdit && btnCombineLot &&
                    <div style={{ marginBottom: 15 }}>
                        <ButtonGeneral
                            disabled={allLotChecked.length < 2 || !!allLotChecked.find(i => i.IsDifferentLot)} // 2 Lot trở lên mới cho gộp
                            name={Config.lang("ERP_Gop")}
                            color={"primary"}
                            variant={"outlined"}
                            style={{ textTransform: 'uppercase', marginRight: 20 }}
                            onClick={() => this.combineLotAction()}
                        />
                        <ButtonGeneral
                            disabled={false}
                            name={Config.lang("ERP_Luu")}
                            color={"primary"}
                            variant={"outlined"}
                            style={{ textTransform: 'uppercase', marginRight: 20 }}
                            onClick={() => this.handleBtnCombineLot(false)}
                        />
                        <ButtonGeneral
                            disabled={false}
                            name={Config.lang("Huy")}
                            color={"primary"}
                            variant={"outlined"}
                            style={{ textTransform: 'uppercase', marginRight: 20 }}
                            onClick={() => this.setState({ btnCombineLot: false, dataGrid: this.LotTemp, allCombinedLot: this.allCombinedLotTemp })}
                        />
                    </div>
                }
                {!isView ?
                    <FormGroup>
                        <Col xs={12} lg={12} md={12} className={"pd0"} >
                            <Attachments
                                ref={ref => this.attRef = ref}
                                showButton={true}
                                files={this.state.dataOldAttachments}
                                disabled={this.state.uploading}
                                maxLength={5}
                                uploading={loading}
                                onUploading={this.onUploading}
                                onChanged={this.onChangeAttachments}
                            />
                        </Col>
                        <Col xs={12} className={"pd0"}>
                            <ButtonGeneral
                                name={Config.lang("ERP_Dinh_kem")}
                                color={"primary"}
                                typeButton={'attachment'}
                                variant={"outlined"}
                                disabled={false}
                                style={{ textTransform: "uppercase" }}
                                size={"large"}
                                onClick={this.onAttachment}
                            />
                        </Col>
                    </FormGroup> :
                    !_.isEmpty(this.state.dataOldAttachments) && <AttachmentCustom height={window.innerHeight - 100} data={this.state.dataOldAttachments} />
                }

                {isView &&
                    <>
                        <div style={{ display: 'flex' }} className={"cursor-pointer pdb10"} onClick={() => this.setState({ isOpenHistory: !this.state.isOpenHistory })}>
                            <Image src={iconHistory} style={{ marginRight: 5 }} />
                            <Typography
                                style={{
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    textTransform: 'uppercase',
                                    color: '#0095FF',
                                    transform: 'translateY(3px)',
                                }}>{Config.lang("ERP_Lich_su")}</Typography>
                        </div>
                        <Collapse isOpen={isOpenHistory}>
                            <FormGroup style={{ padding: '18px 18px 0 18px' }}>
                                <ViewHistory
                                    dataHistory={dataHistory.rows} totalItems={dataHistory.total} limit={this.filterHistory.limit} skip={this.filterHistory.skip}
                                    onChangePage={this.onChangePageHistory} onChangePerPage={this.onChangePerPageHistory}
                                />
                            </FormGroup>
                        </Collapse>

                    </>
                }
            </>
        );
    }
}
class NumberFormatCustom extends React.Component {
    render() {
        const { name, inputRef, onChange, id, ...props } = this.props;
        return (
            <NumberFormat
                {...props}
                // value={value}
                getInputRef={inputRef}
                thousandSeparator
                decimalScale={Number(id)}
                onValueChange={(e) => {
                    if (onChange) {
                        onChange({
                            target: {
                                name: name,
                                value: e.value
                            }
                        })
                    }
                }}
            />
        );
    }
}
const mapStateToProps = (state) => {
    return {
        oldDataAttachments: state.W05F0005.oldDataAttachments,
        oldDataMaster: state.W05F0005.oldDataMaster,
        getFlexCaption: state.W05F0005.getFlexCaption,
        dataFieldsCompare: state.W05F0005.dataFieldsCompare,
        dataAdjustColumns: state.W05F0005.dataAdjustColumns,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        W05F0005Actions: bindActionCreators(W05F0005Actions, dispatch),
        generalActions: bindActionCreators(generalActions, dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(W05F0005);
