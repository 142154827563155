import { Dialog, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import SearchIcon from '@material-ui/icons/Search';
import { Column } from "devextreme-react/data-grid";
import PropTypes from "prop-types";
import React, { PureComponent } from 'react';
import { FormGroup } from "react-bootstrap";
import { connect } from 'react-redux';
import { bindActionCreators, compose } from "redux";
import Config from '../../../../config/index';
import * as W05F0004Actions from '../../../../redux/W0X/W05F0004/W05F0004_actions';
import ButtonGeneral from "../../../common/button/button-general";
import GridContainer from "../../../grid-container/grid-container";

const styles = {
    paper: {
        alignSelf: 'start',
        overflowX: 'hidden'
    },
    searchTitle: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: '#222B45',
        fontSize: '12px',
        marginTop: 17
    },
    circleBtn: {
        borderRadius: '100%',
        minWidth: 0
    },
};
class cbLoadmore extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            loading: false,
            dataGrid: [],
            totalDataGrid: 0,
            selectedRowKeys: [],
            filterGridValue: [],
        };
        this.filter = this.props.filter;
        this.tmpSelectedRowData = [];
        this.tmpSelectedRowKeys = [];
        this.changePage = false;
    }
    getCbData = (filter) => {
        this.setState({ loading: true });
        this.props.W05F0004Actions.getListObjects(filter, (error, data) => {
            this.setState({ loading: false })
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                let temp = [];
                switch (filter.O08ID) {
                    case "10":
                        data.rows.map((item) => {
                            temp.push({ UnitPackID: item.ObjectID, UnitPackName: item.ObjectNameU });
                            return temp;
                        });
                        break;
                    case "20":
                        data.rows.map((item) => {
                            temp.push({ EstimateID: item.ObjectID, EstimateName: item.ObjectNameU });
                            return temp;
                        });

                        break;
                    case "30":
                        data.rows.map((item) => {
                            temp.push({ FumigationCompany: item.ObjectID, FumigationCompanyName: item.ObjectNameU });
                            return temp;
                        });
                        break;
                    default:
                        break;
                }
                if (filter.ObjectTypeID) {
                    data.rows.map((item) => {
                        temp.push({ FumigationCompany: item.ObjectID, FumigationCompanyName: item.ObjectNameU });
                        return temp;
                    });
                }
                this.setState({ dataGrid: temp, totalDataGrid: data.total });
            }
        });
    }
    open = () => {
        const { onOpen } = this.props;
        this.getCbData(this.filter);
        this.setState({
            isOpen: true,
            selectedRowKeys: this.tmpSelectedRowKeys
        }, () => {
            if (onOpen) onOpen();
        });

    };
    close = (e) => {
        const { onClose } = this.props;
        this.setState({
            isOpen: false
        }, () => {
            if (onClose) onClose(e);
        });
    };

    onSave = () => {
        const { onSave } = this.props;
        if (onSave) onSave(this.tmpSelectedRowData);
        this.close();
    };
    // setSelected = (e) => {
    //     const { keyExpr } = this.props;
    //     const currentSelectedRowKeys = e.selectedRowsData;
    //     const currentDeselectedRowKeys = e.currentDeselectedRowKeys;
    //     if (currentDeselectedRowKeys.length > 0) {
    //         if (currentDeselectedRowKeys.length === 1) {
    //             this.tmpSelectedRowData = this.tmpSelectedRowData.filter((e) => {
    //                 return currentDeselectedRowKeys.indexOf(e[keyExpr]) < 0;
    //             });
    //             this.tmpSelectedRowKeys = this.tmpSelectedRowKeys.filter((e) => {
    //                 return currentDeselectedRowKeys.indexOf(e) < 0;
    //             });
    //         } else {
    //             currentDeselectedRowKeys.forEach(val => {
    //                 if (this.tmpSelectedRowKeys.indexOf(val) < 0) {
    //                     this.tmpSelectedRowKeys.push(val);
    //                 }
    //             });
    //         }

    //     } else if (currentSelectedRowKeys.length > 0) {
    //         currentSelectedRowKeys.forEach((val) => {
    //             if (this.tmpSelectedRowKeys.indexOf(val[keyExpr]) < 0) {
    //                 this.tmpSelectedRowData.push(val);
    //                 this.tmpSelectedRowKeys.push(val[keyExpr]);
    //             }
    //         });
    //     }
    // };
    onSelectionChanged = (e) => {
        const { mode, keyExpr } = this.props;
        if (this.changePage) {
            const selectedRowsData = e.selectedRowsData;
            if (selectedRowsData.length > 0) {
                selectedRowsData.forEach((val) => {
                    this.tmpSelectedRowData = [val]
                    this.tmpSelectedRowKeys = [val[keyExpr]];
                });
            }
            this.setState({ selectedRowKeys: this.tmpSelectedRowKeys });
            this.changePage = false;
        } else {
            if (mode === "single") {
                const selectedRowsData = e.selectedRowsData;
                const currentDeselectedRowKeys = e.currentDeselectedRowKeys;
                if (currentDeselectedRowKeys.length > 0) {
                    // console.log('currentDeselectedRowKeys', currentDeselectedRowKeys)
                    // currentDeselectedRowKeys.forEach(val => {
                    //     if (this.tmpSelectedRowKeys.indexOf(val) < 0) {
                    //         this.tmpSelectedRowKeys = [];
                    //         this.tmpSelectedRowData = []
                    //     }
                    // })
                } else if (selectedRowsData.length > 0) {
                    // console.log('selectedRowsData', selectedRowsData)
                    selectedRowsData.forEach((val) => {
                        this.tmpSelectedRowData = [val]
                        this.tmpSelectedRowKeys = [val[keyExpr]];
                    });
                }
                this.setState({ selectedRowKeys: this.tmpSelectedRowKeys });
            }
            // else {
            //     this.setSelected(e);
            //     this.setState({
            //         selectedRowKeys: e.selectedRowKeys
            //     });
            // }

        }
    };

    onFilter = () => {
        this.filter.skip = 0;
        this.getCbData(this.filter);
    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.getCbData(this.filter)
        this.changePage = true;
    };

    onChangePerPage = (perPage) => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.getCbData(this.filter)
        this.changePage = true;
    };
    onInputchange = (value) => {
        this.filter.strSearch = value;
    }
    render() {
        const { dataSource, column, itemPerPage, title, component, children, className, style, fullWidth, maxWidth, mode,
            keyExpr, transition, onOpen, onClose, typeShort, skipPerPage, pagerFullScreen,
            reference, isStrSearch, dialogProps, classes,
            ...props } = this.props;
        const { isOpen, dataGrid, loading, totalDataGrid } = this.state;
        const popupTransitions = Config.helpers.popupTransitions;
        const Trans = transition && popupTransitions[transition] ? popupTransitions[transition] : Config.popupTransition;
        if (props.onSave) delete props.onSave;
        return (
            <React.Fragment >
                <Dialog
                    {...dialogProps}
                    open={isOpen}
                    className={className}
                    style={style}
                    fullWidth={fullWidth}
                    maxWidth={maxWidth ? maxWidth : 'md'}
                    PaperProps={{
                        style: styles.paper,
                    }}
                    aria-labelledby={"alert-dialog-title"}
                    aria-describedby={"alert-dialog-description"}
                    onEntered={this._onEntered}
                    onClose={this.close}
                    TransitionComponent={Trans}
                >
                    <DialogTitle disableTypography style={{ justifyContent: 'space-between' }} className="center">
                        <Typography variant="h6" className="text-uppercase">{title ? title : ''}</Typography>
                        <div>
                            <ButtonGeneral name={Config.lang("ERP_Dong")}
                                typeButton={"cancel"}
                                color={"default"}
                                variant={"text"}
                                // disabled={loading}
                                className={"mgr5"}
                                style={{ textTransform: 'uppercase' }}
                                size={"large"}
                                onClick={this.close} />
                            <ButtonGeneral name={Config.lang("ERP_Chon")}
                                color={"primary"}
                                variant={"contained"}
                                loading={loading}
                                style={{ textTransform: 'uppercase' }}
                                size={"large"}
                                onClick={this.onSave} />
                        </div>
                    </DialogTitle>
                    <DialogContent dividers style={{ height: "100%" }} className={"content"}>
                        <FormGroup>
                            {isStrSearch && <>
                                <span className={classes.searchTitle}>{Config.lang("ERP_Tim_kiem")} {title}</span>
                                <FormGroup>
                                    <TextField
                                        placeholder={`${Config.lang("ERP_Tim_kiem")} ${title.toLowerCase()}`}
                                        variant={"outlined"}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <Button className={classes.circleBtn} onClick={this.onFilter}>
                                                    <SearchIcon />
                                                </Button>
                                            )
                                        }}
                                        onChange={(e) => this.filter.StrSearch = e.target.value}
                                        fullWidth
                                    />
                                </FormGroup>
                            </>}
                            <GridContainer
                                reference={ref => this.dataGrid = ref}
                                totalItems={totalDataGrid}
                                itemPerPage={this.filter.limit}
                                skipPerPage={this.filter.skip}
                                listPerPage={[15, 30, 45, 60]}
                                dataSource={dataGrid}
                                disabled={false}
                                keyExpr={keyExpr}
                                gridProps={{
                                    style: {
                                        minHeight: 400,
                                    }
                                }}
                                pagerFullScreen={false}
                                showBorders={false}
                                columnAutoWidth={true}
                                typeShort={window.innerWidth < 768}
                                loadPanel={{
                                    enabled: loading
                                }}
                                height={"calc(100vh - 250px)"}
                                selection={{
                                    allowSelectAll: false,
                                    mode: "multiple",
                                    selectAllMode: "",
                                    showCheckBoxesMode: "always"
                                }}
                                allowColumnResizing={true}
                                filterRow={{
                                    visible: false,
                                    showOperationChooser: false,
                                }}
                                filterPanel={{
                                    visible: false,
                                }}
                                filterBuilderPopup={{
                                    onInitialized: (e) => {
                                        e.component.dispose();
                                        e.element.remove();
                                    }
                                }}
                                selectedRowKey={this.tmpSelectedRowKeys}
                                onChangePage={this.onChangePage}
                                onChangePerPage={this.onChangePerPage}
                                onSelectionChanged={this.onSelectionChanged}
                            >
                                {column.map((e) => {
                                    return (
                                        <Column
                                            key={e.dataField}
                                            caption={e.caption}
                                            dataField={e.dataField}
                                            width={e.width}
                                        />
                                    );
                                })}
                            </GridContainer>
                        </FormGroup>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }
}
cbLoadmore.defaultProps = {
    isStrSearch: true,
    mode: "single"
};
cbLoadmore.propTypes = {
    dataSource: PropTypes.any,
    column: PropTypes.any,
    keyExpr: PropTypes.string,
    open: PropTypes.bool,
    value: PropTypes.any,
    mode: PropTypes.string, //'single', 'multiple'
    className: PropTypes.string,
    style: PropTypes.string,
    title: PropTypes.string,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.string,

    onClose: PropTypes.func,
    onSave: PropTypes.func,

    isStrSearch: PropTypes.bool,
    dialogProps: PropTypes.any,
    selectedRowKeys: PropTypes.any
};

const mapStateToProps = (state) => {
    return {
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        W05F0004Actions: bindActionCreators(W05F0004Actions, dispatch)
    }
}
export default compose(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true }), withStyles(styles))(cbLoadmore);