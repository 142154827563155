import { FormLabel as Label, Tab, Tabs } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import { TextBox } from 'devextreme-react';
import { Column } from "devextreme-react/data-grid";
import PropTypes from "prop-types";
import React, { Component } from 'react';
import { Col, Row } from "react-bootstrap";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Config from '../../../../config';
import * as W05F0005Actions from '../../../../redux/W0X/W05F0005/W05F0005_actions';
import { Combo } from "../../../common/form-material";
import Modal from '../../../common/modal/modal';
import TabContent, { TabPanel } from "../../../common/tabs/tab-content";
import GridActionBar from '../../../grid-container/grid-actionbar';
import GridContainer from "../../../grid-container/grid-container";
import CbLoadmore from './cbLoadmore';
import { ButtonIcon, MoneyInput, TextInput } from 'diginet-core-ui/components';

const styles = {
    textBoxField: {
        height: '30px !important',
        minWidth: '100px !important',
        fontWeight: 'normal',
        margin: 0,
        backgroundColor: '#f6f6f9 !important',
        '&.quantity ': {
            width: '150px !important',
        },
        '& .DGN-UI-InputBase': {
            height: '100%',
            backgroundColor: 'transparent',
        },
        '& input': {
            fontSize: '16px !important',
            color: '#7F828E',
            lineHeight: '20px',
        },
        '& div.dx-placeholder': {
            top: '-4px !important'
        }
    },
    dateBoxGrid: {
        padding: '0 !important',
        maxHeight: '36px !important',
        "&:after": {
            content: "none",
        },
        "& input": {
            width: '100%',
            height: "37px !important",
            minHeight: "37px !important",
            // margin: '-7px !important',
        },
        "& .dx-popover ": {
            display: 'none !important',
        },
        "& .dx-invalid-message ": {
            display: 'none !important'
        },
        '& .MuiFormControl-root ': {
            margin: 0
        },
    },
    dataGrid: {
        '& .dx-datagrid': {
            "& .dx-datagrid-invalid": {
                backgroundColor: 'rgba(139, 195, 74, 0.32) !important'
            }
        },
    },
    columnWidth: {
        minHeight: '37px !important',
        height: '37px !important'
    },
    currentFirstField: {
        paddingLeft: 14,
        fontSize: '16px',
        color: '#151A30',
        lineHeight: '20px',
    }
};
class W05F0005LotPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataGrid: [],
            rowData: "",
            quantity: "",

            indexTab: 0,
            loadFumi: false,
            dataMethod: [],
            oldDataMethod: [],
            dataDetail: [],
            oldDataDetail: [],
            oldcbFumi: [],
            cbFumi: [],
            disabled: false,
            allowEditFumi: false,
        }
        this.displayData = [];
        this.key = -1;
        this.selectedRowIndx = 0;

    }

    componentDidMount() {
        let { rowData, type } = this.props;
        const tempData = { ...rowData };
        const dataGrid = this.state.dataGrid;
        dataGrid.push(tempData);
        this.setState({ quantity: rowData.Quantity, rowData: tempData, dataGrid: dataGrid, });
        if (type !== "LOT") {
            this.getCbFumigationMethod();
            let dataMethod = [...dataGrid[0].fumigation.method];
            let dataDetail = [...dataGrid[0].fumigation.detail];
            dataMethod = dataMethod.map(rs => ({ ...rs }));
            dataDetail = dataDetail.map(rs => ({ ...rs }));
            dataDetail.map(item => {
                this.key++;
                item.key = this.key;
                return item;
            })
            const oldDataMethod = dataMethod.map(rs => rs = { ...rs });
            this.setState({ dataMethod: dataMethod, dataDetail: dataDetail, dataGrid: [], oldDataMethod: oldDataMethod }, () => {
                this.setState({ oldDataDetail: this.state.dataDetail.map(rs => ({ ...rs })) })
            })
        }
    }
    getCbFumigationMethod = () => {
        let params = {
            Language: Config.language || 84
        }
        this.props.W05F0005Actions.getCbFumigationMethod(params, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            this.setState({ cbFumi: data, oldcbFumi: data })
        });
    }
    setValueLot = (e, name) => {
        const { dataGrid } = this.state;
        dataGrid.forEach(item => {
            if (item.LotNoID === name) {
                item.LotNo = e.target.value;
                return;
            }
        });
    }

    countDecimals = (value) => {
        if (Math.floor(value) === value) return 0;
        return value.toString().split(".")[1].length || 0;
    }

    setValueQuantity = async (e, name) => {
        const value = e.value;
        const { dataGrid, quantity } = this.state;
        let { isEdit, ExchangeRate, decimalScale } = this.props;
        let array = [...dataGrid];
        let rowData = array[0];
        let temp = 0;
        const dataFilter = array.filter((rs, index) => index !== 0);
        dataFilter.forEach((item, index) => {
            if (name === item.LotNoID) {
                temp += value;
            } else {
                temp += item.Quantity;
            }
        });
        if (Number(quantity) > temp && value && value.toString().indexOf('-') === -1) {
            const qty = quantity - temp;
            rowData.Quantity = Number(this.countDecimals(qty) <= decimalScale ? qty : qty.toFixed(decimalScale));
            rowData.Amount = rowData.Quantity * rowData.UnitPrice;
            rowData.CAmount = rowData.Quantity * rowData.UnitPrice * rowData.ExchangeRate;
            array.map((item) => {
                if (item.LotNoID === name) {
                    // item.fumigation = {
                    //     detail: [],
                    //     method: [],
                    // };
                    item.Quantity = value;
                    item.Amount = value * item.UnitPrice;
                    item.CAmount = isEdit ? value * item.UnitPrice * ExchangeRate : value * item.UnitPrice * item.ExchangeRate;

                } else {
                    item.CAmount = isEdit ? value * item.UnitPrice * ExchangeRate : value * item.UnitPrice * item.ExchangeRate;
                }
                return item;
            });
            this.setState({ dataGrid: array, disabled: false });
        } else {
            const current = dataFilter.find(rs => name === rs.LotNoID);
            if (e.target.value === '') {
                const index = array.findIndex(rs => rs.LotNoID === name);
                rowData.Quantity = rowData.Quantity + current.Quantity;
                rowData.Amount = rowData.Quantity * rowData.UnitPrice;
                rowData.CAmount = rowData.Quantity * rowData.UnitPrice * rowData.ExchangeRate;
                array[index].Quantity = 0;
                this.setState({ dataGrid: array, disabled: false });
                return;
            } else {
                e.target.blur();
                Config.popup.show("INFO", Config.lang("ERP_So_luong_khong_hop_le"));
                e.target.value = current.Quantity;
            }
        }
    }

    numberFormat = (number, limitNumber = 0) => {
        const formatter = new Intl.NumberFormat("en-US", {
            minimumFractionDigits: limitNumber,
            maximumFractionDigits: limitNumber,
        });
        const _number = formatter.format(String(number));
        return !_number || _number === "NaN" ? 0 : _number;
    };

    onDeleteRowTachLot = (key) => {
        Config.popup.show("YES_NO", Config.lang(`Ban_co_chac_muon_xoa?`),
            () => {
                const { decimalScale } = this.props;
                const { dataGrid } = this.state;
                const dataFilter = [...dataGrid];
                const index = dataFilter.findIndex(rs => rs.LotNoID === key);
                if (index !== -1) {
                    const qty = dataFilter[0].Quantity + dataFilter[index].Quantity;
                    dataFilter[0].Quantity = Number(this.countDecimals(qty) <= decimalScale ? qty : qty.toFixed(decimalScale));
                    dataFilter.splice(index, 1);
                    this.displayData.splice(index - 1, 1);
                    this.setState({ dataGrid: dataFilter });
                }
            });
    }

    renderData = (key, quantity) => {
        const { classes, decimalScale } = this.props;
        return (
            <div key={key}>
                <div className={"center-row pd10"} style={{ justifyContent: 'space-between', backgroundColor: 'rgba(17, 29, 94, 0.04)', height: 40, }}>
                    <div className="center-row align-between " style={{ width: '90%' }}>
                        <TextInput placeholder={Config.lang("ERP_Nhap")} className={classes.textBoxField}
                            viewType={"outlined"} onChange={(e) => this.setValueLot(e, key)} value={key} />
                        <MoneyInput placeholder={Config.lang("ERP_Nhap")} className={`${classes.textBoxField} quantity`}
                            thousandSeparator={','} decimalDigit={decimalScale} allowZero
                            viewType={"outlined"} onChange={(e) => this.setValueQuantity(e, key)} value={quantity} />
                    </div>
                    <ButtonIcon name='delete' circular viewBox viewType="text" size="small" onClick={() => this.onDeleteRowTachLot(key)} />
                </div>
            </div>
        )
    }
    onTachLot = () => {
        const { dataGrid } = this.state;
        let array = [...dataGrid];
        let rowData = JSON.parse(JSON.stringify(dataGrid[dataGrid.length - 1]));
        let LotNo = rowData.LotNo.substring(rowData.LotNo.lastIndexOf(".") + 1, rowData.LotNo.length);
        LotNo = (/^\d+$/).test(LotNo) ? Number(LotNo) + 1 : LotNo + 1;
        LotNo < 10 ? LotNo = rowData.LotNo.substring(0, rowData.LotNo.lastIndexOf(".") + 1) + "0" + LotNo : LotNo = rowData.LotNo.substring(0, rowData.LotNo.lastIndexOf('.') + 1) + LotNo
        rowData.LotNoID = LotNo;
        rowData.Quantity = 0;
        rowData.LotNo = LotNo;
        rowData.Amount = 0;
        rowData.CAmount = 0;
        rowData.fumigation.detail.map(rs => {
            rs.LotNo = LotNo;
            return rs;
        });
        rowData.fumigation.method.map(rs => {
            rs.LotNo = LotNo;
            return rs;
        });
        array.push(rowData);
        this.setState({ dataGrid: array })
        this.displayData.push(this.renderData(LotNo, 0))
    }
    cellRenderQuantity = (e) => {
        return (
            <div className={"pull-right"}>
                <TextBox stylingMode={"outlined"} mode={"text"} width={80} height={25} defaultValue={""} value={e.data && e.data.Quantity} />
            </div>
        )
    }
    getCodeLang(ID) {
        const listValues = {
            FumigationType: "ERP_Phuong_thuc",
            Quantity: "ERP_So_luong",

            FumigationCompany: "ERP_Cong_ty_phun",
            FumigationDate: "ERP_Ngay_phun",
        };

        return listValues[ID];
    }
    onSave = async () => {
        let { type, decimalScale } = this.props;
        let dataHistoryFumu = [];
        if (type === "LOT") {
            const quantity = this.state.dataGrid.reduce((a, b) => a + (b["Quantity"] || 0), 0);
            const isValid = this.state.dataGrid.find(rs => !rs.Quantity);
            if (isValid) {
                Config.popup.show("INFO", Config.lang("ERP_So_luong_khong_hop_le"));
                return;
            }
            const uniqueLotNo = [...new Set(this.state.dataGrid.map(item => item.LotNo))];
            if (Number(quantity.toFixed(decimalScale)) > this.state.quantity) {
                Config.popup.show("INFO", Config.lang("ERP_Tong_so_luong_cac_lot_phai_bang_so_luong_cua_lot_tach"));
            } else if (uniqueLotNo.length < this.state.dataGrid.length) {
                Config.popup.show("INFO", Config.lang("ERP_Khong_duoc_nhap_trung_so_lot phải bằng Số lượng của LOT tách"));
            } else {
                this.props.reLoadDataSource(this.state.dataGrid, type);
                this.props.onCloseLOT("");
            }
        } else {
            await this.GridMethod && this.GridMethod.instance.saveEditData();
            await this.GridDetail && this.GridDetail.instance.saveEditData();
            const dataSourceMethod = this.GridMethod && this.GridMethod.instance.option("dataSource");
            const dataFilter = await dataSourceMethod.filter(rs => rs.FumigationType === "");
            if (dataFilter.length > 0) {
                Config.popup.show("INFO", Config.lang("ERP_Phuong_thuc_khong_duoc_de_trong"));
                return null;
            }
            await this.createDetail();
            let dataMethod = this.state.dataMethod;
            let dataDetail = this.state.dataDetail;
            this.state.oldcbFumi.forEach(rs => {
                dataMethod.map(item => {
                    if (item.FumigationType === rs.FumigationType) {
                        item.FumigationTypeName = rs.FumigationTypeName;
                    }
                    return item;
                });
                dataDetail.map(item => {
                    if (item.FumigationType === rs.FumigationType) {
                        item.FumigationTypeName = rs.FumigationTypeName;
                    }
                    return item;
                })
            })
            let data = {
                QuotationItemID: this.state.rowData.QuotationItemID,
                LotNo: this.state.rowData.LotNo,
                method: dataMethod,
                detail: dataDetail
            };
            this.props.reLoadDataSource(data, type, dataHistoryFumu);
            this.props.onCloseLOT("");
        }
    }
    onClose = async () => {
        this.props.onCloseLOT("");
    }
    handleChanged = async (event, value) => {
        (await this.GridMethod) && this.GridMethod.instance.saveEditData();
        (await this.GridDetail) && this.GridDetail.instance.saveEditData();
        if (value === 0) {
            // const results = dataSourceDetail.filter(({ FumigationDate: FumigationDate1 }) => !this.state.dataDetail.some(({ FumigationDate: FumigationDate2 }) => FumigationDate1 === FumigationDate2));
            // console.log(results)
            this.setState({ indexTab: 0 });
        }

        if (value === 1) {
            const dataSourceMethod =
                this.GridMethod && this.GridMethod.instance.option("dataSource");
            const dataFilter = dataSourceMethod.filter(rs => rs.FumigationType === "");
            if (dataFilter.length > 0) {
                Config.popup.show("INFO", Config.lang("ERP_Phuong_thuc_khong_duoc_de_trong"));
                return null;
            }
            this.state.oldcbFumi.forEach(rs => {
                dataSourceMethod.map(item => {
                    if (item.FumigationType === rs.FumigationType) {
                        item.FumigationTypeName = rs.FumigationTypeName;
                    }
                    return item;
                });
            });
            if (dataSourceMethod) {
                this.createDetail();
                this.setState({ indexTab: 1 });
            }
        }
    };
    createDetail = () => {
        const dataSourceMethod = this.GridMethod
            ? this.GridMethod.instance.option("dataSource")
            : this.dataMethod;
        const dataSourceDetail = this.GridDetail
            ? this.GridDetail.instance.option("dataSource")
            : this.dataDetail;
        let newDataSourceDetail = [];
        if (dataSourceMethod) {
            dataSourceMethod.map((item, index) => {
                const dataDetail_FumiType = dataSourceDetail.filter(
                    (detail) => detail.FumigationType === item.FumigationType
                );
                let count = 1;

                if (item.Quantity !== dataDetail_FumiType.length) {
                    for (let i = 0; i < item.Quantity; i++) {
                        if (dataDetail_FumiType[i]) {
                            dataDetail_FumiType[i].Quantity = count;
                            count++;
                            newDataSourceDetail.push(dataDetail_FumiType[i]);
                        } else {
                            newDataSourceDetail.push({
                                ContractID: item.ContractID ? item.ContractID : "",
                                FumigationCompany: item.FumigationCompany
                                    ? item.FumigationCompany
                                    : "",
                                FumigationCompanyName: item.FumigationCompanyName
                                    ? item.FumigationCompanyName
                                    : "",
                                FumigationDate: item.FumigationDate
                                    ? item.FumigationDate
                                    : null,
                                FumigationType: item.FumigationType
                                    ? item.FumigationType
                                    : "",
                                FumigationTypeName: item.FumigationTypeName
                                    ? item.FumigationTypeName
                                    : "",
                                LotNo: item.LotNo ? item.LotNo : "",
                                OrderNo: count,
                                QuotationID: item.QuotationID ? item.QuotationID : "",
                                QuotationItemID: item.QuotationItemID
                                    ? item.QuotationItemID
                                    : "",
                                IsNew: true,
                                Notes: "",
                            });
                            count++;
                        }
                    }
                } else {
                    newDataSourceDetail = newDataSourceDetail.concat(
                        dataDetail_FumiType
                    );
                }
                return newDataSourceDetail;
            });
            newDataSourceDetail.map(rs => {
                if (rs.IsNew) {
                    this.key++;
                    rs.key = this.key;
                }
                return rs;
            })
            this.setState({ dataDetail: newDataSourceDetail, });
        }
    }
    onDeleteFumi = async (e) => {
        Config.popup.show(
            "YES_NO",
            Config.lang("ERP_Ban_co_chac_muon_xoa?"),
            () => {
                e.component.deleteRow(e.rowIndex);
                this.createDetail();
                this.GridDetail && this.GridDetail.instance.saveEditData();
            },
            () => {
                //no
            }
        );

    }
    renderActions = (e) => {
        return (
            <GridActionBar>
                <ButtonIcon circular viewBox viewType="text" name="delete" color="primary" onClick={() => this.onDeleteFumi(e)} size="small" />
            </GridActionBar>
        )
    }

    renderMethod = () => {
        const { isView, classes } = this.props;
        const { loadFumi, rowData, dataMethod, cbFumi, oldcbFumi } = this.state;
        const dataKeyMoi = dataMethod.map(d => d.FumigationType);
        let cbDataFumi = cbFumi.filter(d => {
            return dataKeyMoi.indexOf(d.FumigationType) < 0;
        });
        cbDataFumi = cbDataFumi.filter((v, i, a) => a.findIndex(t => (t.FumigationType === v.FumigationType)) === i);
        return (
            <>
                <GridContainer
                    dataSource={dataMethod}
                    reference={(ref) => (this.GridMethod = ref)}
                    loading={loadFumi}
                    width={'100%'}
                    elementAttr={{
                        class: classes.dataGrid
                    }}
                    showBorders={false}
                    typePaging={"normal"}
                    columnAutoWidth={true}
                    pagerFullScreen={false}
                    showColumnLines={false}
                    showRowLines={false}
                    hoverStateEnabled={true}
                    rowAlternationEnabled={true}
                    editing={{
                        mode: "cell",
                        refreshMode: "reshape",
                        allowUpdating: true,
                        texts: {
                            confirmDeleteMessage: "",
                        },
                        // startEditAction: "dblClick",
                    }}
                    onInitNewRow={(eMethod) => {
                        eMethod.data.ContractID = rowData && rowData.ContractID ? rowData.ContractID : ""
                        eMethod.data.LotNo = rowData && rowData.LotNo ? rowData.LotNo : ""
                        eMethod.data.FumigationType = "";
                        eMethod.data.FumigationTypeName = "";
                        eMethod.data.Quantity = 1;
                        eMethod.data.IsNew = true;
                        eMethod.data.QuotationID = rowData && rowData.QuotationID ? rowData.QuotationID : ""
                        eMethod.data.QuotationItemID = rowData && rowData.QuotationItemID ? rowData.QuotationItemID : ""
                    }}
                    onRowUpdated={e => {
                        const dataSourceMethod = this.GridMethod && this.GridMethod.instance.option("dataSource");
                        const oldData = this.state.oldDataMethod.map(rs => ({ ...rs }));
                        oldData.map((rs) => {
                            const dataNew = dataSourceMethod.find(
                                (d) => d.FumigationType === rs.FumigationType
                            );
                            if (dataNew && dataNew.Quantity < rs.Quantity) {
                                Config.popup.show(
                                    "YES_NO",
                                    Config.lang("ERP_Ban_co_muon_tiep_tuc_sua"),
                                    () => {
                                        this.createDetail();
                                    },
                                    () => {
                                        this.setState({ dataMethod: oldData, dataDetail: this.state.oldDataDetail });
                                    }
                                );
                            }
                            return dataNew;
                        });
                    }}
                    onRowInserted={(e) => {
                        const FumigationTypeName = cbFumi.find(rs => e.data.FumigationType === rs.FumigationType);
                        e.data.FumigationTypeName = FumigationTypeName && FumigationTypeName.FumigationTypeName;

                        const dataFilter = cbFumi.filter(rs => rs.FumigationType !== e.data.FumigationType)
                        this.setState({ cbFumi: dataFilter });
                        if (this.state.allowEditFumi === true) this.setState({ allowEditFumi: false })
                    }}
                    onRowRemoving={(e) => {
                        const dataFilter = oldcbFumi.filter(rs => rs.FumigationType === e.data.FumigationType);
                        this.setState({ cbFumi: this.state.cbFumi.concat(dataFilter) });
                    }}
                    onEditingStart={e => {
                        if (e.data.FumigationType !== "" && e.column.dataField === "FumigationType") {
                            e.cancel = true;
                        }
                    }}
                >
                    <Column
                        caption={Config.lang("ERP_Ma_lot")}
                        dataField={"LotNo"}
                        alignment={"left"}
                        allowEditing={false}
                    />
                    <Column
                        dataField={"FumigationTypeName"} visible={false}
                    />
                    <Column
                        caption={Config.lang("ERP_Phuong_thuc")}
                        dataField={"FumigationType"}
                        alignment={"left"}
                        // allowEditing={this.state.allowEditFumi}
                        cssClass={classes.dateBoxGrid}
                        headerCellRender={e => {
                            return (
                                <label>{Config.lang("ERP_Phuong_thuc")} <span style={{ color: 'red', fontSize: 15 }}>*</span></label>
                            )
                        }}
                        cellRender={e => {
                            return (
                                <Label>{e.data.FumigationTypeName}</Label>
                            )
                        }}
                        // lookup={{
                        //     allowClearing: true,
                        //     dataSource: cbDataFumi,
                        //     valueExpr: "FumigationType",
                        //     displayExpr: "FumigationTypeName",
                        // }}
                        width={250}
                        editCellRender={(e) => {
                            const selectedItem = cbDataFumi.filter((c) => {
                                return c.FumigationType === e.value;
                            });
                            return (
                                <Combo
                                    dataSource={Config.storeDataSoureDevExtreme(cbDataFumi)}
                                    displayExpr={"FumigationTypeName"}
                                    // valueExpr={'FumigationType'}
                                    stylingMode={"underlined"}
                                    cssClass={classes.dateBoxGrid}
                                    style={{ backgroundColor: "transparent", marginBottom: -5 }}
                                    placeholder={Config.lang("ERP_Chon")}
                                    value={selectedItem.length > 0 ? selectedItem[0] : null}
                                    onValueChanged={(rs) => {
                                        e.setValue(rs.value && rs.value.FumigationType ? rs.value.FumigationType : "");
                                        e.component.cellValue(e.rowIndex, "FumigationTypeName", rs.value && rs.value.FumigationTypeName ? rs.value.FumigationTypeName : "");
                                    }}
                                />
                            );
                        }}
                    >
                        {/* <RequiredRule /> */}
                    </Column>

                    <Column
                        caption={Config.lang("So_lan_khu_trung")}
                        dataField={"Quantity"}
                        alignment={"right"}
                        editorOptions={{ min: 1, max: 10 }}
                        allowEditing={!isView}
                        minWidth={150}
                        cssClass={classes.dateBoxGrid}
                    />
                    <Column
                        fixed={true}
                        fixedPosition={"right"}
                        cellRender={this.renderActions}
                        visible={!isView || Config.isMobile}
                        alignment={"center"}
                        allowEditing={false}
                        cssClass={classes.columnWidth}
                    />
                </GridContainer>
                {
                    !isView && cbDataFumi.length > 0 &&
                    <ButtonIcon circular viewBox viewType="text" name="AddCircle" color="primary" onClick={this.onAddDataFumi} />
                }
            </>
        );
    };
    onAddDataFumi = async () => {
        await this.GridMethod.instance.saveEditData();
        const dataSourceMethod = this.GridMethod && this.GridMethod.instance.option("dataSource");
        const dataFilter = dataSourceMethod.filter(rs => rs.FumigationType === "");
        if (dataFilter.length > 0) {
            Config.popup.show("INFO", Config.lang("ERP_Phuong_thuc_khong_duoc_de_trong"));
            return null;
        } else {
            if (this.GridMethod) {
                this.setState({ allowEditFumi: true }, () => {
                    setTimeout(() => {
                        this.GridMethod.instance.addRow();
                    }, 300);
                });
            }
        }
    };
    showPopup = (data, dataField) => {
        const value = data ? data.replace(/,/g, ';').split(';') : [];
        this.popupCompany.tmpSelectedRowKeys = value;
        setTimeout(() => {
            this.popupCompany && this.popupCompany.open();
        }, 100);
    };
    cellRenderPopupAction = (cellData) => {
        const dataField = cellData.column.dataField,
            rowData = cellData.data;
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className={"pull-left mgr5"}>
                    <ButtonIcon circular viewBox viewType="text" size="small" name="menu" color="primary" onClick={() => this.showPopup(rowData[dataField], dataField)} />
                </div>
                <div className={"pull-left"} title={rowData.FumigationCompanyName}>{rowData.FumigationCompanyName}</div>
            </div>
        );
    };
    onSelectedPopup = (data, field) => {
        const { filterFumiCompany } = this.props;
        filterFumiCompany.limit = 20;
        filterFumiCompany.skip = 0;
        this.GridDetail.instance.cellValue(this.selectedRowIndx, field, data[0].ObjectID);
        this.GridDetail.instance.cellValue(this.selectedRowIndx, "FumigationCompanyName", data[0].ObjectName);
        this.GridDetail.instance.saveEditData();
    };
    onRowClick = (e) => {
        this.selectedRowIndx = e.rowIndex;
    }
    renderDetail = () => {
        const { loadFumi, dataDetail } = this.state;
        const { isView, classes } = this.props;
        return (
            <div style={{ paddingBottom: 15 }}>
                <GridContainer
                    dataSource={dataDetail}
                    loading={loadFumi}
                    showBorders={false}
                    typePaging={"normal"}
                    pagerFullScreen={false}
                    showColumnLines={false}
                    showRowLines={false}
                    hoverStateEnabled={true}
                    rowAlternationEnabled={true}
                    onRowClick={this.onRowClick}
                    reference={(ref) => (this.GridDetail = ref)}
                    editing={{
                        mode: "cell",
                        refreshMode: "reshape",
                        allowUpdating: true,
                        // startEditAction: "dblClick"

                    }}
                    onInitNewRow={(eDetail) => {
                        eDetail.data.IsNew = true;
                    }}
                >
                    <Column
                        caption={Config.lang("ERP_Phuong_thuc")}
                        dataField={"FumigationTypeName"}
                        alignment={"left"}
                        allowEditing={false}
                    />
                    <Column
                        caption={Config.lang("ERP_Lan")}
                        dataField={"OrderNo"}
                        alignment={"center"}
                        allowEditing={false}
                        width={145}
                    />
                    <Column
                        dataField={"FumigationCompanyName"} visible={false}
                    />
                    <Column
                        caption={Config.lang("ERP_Cong_ty_phun")}
                        dataField={"FumigationCompany"}
                        alignment={"left"}
                        minWidth={150} allowEditing={false}
                        cellRender={(cellData) => this.cellRenderPopupAction(cellData)}
                    />
                    <Column
                        caption={Config.lang("ERP_Ngay_phun")}
                        dataField={"FumigationDate"}
                        width={150}
                        alignment={"center"}
                        dataType={"date"}
                        editorOptions={{
                            placeholder: "DD/MM/YYYY",
                            allowUpdating: !isView,
                        }}
                        format={"dd/MM/yyyy"}
                        allowEditing={!isView}
                        cssClass={classes.dateBoxGrid}
                    />
                    <Column
                        caption={Config.lang("Ghi_chu")}
                        dataField={"Notes"}
                        width={250}
                    />
                </GridContainer>
            </div>
        );
    };
    loadMoreG = () => {
        const { loadMoreG, filterGeneral30 } = this.props;
        loadMoreG(filterGeneral30)
    }
    render() {
        let { opendLot, type, isView, filterFumiCompany, classes, decimalScale } = this.props;
        let { dataGrid, indexTab, rowData, disabled, } = this.state;
        const columnCompany = [
            {
                caption: Config.lang("ERP_Ma"),
                dataField: "ObjectID",
                width: 200
            },
            {
                caption: Config.lang("ERP_Ten"),
                dataField: "ObjectName"
            }
        ];
        return (
            <div>
                <CbLoadmore
                    ref={ref => this.popupCompany = ref}
                    // maxWidth={"sm"}
                    mode={"single"}
                    keyExpr={"ObjectID"}
                    title={Config.lang("ERP_Cong_ty_phun")}
                    onClose={() => {
                        this.props.filterFumiCompany.limit = 20;
                        this.props.filterFumiCompany.skip = 0;
                    }}
                    filter={filterFumiCompany} //qwrqwr
                    column={columnCompany}
                    onSave={(data) => (this.onSelectedPopup(data, "FumigationCompany"))}
                // listPerPage={[15, 30, 45, 60]}
                />
                <Modal open={opendLot}
                    fullWidth={true}
                    maxWidth={"md"}
                >
                    <Modal.Title disableTypography>
                        <div className={"display_row align-center align-between flex-wrap"} style={{ width: '100%' }}>
                            <div className={"display_row align-center"}>
                                <Typography variant={"h6"} className={"mgr10"}>{Config.lang(`${type === 'LOT' ? 'ERP_Tach_lot' : 'ERP_Phun_trung'}`)}</Typography>
                            </div>
                            <div>
                                {!isView &&
                                    <ButtonIcon circular viewBox viewType="text" name="save" color="primary" onClick={this.onSave}
                                        className={"mgr10"} disabled={disabled} size="small" />
                                }
                                <ButtonIcon circular viewBox viewType="text" name="close" color="primary" onClick={this.onClose} size="small" />
                            </div>
                        </div>
                    </Modal.Title>

                    <Divider />
                    <Modal.Content style={{ margin: '-12px 0' }}>
                        {type === 'LOT' ?
                            <div className={"pd10 "}>
                                <div style={{ height: 20 }}>
                                    <div className={"pull-right"}>
                                        <Label className={"font12 text-uppercase"}>{Config.lang("ERP_So_luong_lot_tach")}:  </Label>
                                        <label style={{ paddingLeft: 10, fontSize: 16 }}>
                                            {this.numberFormat(this.state.quantity, this.state.quantity ? this.countDecimals(this.state.quantity) : decimalScale)}
                                        </label>
                                    </div>
                                </div>

                                <div style={{ paddingTop: 10 }}>
                                    <div className={"center-row pd10 full_w"} style={{ justifyContent: 'space-between', backgroundColor: '#E4E9F2', height: 40, borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                                        <div className="center-row align-between " style={{ width: '90%' }}>
                                            <span className={"text-uppercase font12 bold"} >{Config.lang("ERP_Ma_lot")}</span>
                                            <span className={"text-uppercase font12 bold"}>{Config.lang("ERP_So_luong")}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className={"pdt5"}>
                                    <div className={"center-row pd10"} style={{ justifyContent: 'space-between', backgroundColor: 'rgba(17, 29, 94, 0.04)', height: 40, }}>
                                        <div className="center-row align-between " style={{ width: '90%' }}>
                                            <span className={classes.currentFirstField}  >{dataGrid.length > 0 && dataGrid[0].LotNo}</span>
                                            <MoneyInput placeholder={Config.lang("ERP_Nhap")} className={`${classes.textBoxField} quantity`}
                                                thousandSeparator={','} decimalDigit={5} allowZero
                                                viewType={"outlined"} disabled={true} value={dataGrid.length > 0 ? dataGrid[0].Quantity : 0} />
                                        </div>
                                    </div>
                                </div>

                                {this.displayData.map((item, index) => (item))}
                                <ButtonIcon circular viewBox viewType="text" name="AddCircle" color="primary" onClick={this.onTachLot} />
                            </div> :
                            <div>
                                <Row>
                                    <Col xs={8} sm={8} md={8} lg={8}>
                                        <Tabs
                                            indicatorColor={"primary"}
                                            textColor={"primary"}
                                            value={indexTab}
                                            onChange={this.handleChanged}
                                        >
                                            <Tab value={0} label={Config.lang("ERP_Phuong_thuc")} />

                                            <Tab value={1} label={Config.lang("ERP_Chi_tiet")} />
                                        </Tabs>
                                    </Col>
                                    <Col xs={4} sm={4} md={4} lg={4}>
                                        {indexTab === 1 && (
                                            <div style={{ marginTop: 14 }}>
                                                <Label className={"pdr5"}>
                                                    {`${Config.lang("ERP_Ma_LOT")}: `}
                                                </Label>
                                                <label>{rowData && rowData.LotNo}</label>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                                <TabContent
                                    activeKey={indexTab}
                                    disableSwipe={true}
                                    lazyLoading={false}
                                >
                                    <TabPanel index={0}>{this.renderMethod}</TabPanel>
                                    <TabPanel index={1}>{this.renderDetail}</TabPanel>
                                </TabContent>
                            </div>}
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

W05F0005LotPopup.propsTypes = {
    isView: PropTypes.bool,
    isEdit: PropTypes.bool,
    opendLot: PropTypes.bool,
    exchangeRate: PropTypes.number,
    type: PropTypes.string,
    filterGeneral30: PropTypes.object,
    filterFumiCompany: PropTypes.object,
    loadMoreG: PropTypes.func,
    rowData: PropTypes.object,
    onCloseLOT: PropTypes.func,
    reLoadDataSource: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        W05F0005Actions: bindActionCreators(W05F0005Actions, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(W05F0005LotPopup));