import { FormLabel as Label, Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Column } from "devextreme-react/data-grid";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as w05f0005Actions from "../../../../redux/W0X/W05F0005/W05F0005_actions";
import ButtonGeneral from "../../../common/button/button-general";
import Icons from "../../../common/icons/";
import Modal from "../../../common/modal/modal";
import GridContainer from "../../../grid-container/grid-container";

const styles = {
    contentCode: {
        width: '100%',
        marginBottom: 15,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    divHeaderTitle: {
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontSize: '0.85rem',
        textTransform: 'uppercase',
        color: '#8F9BB3',
    },
    divHeaderItem: {
        marginLeft: 8,
        fontSize: 16,
        fontWeight: 600,
        fontStyle: 'normal',
    },
    cellPadding: {
        '& .dx-datagrid-content': {
            '& td': {
                paddingTop: '8px !important',
                paddingBottom: '8px !important',
            }
        }
    },
    checkBoxGrid: {
        padding: 0,
    }
};

class W05F0005DeliveryQuantity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalDeliveryQuantity: 0,
            btnSaveStatus: true,
            allowEditing: false,
            btnConfirmStatus: false,
            btnCompleteStatus: false,
            dataDeliveryQuantity: []
        };
        this.confirmedRow = [];
        this.deliveryQuantityGrid = null;
        this.dataDeliveryQuantityOld = [];
        this.allDeliveryItemIDChecked = [];
    }

    componentDidMount = async () => {
        await this.props.loadDeliveryQuantity(); //Load lại data
        const { rowData } = this.props;
        if (!_.isEmpty(rowData)) {
            this.dataDeliveryQuantityOld = rowData.map(item => ({ ...item }));
            this.setState({ dataDeliveryQuantity: rowData, totalDeliveryQuantity: rowData.length })
        } else {
            this.setState({ btnConfirmStatus: true })
        }
    }

    saveDeliveryQuantityHistory = async () => {
        const newData = this.state.dataDeliveryQuantity
        const oldData = this.dataDeliveryQuantityOld;
        const captions = {};
        const options = {
            codeID: this.props.QuotationID,
            keyExpr: "DeliveryItemID",
            formID: "W05F0004",
            mode: "edit",
            itemRender: (e, type) => {
                const { mode, data } = e;
                if (mode === "editGrid") {
                    if (type === "description84") return `${Config.lang("Trang_thai_chi_tiet_giao_hang", "vi")} ${data.DeliveryNo} ${Config.lang("Cua_lot", "vi")} ${data.Ana08ID}`;
                    if (type === "description01") return `${Config.lang("Trang_thai_chi_tiet_giao_hang", "en")} ${data.DeliveryNo} ${Config.lang("Cua_lot", "en")} ${data.Ana08ID}`;
                }
            }
        };
        let data = await Config.createSaveHistory(newData, oldData, captions, options, true);
        if (data.length > 0) {
            data.map(rs => {
                rs.oldValue = Config.lang("Chua_xac_nhan", "en")
                rs.newValue = Config.lang("Da_xac_nhan", "en")
                return rs;
            });
            const params = { attributes: JSON.stringify(data) }
            await Config.saveHistory(params);
        }
    }

    saveAllDeliveryQuantityHistory = () => {
        const { QuotationID, ContractNo, VoucherNum } = this.props;
        const data = {
            codeID: QuotationID,
            formID: "W05F0004",
            linkedTrans: "",
            oldValue: Config.lang("Chua_xac_nhan", "en"),
            newValue: Config.lang("Da_xac_nhan", "en"),
            description84: `${Config.lang("Trang_thai_chi_tiet_giao_hang", "vi")} ${VoucherNum} của hợp đồng ${ContractNo}`,
            description01: `${Config.lang("Trang_thai_chi_tiet_giao_hang", "en")} ${VoucherNum} on contract ${ContractNo}`,
            action: 1,
        };
        const param = {
            attributes: JSON.stringify([data]),
        };
        this.props.W05F0005Actions.saveHistory(param, (error, data) => {
            this.setState({ formLoading: false, isSave: false });
            if (error) {
                let message = error.message || Config.lang("ERP_Luu_lich_su_khong_thanh_cong");
                Config.popup.show("INFO", message);
                return false;
            }
        });
    }

    onSaveConfirmDeliveryQuantity = (confirmAll) => {
        let btnConfirmStatus = false;
        let dataDQ = this.state.dataDeliveryQuantity;
        if (_.isObject(confirmAll)) { // Xác nhận từng dòng
            this.confirmedRow.push(confirmAll.DeliveryItemID);
            dataDQ = [{ ...confirmAll }];
            if (this.confirmedRow.length === this.state.dataDeliveryQuantity.length) {
                btnConfirmStatus = true;

            }
        } else {// Xác nhận all
            this.state.dataDeliveryQuantity.forEach(item => {
                this.confirmedRow.push(item.DeliveryItemID);
            })
        }
        const params = {
            values: JSON.stringify(dataDQ)
        }
        btnConfirmStatus ? this.setState({ deliveryQuantiesLoaded: false, btnConfirmStatus }) : this.setState({ deliveryQuantiesLoaded: false });
        this.props.W05F0005Actions.saveConfirmDeliveryQuantity(params, (error, data) => {
            this.setState({ deliveryQuantiesLoaded: false });
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                let dataUdate = [];
                if (_.isBoolean(confirmAll)) { // Xác nhận tất cả 
                    this.setState({ btnConfirmStatus: true }) // Ẩn btn confirm
                    this.saveAllDeliveryQuantityHistory();
                } else { // Xác nhận từng row
                    this.saveDeliveryQuantityHistory();
                    dataUdate = this.state.dataDeliveryQuantity.filter(item => item.IsConfirmed !== 1); // Những data chưa checked
                }
                this.props.updateDeleveryQuantityNoti(dataUdate);
                Config.popup.show("INFO", Config.lang("ERP_Luu_thanh_cong"));
            }
        });
    };

    render() {
        const { deliveryQuantiesLoaded, dataDeliveryQuantity, totalDeliveryQuantity, btnConfirmStatus } = this.state;
        const { onOpenDeliveryQuantityModal, onCloseDeliveryQuantityModal, classes, VoucherNum, ContractNo, updateRow } = this.props;
        return (
            <Modal
                open={onOpenDeliveryQuantityModal}
                maxWidth={"lg"}
                fullWidth={true}
                position={"center"}
            >
                <Modal.Title disableTypography>
                    <div className={"display_row align-center align-between flex-wrap"} style={{ width: "100%" }}>
                        <div className={"display_row align-center"}>
                            <Typography variant={"h6"} className={"mgr10 text-uppercase"}>{Config.lang("ERP_Xac_nhan_so_luong_da_giao")}</Typography>
                            <Tooltip title={Config.lang("ERP_Xac_nhan_tat_ca")}>
                                <span>
                                    <ButtonGeneral
                                        name={Config.lang("ERP_Xac_nhan_tat_ca")}
                                        variant={"outlined"}
                                        color={"primary"}
                                        disabled={btnConfirmStatus}
                                        style={{ textTransform: "uppercase" }}
                                        onClick={() => {
                                            Config.popup.show("YES_NO", Config.lang("Ban_co_chac_chan_muon_xac_nhan_tat_ca_cac_chi_tiet_giao_hang_khong"), () => {
                                                if (updateRow) updateRow();
                                                this.onSaveConfirmDeliveryQuantity(true);
                                            });
                                        }}
                                    />
                                </span>
                            </Tooltip>
                        </div>
                        <div>
                            <IconButton
                                aria-label={"close"}
                                size={"small"}
                                onClick={() => {
                                    this.setState({ Status: 0, StatusDetailName: "" });
                                    onCloseDeliveryQuantityModal();
                                }}
                            >
                                <Icons name={"cancel_filled"} />
                            </IconButton>
                        </div>
                    </div>
                </Modal.Title>
                <Modal.Content>
                    <div className={classes.contentCode}>
                        <span>
                            <Label className={classes.divHeaderTitle}
                                {...(!Config.isMobile ? { style: { paddingRight: 8 } } : {})}>
                                {`${Config.lang("ERP_So_hop_dong")}:`}
                            </Label>
                            <Label className={classes.divHeaderItem}
                                {...(!Config.isMobile ? { style: { paddingRight: 8 } } : {})}>
                                {Config.lang(ContractNo)}
                            </Label>
                        </span>
                        <span style={{ float: 'right' }}>
                            <Label className={classes.divHeaderTitle}
                                {...(!Config.isMobile ? { style: { paddingRight: 8 } } : {})}>
                                {`${Config.lang("ERP_Ma_dot_trien_khai")}:`}
                            </Label>
                            <Label className={classes.divHeaderItem}
                                {...(!Config.isMobile ? { style: { paddingRight: 8 } } : {})}>
                                {Config.lang(VoucherNum)}
                            </Label>
                        </span>
                    </div>
                    <div className={classes.cellPadding}>
                        <GridContainer
                            reference={(ref) => this.deliveryQuantityGrid = ref}
                            typeShort={window.innerWidth < 768}
                            totalItems={totalDeliveryQuantity}
                            dataSource={dataDeliveryQuantity}
                            loading={deliveryQuantiesLoaded}
                            disabled={btnConfirmStatus}
                            keyExpr={"DeliveryItemID"}
                            rowAlternationEnabled={true}
                            allowColumnResizing={true}
                            hoverStateEnabled={true}
                            pagerFullScreen={false}
                            columnAutoWidth={true}
                            showBorders={false}
                            elementAttr={{
                                style: 'max-height: 480px',
                                class: 'noBGSelect'
                            }}
                            editing={{
                                mode: "cell",
                                refreshMode: "reshape",
                                allowEditing: true,
                                texts: { confirmDeleteMessage: "" }
                            }}
                            onRowUpdated={(e) => {
                                if (this.state.btnSaveStatus) this.setState({ btnSaveStatus: false })
                            }}
                            loadPanel={{ enabled: deliveryQuantiesLoaded }}
                        >
                            <Column
                                caption={Config.lang("ERP_Ma_lot")}
                                dataField={"Ana08ID"}
                                allowEditing={false}
                                width={240}
                            />
                            <Column
                                caption={Config.lang("ERP_Ma_mapping")}
                                dataField={"MappingID"}
                                allowEditing={false}
                                width={200}
                            />
                            <Column
                                caption={Config.lang("ERP_So_mapping")}
                                dataField={"MappingNo"}
                                allowEditing={false}
                                width={200}
                            />
                            <Column
                                caption={Config.lang("ERP_So_chi_tiet_giao_hang")}
                                dataField={"DeliveryNo"}
                                allowEditing={false}
                                width={240}
                            />
                            <Column
                                caption={Config.lang("ERP_So_luong_da_giao")}
                                dataField={"QuantityPack"}
                                allowEditing={false}
                                width={160}
                            />
                            <Column
                                alignment={"center"}
                                cellRender={(e) => {
                                    const { data } = e;
                                    let index = -1;
                                    if (!_.isEmpty(this.confirmedRow)) index = this.confirmedRow.indexOf(data.DeliveryItemID);
                                    const disableColor = index > -1 ? 'gray' : 'green';
                                    return <IconButton
                                        disabled={index > -1}
                                        style={{ color: disableColor }}
                                        aria-label={"confirm"}
                                        size={"small"}
                                        onClick={() => {
                                            Config.popup.show("YES_NO", Config.lang("ERP_Ban_co_chac_chan_muon_xac_nhan_chi_tiet_giao_hang_nay_khong"), () => {
                                                data["IsConfirmed"] = 1;
                                                if (updateRow) updateRow();
                                                this.setState({ ...dataDeliveryQuantity, data });
                                                this.onSaveConfirmDeliveryQuantity(data);
                                            });
                                        }}
                                    >
                                        <CheckCircleIcon />
                                    </IconButton>
                                }}
                            />
                        </GridContainer>
                    </div>
                </Modal.Content>
            </Modal>
        );
    }
}

W05F0005DeliveryQuantity.propsTypes = {
    onOpenDeliveryQuantityModal: PropTypes.bool,
    rowData: PropTypes.object,
    loadDeliveryQuantity: PropTypes.func,
    updateDeleveryQuantityNoti: PropTypes.func,
    onCloseDeliveryQuantityModal: PropTypes.func,
};

export default compose(connect(state => ({}),
    (dispatch) => ({
        W05F0005Actions: bindActionCreators(w05f0005Actions, dispatch),
    })
),
    withStyles(styles)
)(W05F0005DeliveryQuantity);
