import { Checkbox, FormLabel as Label, Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import { Column } from "devextreme-react/data-grid";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as w05f0005Actions from "../../../../redux/W0X/W05F0005/W05F0005_actions";
import ButtonGeneral from "../../../common/button/button-general";
import Icons from "../../../common/icons/";
import Modal from "../../../common/modal/modal";
import GridContainer from "../../../grid-container/grid-container";

const styles = {
    lotCode: {
        textAlign: 'right',
        width: '100%',
        marginBottom: 15,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    divHeaderTitle: {
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontSize: '0.85rem',
        textTransform: 'uppercase',
        color: '#8F9BB3',
    },
    divHeaderItem: {
        marginLeft: 8,
        fontSize: 16,
        fontWeight: 600,
        fontStyle: 'normal',
    },
    cellPadding: {
        '& .dx-datagrid-content': {
            '& td': {
                paddingTop: '8px !important',
                paddingBottom: '8px !important',
            }
        }
    },
    checkBoxGrid: {
        padding: 0,
    }
};

class W05F0005ReceiptModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalReceipt: 0,
            btnSaveStatus: true,
            allowEditing: false,
            btnCompleteStatus: false,
            dataReceipt: []
        };
        this.filterReceipt = {
            QuotationItemID: "",
            Language: Config.language || "84"
        };
        this.receiptGrid = null;
        this.dataReceiptOld = [];
    }

    componentDidMount = () => {
        const { rowData, dataAddReceipt } = this.props;
        const dataReceipt = rowData.receipts;
        if (!_.isEmpty(dataReceipt)) {
            const allowEditing = (dataReceipt[0]).IsCompleteVoucher === 1; // View only 
            this.setState({ dataReceipt, totalReceipt: dataReceipt.length, allowEditing })
        } else {
            if (_.isEmpty(dataAddReceipt)) {
                this.loadReceipts();
            } else { // Has Default Data
                this.hasDataAndSetState(dataAddReceipt);
            }
        }
    }

    hasDataAndSetState = (data) => {
        const defaultData = data.map(rs => ({ ...rs }));
        const allowEditing = (data[0]).IsCompleteVoucher === 1; // View only 
        this.setState({
            dataReceipt: defaultData,
            totalReceipt: data.length,
            allowEditing
        })
    }

    loadReceipts = () => {
        if (this.state.receiptsLoaded === false) this.setState({ receiptsLoaded: true })
        const { QuotationItemID, Language } = this.filterReceipt;
        const params = {
            Language,
            QuotationItemID
        };
        this.props.w05f0005Actions.loadReceipt(params, async (error, data) => {
            this.setState({ receiptsLoaded: false })
            if (error) {
                this.setState({ btnCompleteStatus: true }) //Disable Btn Complete
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data.length > 0) {
                this.hasDataAndSetState(data);
            }
        });
    };

    onSaveReceipt = async () => {
        const { reLoadDataSource, rowData } = this.props;
        const receiptData = this.receiptGrid.instance.option("dataSource");
        await this.receiptGrid.instance.saveEditData();
        const data = {
            LotNo: rowData.LotNo,
            QuotationItemID: rowData.QuotationItemID,
            receipts: receiptData
        };
        await reLoadDataSource(data, "receipts")
        // onCloseReceiptModal(null);
    };


    confirmBtnComplete = () => {
        const { allowEditing, dataReceipt } = this.state;
        const messageBtnComplete = allowEditing ? "ERP_Thong_bao_xac_nhan_chung_tu" : "ERP_Khi_xac_nhan_hoan_tat_ban_se_khong_duoc_sua";
        Config.popup.show("YES_NO", Config.lang(messageBtnComplete), async () => {
            dataReceipt.forEach(item => item.IsCompleteVoucher = item.IsCompleteVoucher === 0 ? 1 : 0)
            await this.onSaveReceipt();
            this.setState({ allowEditing: !allowEditing })
        });
    }

    render() {
        const { receiptsLoaded, dataReceipt, totalReceipt, allowEditing, btnCompleteStatus, btnSaveStatus } = this.state;
        const { onOpenReceiptModal, onCloseReceiptModal, rowData, classes, isView } = this.props;
        const btnCompleteTitle = allowEditing ? "ERP_Da_hoan_tat" : "ERP_Hoan_tat";

        return (
            <Modal
                open={onOpenReceiptModal}
                maxWidth={"sm"}
                fullWidth={true}
                position={"center"}
            >
                <Modal.Title disableTypography>
                    <div className={"display_row align-center align-between flex-wrap"} style={{ width: "100%" }}>
                        <div className={"display_row align-center"}>
                            <Typography variant={"h6"} className={"mgr10 text-uppercase"}>{Config.lang("ERP_Cap_nhat_chung_tu")}</Typography>
                            <Tooltip title={Config.lang(btnCompleteTitle)}>
                                <span>
                                    <ButtonGeneral
                                        name={Config.lang(btnCompleteTitle)}
                                        variant={"outlined"}
                                        color={"primary"}
                                        disabled={receiptsLoaded || isView || btnCompleteStatus}
                                        style={{ textTransform: "uppercase" }}
                                        onClick={() => {
                                            this.confirmBtnComplete();
                                        }}
                                    />
                                </span>
                            </Tooltip>
                        </div>
                        <div>
                            <IconButton
                                disabled={isView || allowEditing || btnSaveStatus}
                                aria-label={"save"}
                                size={"small"}
                                className={"mgr10"}
                                onClick={async () => {
                                    await this.onSaveReceipt();
                                    onCloseReceiptModal(null);
                                }}
                            >
                                <Icons name={"save_filled"} />
                            </IconButton>
                            <IconButton
                                aria-label={"close"}
                                size={"small"}
                                onClick={() => {
                                    this.setState({ Status: 0, StatusDetailName: "" });
                                    onCloseReceiptModal(null);
                                }}
                            >
                                <Icons name={"cancel_filled"} />
                            </IconButton>
                        </div>
                    </div>
                </Modal.Title>
                <Modal.Content>
                    <div className={classes.lotCode}>
                        <Label className={classes.divHeaderTitle}
                            {...(!Config.isMobile ? { style: { paddingRight: 8 } } : {})}>
                            {`${Config.lang("ERP_Ma_lot")}:`}
                        </Label>
                        <Label className={classes.divHeaderItem}
                            {...(!Config.isMobile ? { style: { paddingRight: 8 } } : {})}>
                            {Config.lang(rowData.LotNo)}
                        </Label>
                    </div>
                    <div className={classes.cellPadding}>
                        <GridContainer
                            reference={(ref) => this.receiptGrid = ref}
                            totalItems={totalReceipt}
                            dataSource={dataReceipt}
                            loading={receiptsLoaded}
                            keyExpr={"VoucherTypeID"}
                            typePaging={"normal"}
                            typeShort={window.innerWidth < 768}
                            rowAlternationEnabled={true}
                            allowColumnResizing={true}
                            hoverStateEnabled={true}
                            pagerFullScreen={false}
                            columnAutoWidth={true}
                            showBorders={false}
                            elementAttr={{
                                style: 'max-height: 480px',
                                class: 'noBGSelect'
                            }}
                            editing={{
                                mode: "cell",
                                refreshMode: "reshape",
                                allowUpdating: true,
                                texts: { confirmDeleteMessage: "" }
                            }}
                            onRowUpdated={(e) => {
                                if (this.state.btnSaveStatus) this.setState({ btnSaveStatus: false })
                            }}
                            loadPanel={{ enabled: receiptsLoaded }}
                        >
                            <Column
                                caption={Config.lang("ERP_Chung_tu")}
                                dataField={"VoucherTypeName"}
                                width={200}
                            />
                            <Column
                                caption={Config.lang("ERP_Su_dung")}
                                dataField={"IsUse"}
                                alignment={"center"}
                                width={180}
                                allowEditing={false}
                                cellRender={(e) => {
                                    const { data } = e;
                                    return <Checkbox label={""}
                                        disabled={allowEditing || isView}
                                        checked={!!data.IsUse}
                                        color={"primary"}
                                        className={classes.checkBoxGrid}
                                        onChange={(chk) => {
                                            if (allowEditing) return;
                                            if (data.IsComplete === 1) {
                                                const message = Config.lang("ERP_Ban_da_check_hoan_thanh_chung_tu_nay");
                                                Config.popup.show("INFO", message);
                                            } else {
                                                e.component.cellValue(e.rowIndex, "IsUse", chk.target.checked ? 1 : 0);
                                                e.component.saveEditData();
                                            }
                                        }}
                                    />
                                }}
                            />
                            <Column
                                caption={Config.lang("ERP_Hoan_thanh")}
                                dataField={"IsComplete"}
                                alignment={"center"}
                                width={180}
                                allowEditing={false}
                                cellRender={(e) => {
                                    const { data } = e;
                                    return <Checkbox label={""}
                                        disabled={allowEditing || isView}
                                        checked={!!data.IsComplete}
                                        color={"primary"}
                                        className={classes.checkBoxGrid}
                                        onChange={(chk) => {
                                            if (allowEditing) return;
                                            if (data.IsUse === 1) {
                                                e.component.cellValue(e.rowIndex, "IsComplete", chk.target.checked ? 1 : 0);
                                                e.component.saveEditData();
                                            } else {
                                                const message = Config.lang("ERP_Ban_phai_check_su_dung_truoc");
                                                Config.popup.show("INFO", message);
                                            }
                                        }}
                                    />
                                }}
                            />
                        </GridContainer>
                    </div>
                </Modal.Content>
            </Modal>
        );
    }
}

W05F0005ReceiptModal.propsTypes = {
    isView: PropTypes.bool,
    onOpenReceiptModal: PropTypes.bool,
    rowData: PropTypes.object,
    reLoadDataSource: PropTypes.func,
    onCloseReceiptModal: PropTypes.func,
};

export default compose(connect(state => ({
    dataAddReceipt: state.W05F0005.dataAddReceipt,
}),
    (dispatch) => ({
        w05f0005Actions: bindActionCreators(w05f0005Actions, dispatch),
    })
),
    withStyles(styles)
)(W05F0005ReceiptModal);
