import { Checkbox, FormLabel as Label } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ReorderIcon from '@material-ui/icons/Reorder';
import { Column } from "devextreme-react/data-grid";
import _ from "lodash";
import moment from 'moment';
import React, { Component } from 'react';
import { Col } from "react-bootstrap";
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Config from '../../../../config';
import * as W05F0005Actions from '../../../../redux/W0X/W05F0005/W05F0005_actions';
import ButtonGeneral from "../../../common/button/button-general";
import { Combo, TextField } from "../../../common/form-material";
import Icons from "../../../common/icons/";
import TabContent, { TabPanel } from "../../../common/tabs/tab-content";
import GridActionBar from "../../../grid-container/grid-actionbar";
import GridContainer from "../../../grid-container/grid-container";
import HeadClick from "../../../grid-container/head-click";
import PopoverAction from "../../../grid-container/popover-action";
import CbLoadmore from './cbLoadmore';
import W05F0005LotPopup from './W05F0005LotPopup';
import W05F0005PaymentModal from './W05F0005PaymentModal';
import W05F0005ReceiptModal from './W05F0005ReceiptModal';
import W05F0005RequestLotPopup from './W05F0005RequestLotPopup';
import W91F0001 from '../../W09/W91F0001/W91F0001';
import W05F0005LocationModal from './W05F0005LocationModal';
import SelectIDNameModal from '../../../common/selectIDNameModal/SelectIDNameModal';
const styles = {
    divIcon: {
        right: '50px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItem: 'center',
        padding: '5px 10px',
        zIndex: 999,
        position: 'absolute',
        background: '#FFFFFF',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
        listStyle: 'none',
        marginTop: '-30px',
        borderRadius: '4px',
    },
    divIconItem: {
        padding: 0,
        width: 30,
        height: 30,
        marginRight: 4,
        '&:last-child': {
            marginRight: 0
        }
    },
    hiddenList: {
        opacity: 0,
        visibility: 'hidden'
    },
    pStatus: {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '20px'
    },
    hiddenFormItem: {
        display: 'none'
    },
    columnActions: {
        height: '35px !important'
    },
    divIconMobile: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '120px',
        padding: 0,
        zIndex: 999,
        position: 'fixed',
        background: '#FFFFFF',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
        listStyle: 'none',
        borderRadius: '4px',
    },
    dateBoxGrid: {
        '&:after': {
            content: 'none'
        },
        '& input': {
            height: '35px !important',
            minHeight: '35px !important',
        }
    },
    divActionName: {
        height: 30,
        width: '100%',
        textAlign: 'left',
        display: 'block',
        padding: '0 5px'
    },
    removeBorder: {
        '& div': {
            '&:before': {
                borderBottomStyle: 'none !important',
                border: 'none !important'
            },
            '&:after': {
                border: 'none !important'
            },
            '& input': {
                textAlign: 'right !important'
            },
        }
    },
    numberInput: {
        width: '100%',
        paddingRight: '7px',
        paddingTop: '7px',
    },
    removeBg: {
        '& .cell-selected': {
            backgroundColor: '#EFF5FF !important'
        }
    },
    hiddenRedoBtn: {
        '& div.dx-overlay-content': {
            display: 'none',
            opacity: 0,
            visibility: 'hidden'
        }
    },
    columnGrid: {
        height: '48px !important',
        minHeight: '48px !important',
    },
    radioButton: {
        width: '61px !important',
        '& .MuiCheckbox-root': {
            padding: 5
        }
    },
};
class W05F0005TabContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reload: false,
            opendLot: false,
            isUpdated: false,
            paymentModal: false,
            receiptModal: false,
            type: "",
            rowData: "",
            selectedRange: {},
            currentRowClick: {},
            showW91F0001: false,
            openLocationNoModal: false,
            showInventorySelect: false,

        }
        this.selectedRowIndx = 0;
        this.selectedRange = {};
        this.isCalc = false;
        this.popoverGrid = null;
        this.itemW91F10001 = null;
        this.ObjectTypeID = "KH";
    }

    componentDidMount = () => {
        if (this.props.onRef) this.props.onRef(this);
        if (_.isEmpty(this.props.getCboPaymentMenthod)) this.getPaymentMenthod();
    }

    getPaymentMenthod = () => {
        this.props.W05F0005Actions.loadCboPaymentMethod( (error) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
        });
    };

    componentDidUpdate = () => {
        const { opendLot, paymentModal, receiptModal } = this.state;
        if (opendLot || paymentModal || receiptModal) this.popoverGrid.hide(); // Modal nào mở thì ẩn tooltip
    }

    renderDate = (e) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                <Col md={6}>
                    <Label >{e.data.DeliveryDateFrom !== null ? moment(e.data.DeliveryDateFrom).format("DD/MM/YYYY") : ""}</Label>
                </Col>
                <Label >-</Label>
                <Col md={6}>
                    <Label >{e.data.DeliveryDateTo !== null ? moment(e.data.DeliveryDateTo).format("DD/MM/YYYY") : ""}</Label>
                </Col>
            </div>
        )
    }

    onOpenLOT = (type) => {
        this.setState({ opendLot: !this.state.opendLot, type: type, rowData: this.currentRowClick })
    }

    onModalPopup = (status, modalName) => {
        this.setState({ [modalName]: status, rowData: this.currentRowClick })
    }

    cellRender = (e, key) => {
        switch (key) {
            case "UnitPack":
                return (
                    <Label>{e.data.UnitPackName}</Label>
                )
            case "Estimate":
                return (
                    <Label>{e.data.EstimateName}</Label>
                )
            default:
                break;
        }

    }
    reLoadDataSource = (data, type, dataHistory = []) => {
        this.props.reLoadDataSource(data, type, dataHistory);
    }
    dataFieldName = (fieldName) => {
        let rs = fieldName;
        switch (fieldName) {
            case "StatusDetail":
                rs = "StatusDetailName";
                break;
            case "MarkingID":
                rs = "MarkingName";
                break;
            case "PackingMethodID":
                rs = "PackingMethodName";
                break;
            case "PackingTypeID":
                rs = "PackingTypeName";
                break;
            case "PortToBoat":
                rs = "PortToBoatName";
                break;
            case "PortFromBoat":
                rs = "PortFromBoatName";
                break;
            case "PortToCont":
                rs = "PortToContName";
                break;
            case "PortFromCont":
                rs = "PortFromContName";
                break;
            case "UnitPackID":
                rs = "UnitPackName";
                break;
            case "EstimateID":
                rs = "EstimateName";
                break;
            case "ObjectID":
                rs = "ObjectName";
                break;
            case "ObjectIDCont":
                rs = "ObjectNameCont";
                break;
            default:
                break;
        }
        return rs;
    }

    renderHeaderColumns = (e) => {
        const { cbVessel, ExchangeRate, cbUnitPack, cbEstimate, cbShippingAgent1, cbShippingAgent2, isView, btnCombineLot } = this.props;
        const allowUpdating = !isView && !btnCombineLot;
        if (!e) return false;
        // return <div style={{color: col.HeaderTextColor}}>{col.Caption}</div>
        return <HeadClick selectedRange={this.state.selectedRange}
            dataHeaderCell={e}
            allowClick={allowUpdating || (e?.column?.dataField === "LotNo" && btnCombineLot)}
            isCopyAll={e?.column?.dataField === "LotNo" && btnCombineLot}
              conditionCopy={(e) => {
                  const {data} = e || {};
                  if (e && e.key === "LotNo") {
                      return data?.IsChecked === 1;
                  }
                  return true;
              }}
            dataFieldName={this.dataFieldName}
            callbackAfterCopy={rs => {
                const { column } = e
                const dataField = column.dataField;
                const startRowIndx = this.state.selectedRange.startRowIndex;
                const getDataSource = e.component.getDataSource();
                const dataSource = getDataSource.items();
                let datafilter = [];
                switch (dataField) {
                    case "UnitPackID":
                        for (let i = startRowIndx + 1; i < dataSource.length; i++) {
                            let data = dataSource[i];
                            if (!_.isEmpty(cbUnitPack)) {
                                datafilter = cbUnitPack.filter(c => c.UnitPackID === data.UnitPackID);
                            }
                            data["UnitPackName"] = datafilter.length > 0 ? datafilter[0].UnitPackName : "";
                        }
                        getDataSource.reload();
                        break;
                    case "EstimateID":
                        for (let i = startRowIndx + 1; i < dataSource.length; i++) {
                            let data = dataSource[i];
                            if (!_.isEmpty(cbEstimate)) {
                                datafilter = cbEstimate.filter(c => c.EstimateID === data.EstimateID);
                            }
                            data["EstimateName"] = datafilter.length > 0 ? datafilter[0].EstimateName : "";
                        }
                        getDataSource.reload();
                        break;
                    case "VesselID":
                        for (let i = startRowIndx + 1; i < dataSource.length; i++) {
                            let data = dataSource[i];
                            if (!_.isEmpty(cbVessel)) {
                                datafilter = cbVessel.filter(c => c.VesselID === data.VesselID);
                            }
                            data["VesselName"] = datafilter.length > 0 ? datafilter[0].VesselName : "";
                            data["ObjectName"] = datafilter.length > 0 ? datafilter[0].ObjectName : "";
                        }
                        getDataSource.reload();
                        break;
                    case "VesselIDCont":
                        for (let i = startRowIndx + 1; i < dataSource.length; i++) {
                            let data = dataSource[i];
                            if (!_.isEmpty(cbVessel)) {
                                datafilter = cbVessel.filter(c => c.VesselID === data.VesselIDCont);
                            }
                            data["VesselNameCont"] = datafilter.length > 0 ? datafilter[0].VesselName : "";
                            data["ObjectNameCont"] = datafilter.length > 0 ? datafilter[0].ObjectName : "";
                        }
                        getDataSource.reload();
                        break;
                    // case 'DeliveryDate':
                    //     for (let i = startRowIndx + 1; i < dataSource.length; i++) {
                    //         let data = dataSource[i];
                    //         let datefrom = data.DeliveryDate.substring(0, data.DeliveryDate.lastIndexOf('+'));
                    //         let dateto = data.DeliveryDate.substring(data.DeliveryDate.lastIndexOf('+') + 1, data.DeliveryDate.length);
                    //         data["DeliveryDateFrom"] = datefrom;
                    //         data["DeliveryDateTo"] = dateto;
                    //     }
                    //     getDataSource.reload();
                    //     break;
                    case "DeliveryDateFrom":
                        for (let i = startRowIndx + 1; i < dataSource.length; i++) {
                            let data = dataSource[i];
                            data["DeliveryDateFrom"] = moment(data["DeliveryDateFrom"]).format("YYYY-MM-DD");
                        }
                        getDataSource.reload();
                        break;
                    case "DeliveryDateTo":
                        for (let i = startRowIndx + 1; i < dataSource.length; i++) {
                            let data = dataSource[i];
                            data["DeliveryDateTo"] = moment(data["DeliveryDateTo"]).format("YYYY-MM-DD");
                        }
                        getDataSource.reload();
                        break;
                    case "RateReduce":
                        for (let i = startRowIndx + 1; i < dataSource.length; i++) {
                            let data = dataSource[i];
                            data["OriginalReduce"] = data.Quantity * data.UnitPrice * data.RateReduce;
                            data["CReduce"] = data.Quantity * data.UnitPrice * ExchangeRate * data.RateReduce;
                        }
                        getDataSource.reload();
                        break;
                    case "Quantity":
                        for (let i = startRowIndx + 1; i < dataSource.length; i++) {
                            let data = dataSource[i];
                            data["Amount"] = data.Quantity * data.UnitPrice;
                            data["CAmount"] = data.Quantity * data.UnitPrice * ExchangeRate;
                            data["OriginalReduce"] = data.Quantity * data.UnitPrice * (data.RateReduce !== undefined ? data.RateReduce : 0);
                            data["CReduce"] = data.Quantity * data.UnitPrice * ExchangeRate * (data.RateReduce !== undefined ? data.RateReduce : 0);
                            this.props.onRefW05F0005.setQuantity();
                        }
                        getDataSource.reload();
                        break;
                    case "ObjectID":
                        for (let i = startRowIndx + 1; i < dataSource.length; i++) {
                            let data = dataSource[i];
                            if (!_.isEmpty(cbShippingAgent1)) {
                                datafilter = cbShippingAgent1.filter(c => c.ObjectID === data.ObjectID);
                            }
                            data["ObjectName"] = datafilter.length > 0 ? datafilter[0].ObjectName : "";
                        }
                        getDataSource.reload();
                        break;
                    case "ObjectIDCont":
                        for (let i = startRowIndx + 1; i < dataSource.length; i++) {
                            let data = dataSource[i];
                            if (!_.isEmpty(cbShippingAgent2)) {
                                datafilter = cbShippingAgent2.filter(c => c.ObjectIDCont === data.ObjectIDCont);
                            }
                            data["ObjectNameCont"] = datafilter.length > 0 ? datafilter[0].ObjectNameCont : "";
                        }
                        getDataSource.reload();
                        break;
                    default:
                        break;
                }
                this.setState({ isUpdated: rs.IsUpdated });
                this.props.onRefW05F0005.setIsUpdate();
            }}
        />
    };

    getDataFlName = (dataField, rowData) => {
        let rs = null;
        switch (dataField) {
            case "UnitPackID":
                rs = rowData.UnitPackName;
                break;
            case "EstimateID":
                rs = rowData.EstimateName;
                break;
            case "VesselID":
                rs = rowData.VesselID;
                break;
            case "VesselIDCont":
                rs = rowData.VesselIDCont;
                break;
            case "ObjectID":
                rs = rowData.ObjectName;
                break;
            case "ObjectIDCont":
                rs = rowData.ObjectNameCont;
                break;
            case "InsuranceID":
                rs = rowData.InsuranceName;
                break;
            case "GuaranteeID":
                rs = rowData.GuaranteeName;
                break;
            case "ParcelInventoryID":
                rs = rowData.ParcelInventoryName;
                break;
            default:
                break;
        }
        return rs;
    }

    cellRenderPopupAction = (cellData, disabled) => {
        const dataField = cellData.column.dataField,
            rowData = cellData.data;
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className={"pull-left mgr5"}>
                    <IconButton disabled={!disabled} size={'small'} style={{ display: `${this.props.isView ? 'none' : 'block'}` }}
                        onClick={() => {
                            this.showPopup(rowData[dataField], dataField, cellData);
                            this.selectedRowIndx = cellData.rowIndex;
                        }}>
                        <ReorderIcon fontSize={"small"} />
                    </IconButton>
                </div>
                <div className={"pull-left textCellHidden"} title={this.getDataFlName(dataField, rowData)}>{this.getDataFlName(dataField, rowData)}</div>
            </div>
        );
    };

    showPopup = (data, dataField, e) => {
        const value = data ? data.replace(/,/g, ';').split(';') : [];
        switch (dataField) {
            case "UnitPackID":
                this.popupUnitPack.tmpSelectedRowKeys = value;
                setTimeout(() => {
                    this.popupUnitPack && this.popupUnitPack.open();
                }, 100);
                break;
            case "EstimateID":
                this.popupEstimate.tmpSelectedRowKeys = value;
                setTimeout(() => {
                    this.popupEstimate && this.popupEstimate.open();
                }, 100);
                break;
            case "VesselID":
                this.popupVessel.tmpSelectedRowKeys = value;
                setTimeout(() => {
                    this.popupVessel && this.popupVessel.open();
                }, 100);
                break;
            case "VesselIDCont":
                this.popupVesselCont.tmpSelectedRowKeys = value;
                setTimeout(() => {
                    this.popupVesselCont && this.popupVesselCont.open();
                }, 100);
                break;
            case "ObjectID":
                this.popupShippingAgent1.tmpSelectedRowKeys = value;
                setTimeout(() => {
                    this.popupShippingAgent1 && this.popupShippingAgent1.open();
                }, 100);
                break;
            case "ObjectIDCont":
                this.popupShippingAgent2.tmpSelectedRowKeys = value;
                setTimeout(() => {
                    this.popupShippingAgent2 && this.popupShippingAgent2.open();
                }, 100);
                break;
            case "InsuranceID":
            case "GuaranteeID":
                this.ObjectTypeID = dataField === "InsuranceID" ? "CC" : "KH";
                this.itemW91F10001 = dataField;
                this.setState({ showW91F0001: true });
                break;
            case "ParcelInventoryID":
                this.setState({ showInventorySelect: true });
                break;
            default:
                break;
        }
    };

    onSelectedPopup = (data, field) => {
        switch (field) {
            case "UnitPackID":
                this.props.filterGeneral10.limit = 20;
                this.props.filterGeneral10.skip = 0;
                this.gridTab0.instance.cellValue(this.selectedRowIndx, field, data[0].UnitPackID);
                this.gridTab0.instance.cellValue(this.selectedRowIndx, "UnitPackName", data[0].UnitPackName);
                this.gridTab0.instance.saveEditData();
                break;
            case "EstimateID":
                this.props.filterGeneral20.limit = 20;
                this.props.filterGeneral20.skip = 0;
                this.gridTab0.instance.cellValue(this.selectedRowIndx, field, data[0].EstimateID);
                this.gridTab0.instance.cellValue(this.selectedRowIndx, "EstimateName", data[0].EstimateName);
                this.gridTab0.instance.saveEditData();
                break;
            case "VesselID":
                this.props.filterCbVessel.limit = 20;
                this.props.filterCbVessel.skip = 0;
                this.gridTab2.instance.cellValue(this.selectedRowIndx, field, data[0].VesselID);
                this.gridTab2.instance.cellValue(this.selectedRowIndx, "VesselName", data[0].VesselName);
                this.gridTab2.instance.saveEditData();
                break;
            case "ObjectID":
                this.props.filterShippingAgent1.limit = 20;
                this.props.filterShippingAgent1.skip = 0;
                this.gridTab2.instance.cellValue(this.selectedRowIndx, field, data[0].ObjectID);
                this.gridTab2.instance.cellValue(this.selectedRowIndx, "ObjectName", data[0].ObjectName);
                this.gridTab2.instance.saveEditData();
                break;
            case "VesselIDCont":
                this.props.filterCbVessel.limit = 20;
                this.props.filterCbVessel.skip = 0;
                this.gridTab3.instance.cellValue(this.selectedRowIndx, field, data[0].VesselID);
                this.gridTab3.instance.cellValue(this.selectedRowIndx, "VesselNameCont", data[0].VesselName);
                this.gridTab3.instance.saveEditData();
                break;
            case "ObjectIDCont":
                this.props.filterShippingAgent2.limit = 20;
                this.props.filterShippingAgent2.skip = 0;
                this.gridTab3.instance.cellValue(this.selectedRowIndx, field, data[0].ObjectIDCont);
                this.gridTab3.instance.cellValue(this.selectedRowIndx, "ObjectNameCont", data[0].ObjectNameCont);
                this.gridTab3.instance.saveEditData();
                break;
            case "LocationNo":
                this.props.W05F0005Actions.saveLocationNo(data, (error, dataU) => {
                    if (error) {
                        let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                        Config.popup.show("INFO", message);
                        return false;
                    }
                    if (dataU.No) {
                        Config.popup.show("INFO", Config.lang('Ma_Lo_da_ton_tai'));
                        return;
                    }
                    this.gridTab0.instance.cellValue(this.selectedRowIndx, field, data[field]);
                    this.props.dataGrid[this.selectedRowIndx].LocationNoDes = data['LocationNoDes'];
                    this.gridTab0.instance.saveEditData();
                    this.setOpenLocationNoModal();
                    this.props.onRefW05F0005.getCbLocationNo();
                });
                break;
            default:
                break;
        }
        this.props.dataGrid[this.selectedRowIndx].IsUpdated = 1;
        this.setState({ flag: false });
    };

    loadMoreG = (data) => {
        switch (data.O08ID) {
            case "10":
                this.props.onRefW05F0005.getCbGeneral(this.props.filterGeneral10);
                break;
            case "20":
                this.props.onRefW05F0005.getCbGeneral(this.props.filterGeneral20);
                break;
            case "30":
                this.props.onRefW05F0005.getCbGeneral(this.props.filterGeneral30);
                break;
            default:
                break;
        }
    }

    loadMoreVessel = () => {
        this.props.onRefW05F0005.getCbVessel(this.props.filterCbVessel)
    }

    onRowClick = (e) => {
        if (typeof e.rowIndex !== 'undefined') {
            this.selectedRowIndx = e.rowIndex;
        }
    }

    handleActionClick = (e, data) => {
        const target = e.currentTarget;
        this.currentRowClick = data;
        if (this.popoverGrid) {
            this.popoverGrid.show(target, data);
        }
    };

    onDeleteRowVessel = (e, mode) => {
        let name = mode === "grid2" ? "ERP_Di_tau" : "ERP_Di_cont";
        Config.popup.show(
            "YES_NO",
            Config.lang(`ERP_Ban_co_muon_xoa_du_lieu`) + ` Lot ${e.data["LotNo"]} ` + Config.lang(`ERP_O_tab`) + ' ' + Config.lang(name) + ' ?',
            () => {
                if (mode === "grid2") {
                    e.data["IsUpdated"] = 1;
                    // e.data["VesselID"] = ""; // Đã ẩn cột
                    e.data["VesselName"] = "";
                    e.data["ObjectID"] = "";
                    e.data["ObjectName"] = "";
                    e.data["DateVesselIn"] = "";
                    e.data["DateVesselOut"] = "";
                    e.data["PortToBoat"] = "";
                    e.data["PortToBoatName"] = "";
                    e.data["PortFromBoat"] = "";
                    e.data["PortFromBoatName"] = "";
                    e.component.saveEditData();
                    this.setState({ isUpdated: true });
                } else { // ================ grid3
                    e.data["IsUpdated"] = 1;
                    e.data["BookingNo"] = "";
                    e.data["QuantityBooking"] = "";
                    e.data["ObjectID"] = "";
                    e.data["ObjectName"] = "";
                    e.data["DateActual"] = "";
                    // e.data["VesselIDCont"] = "";  // Đã ẩn cột
                    e.data["VesselNameCont"] = "";
                    e.data["ObjectIDCont"] = "";
                    e.data["ObjectNameCont"] = "";
                    e.data["ContWeight"] = "";
                    e.data["PortToCont"] = "";
                    e.data["PortToContName"] = "";
                    e.data["PortFromCont"] = "";
                    e.data["PortFromContName"] = "";
                    e.component.saveEditData();
                    this.setState({ isUpdated: true });
                }
            },
            () => {
                //no
            }
        );
    }

    sendRequestDeleteMapping = (currentRowClick) => {
        const { QuotationID = "", ContractNo = "", VoucherNum = "" } = this.props;
        const { QuotationItemID = "", LotNo = "" } = currentRowClick;
        const params = {
            QuotationID,
            QuotationItemID,
            ContractNo,
            VoucherNum,
            LotNo,
            UserName: Config.profile.UserNameU || "",
        };
        this.props.W05F0005Actions.sendRequestDeleteMapping(params, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                Config.notify.show("success", Config.lang("ERP_Gui_yeu_cau_thanh_cong"), 2000);
            }
        })
    }

    onDeleteRow = (e, currentRowClick) => {
        if (currentRowClick.IsMapping ? currentRowClick.IsMapping === 0 : true) {
            Config.popup.show("YES_NO", Config.lang(`Ban_co_chac_muon_xoa?`),
                () => {
                    e.component.deleteRow(e.rowIndex);
                    e.component.saveEditData();
                    if (this.props.dataGrid?.length <= 0) { // XÓa hết lưới thì refresh lại page cha
                        this.props.onRefW05F0005.setRefreshPage(true);
                    }
                });
        } else if (currentRowClick.IsMapping === 1) {
            Config.popup.show("YES_NO", Config.lang("ERP_Lot_da_mapping_khong_the_xoa_ban_co_muon_gui_yeu_cau_xoa_mapping_khong"), () => {
                this.sendRequestDeleteMapping(currentRowClick);
            });
        }
    };

    renderButtonAction = (e, mode) => {
        const { data } = e.row;
        const { classes, isView, btnCombineLot } = this.props;
        const allowUpdating = !isView && !btnCombineLot;
        return (
            <GridActionBar>
                <IconButton
                    aria-label={"view"}
                    className={classes.divIconItem}
                    onClick={(a) => this.handleActionClick(a, data)}
                    disabled={false}
                >
                    <MoreVertIcon />
                </IconButton>
                {allowUpdating &&
                    <Tooltip
                        title={Config.lang("ERP_Xoa_dong")}
                        aria-label={Config.lang("ERP_Xoa_dong")}>
                        <IconButton
                            aria-label={"delete"}
                            size={"small"}
                            onClick={() => this.onDeleteRow(e, data)}
                        >
                            <Icons name={"delete"} />
                        </IconButton>
                    </Tooltip>
                }
                {!_.isUndefined(mode) && !isView && allowUpdating &&
                    <Tooltip
                        title={Config.lang("ERP_Xoa_du_lieu")}
                        aria-label={Config.lang("ERP_Xoa_du_lieu")}>
                        <IconButton
                            aria-label={"view"}
                            className={classes.divIconItem}
                            data-id={data.key}
                            onClick={() => this.onDeleteRowVessel(e, mode)}
                            disabled={false}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                }
            </GridActionBar>
        );
    };

    onSaveSwipeTab = async (cb) => {
        if (this.gridTab0 !== null && this.gridTab0 !== undefined) {
            await this.gridTab0.instance.saveEditData();
            if (cb) cb();
        } else if (this.gridTab1 !== null && this.gridTab1 !== undefined) {
            await this.gridTab1.instance.saveEditData();
            if (cb) cb();
        } else if (this.gridTab2 !== null && this.gridTab2 !== undefined) {
            await this.gridTab2.instance.saveEditData();
            if (cb) cb();
        } else if (this.gridTab3 !== null && this.gridTab3 !== undefined) {
            await this.gridTab3.instance.saveEditData();
            if (cb) cb();
        }
    }

    renderNumberFormat = (e) => {
        if (!e) return false;
        const { decimals } = this.props;
        const { data } = e.row;
        const { column } = e;
        const dataField = column.dataField;
        let decimalValue = Number(data[dataField]);
        let decimal = decimals?.OriginalDecimal ?? 0;
        switch (dataField) {
            case "Quantity":
                decimal = decimals ? decimals.QuantityDecimals : 5
                break;
            case "Amount":
            case "OriginalReduce":
                decimal = decimals ? decimals.OriginalDecimal : 6
                break;
            case "CAmount":
            case "CReduce":
                decimal = decimals ? decimals.ConvertedDecimal : 0
                break;
            case "RateReduce":
                // decimal = decimals ? decimals.ExchangeRateDecimals : 2
                break;
            case "AdjustedUnitPrice":
                // decimal = decimals?.UnitPriceDecimals ? decimals?.UnitPriceDecimals : 2
                break;
            default:
                break;
        }
        return <NumberFormat value={decimalValue} displayType={"text"} thousandSeparator={true} decimalScale={decimal} fixedDecimalScale />
    };

    //CÁC FUNCTION CỦA TASK [EW-107] - KHÔNG ĐƯỢC XÓA
    cellRenderRadio = (e) => {
        let status = null;
        return (
            <Checkbox
                label={""}
                color={"primary"}
                value={e.data.IsChecked}
                checked={e.data.IsChecked === 1}
                className={this.props.classes.checkBoxGrid}
                onChange={(chk) => {
                    e.component.cellValue(e.rowIndex, "IsChecked", chk.target.checked ? 1 : 0);
                    e.component.saveEditData();
                    if (chk.target.checked) { // Check
                        // status = true  Lần check đầu tiên...
                        // status = false   Lần check thứ n...
                        status = _.isEmpty(this.props.firstLotChecked);
                    } else { // UnCheck
                        status = null;
                    }
                    this.props.onRefW05F0005.setLotChecked(status, e.data);
                }}
            />
        )
    }
    //CÁC FUNCTION CỦA TASK [EW-107] - KHÔNG ĐƯỢC XÓA

    onSelectedInsurance = (e) => {
        this.gridTab0.instance.cellValue(this.selectedRowIndx, this.itemW91F10001, e.ObjectID);
        this.gridTab0.instance.cellValue(this.selectedRowIndx, this.itemW91F10001 === 'InsuranceID' ? 'InsuranceName' : 'GuaranteeName', e.ObjectNameU);
        if(this.itemW91F10001==='GuaranteeID'){
            this.gridTab0.instance.cellValue(this.selectedRowIndx, 'GuaranteeTypeID', e.ObjectTypeID);
        }
        this.props.dataGrid[this.selectedRowIndx].IsUpdated = 1;
        this.gridTab0.instance.saveEditData();
        this.setState({ showW91F0001: false });
    }

    setOpenLocationNoModal = () => {
        this.setState({ openLocationNoModal: !this.state.openLocationNoModal });
    }

    selectedParcelInventoryID=(e)=>{
        this.gridTab0.instance.cellValue(this.selectedRowIndx, "ParcelInventoryID", e[0]?.InventoryID);
        this.gridTab0.instance.cellValue(this.selectedRowIndx, "ParcelInventoryName", e[0]?.InventoryNameU);
        this.props.dataGrid[this.selectedRowIndx].IsUpdated = 1;
        this.gridTab0.instance.saveEditData();
        this.setState({ showInventorySelect: false });
    }

    render() {
        let { EStatus, dataGrid, tabDetailSupplement, cbStatusD, cbPackingMethod, cbPackingType, cbMarking,
            decimals, cbPort, classes, isView, ExchangeRate, isEdit, filterShippingAgent2, isLoadForm, btnCombineLot,
            filterGeneral10, filterGeneral20, filterGeneral30, filterCbVessel, filterShippingAgent1, filterFumiCompany,
            dataAdjustColumns, getCboPaymentMenthod, cbLocationNo, isPerW05F2004
        } = this.props;
        let { opendLot, rowData, type, receiptModal, paymentModal, showW91F0001, openLocationNoModal, showInventorySelect } = this.state;
        const allowUpdating = !isView && !btnCombineLot;
        const btnAction = !btnCombineLot;
        const columnUnitPacks = [
            {
                caption: Config.lang("ERP_Ma"),
                dataField: "UnitPackID",
                width: 200
            },
            {
                caption: Config.lang("ERP_Ten"),
                dataField: "UnitPackName"
            }
        ];
        const columnEstimate = [
            {
                caption: Config.lang("ERP_Ma"),
                dataField: "EstimateID",
                width: 200
            },
            {
                caption: Config.lang("ERP_Ten"),
                dataField: "EstimateName"
            }
        ];
        const columnVessel = [
            {
                caption: Config.lang("ERP_Ma"),
                dataField: "VesselID",
                width: 200
            },
            {
                caption: Config.lang("ERP_Ten"),
                dataField: "VesselName"
            },
            {
                caption: Config.lang("ERP_Dai_ly_tau"),
                dataField: "ObjectName"
            },
        ];
        const columnShippingAgent1 = [
            {
                caption: Config.lang("ERP_Ma"),
                dataField: "ObjectID",
                width: 200
            },
            {
                caption: Config.lang("ERP_Ten"),
                dataField: "ObjectName"
            }
        ];
        const columnShippingAgent2 = [
            {
                caption: Config.lang("ERP_Ma"),
                dataField: "ObjectIDCont",
                width: 200
            },
            {
                caption: Config.lang("ERP_Ten"),
                dataField: "ObjectNameCont"
            }
        ];
        return (
            <>
                {showInventorySelect && <SelectIDNameModal
                    formID={"W05F0002"}
                    maxWidth={"sm"}
                    mode='single'
                    modalType={"Inventory"}
                    openModal={showInventorySelect}
                    onChosen={this.selectedParcelInventoryID}
                    onClose={() => this.setState({ showInventorySelect: false })}
                />}
                {showW91F0001 && <W91F0001 showW91F0001={showW91F0001} ObjectTypeID={this.ObjectTypeID}
                    onCloseModal={() => this.setState({ showW91F0001: false })}
                    mode={1} onHide={this.onSelectedInsurance} reLoadPage
                />}
                {openLocationNoModal && <W05F0005LocationModal onClose={this.setOpenLocationNoModal}
                    onAddLocationNo={e => this.onSelectedPopup(e, 'LocationNo')}
                />}
                {opendLot && type !== "Request_LOT" && rowData && <W05F0005LotPopup onRefW05F0005={this.props.onRefW05F0005} opendLot={opendLot} onCloseLOT={this.onOpenLOT} rowData={rowData} reLoadDataSource={this.reLoadDataSource}
                    isEdit={isEdit} isView={isView} ExchangeRate={ExchangeRate} type={type} filterGeneral30={filterGeneral30} loadMoreG={this.loadMoreG} filterFumiCompany={filterFumiCompany}
                    decimalScale={decimals?.QuantityDecimals ?? 5}
                />}

                {opendLot && type === "Request_LOT" && <W05F0005RequestLotPopup
                    onRefW05F0005={this.props.onRefW05F0005}
                    opendLot={opendLot}
                    onCloseLOT={this.onOpenLOT}
                    rowData={rowData}
                    isView={isView}
                    type={type}
                    decimalScale={decimals?.QuantityDecimals ?? 5}
                />}

                {receiptModal &&
                    <W05F0005ReceiptModal
                        isView={isView}
                        rowData={rowData}
                        onOpenReceiptModal={receiptModal}
                        reLoadDataSource={this.reLoadDataSource}
                        onCloseReceiptModal={() => this.onModalPopup(false, "receiptModal")}
                    />}

                {paymentModal &&
                    <W05F0005PaymentModal
                        isView={isView}
                        rowData={rowData}
                        onOpenPaymentModal={paymentModal} onCloseReceiptModal={() => this.onModalPopup(false, "receiptModal")}
                    />}

                {paymentModal &&
                    <W05F0005PaymentModal
                        isView={isView}
                        rowData={rowData}
                        onOpenPaymentModal={paymentModal}
                        reLoadDataSource={this.reLoadDataSource}
                        onClosePaymentModal={() => this.onModalPopup(false, 'paymentModal')}
                    />}

                <CbLoadmore
                    ref={ref => this.popupUnitPack = ref}
                    mode={"single"}
                    // maxWidth={"sm"}
                    keyExpr={"UnitPackID"}
                    title={Config.lang("ERP_Don_vi_det_bao")}
                    onClose={() => {
                        this.props.filterGeneral10.limit = 20;
                        this.props.filterGeneral10.skip = 0;
                    }}
                    filter={filterGeneral10}
                    column={columnUnitPacks}
                    onSave={(data) => (this.onSelectedPopup(data, "UnitPackID"))}
                    // listPerPage={[15, 30, 45, 60]}
                />
                <CbLoadmore
                    ref={ref => this.popupEstimate = ref}
                    mode={"single"}
                    // maxWidth={"sm"}
                    keyExpr={"EstimateID"}
                    title={Config.lang("ERP_Giam_dinh")}
                    onClose={() => {
                        this.props.filterGeneral20.limit = 20;
                        this.props.filterGeneral20.skip = 0;
                    }}
                    filter={filterGeneral20}
                    column={columnEstimate}
                    onSave={(data) => (this.onSelectedPopup(data, "EstimateID"))}
                    // listPerPage={[15, 30, 45, 60]}
                />
                <CbLoadmore
                    ref={ref => this.popupVessel = ref}
                    mode={"single"}
                    // maxWidth={"sm"}
                    keyExpr={"VesselID"}
                    title={Config.lang("ERP_Ma_tau")}
                    onClose={() => {
                        this.props.filterCbVessel.limit = 20;
                        this.props.filterCbVessel.skip = 0;
                    }}
                    filter={filterCbVessel}
                    column={columnVessel}
                    onSave={(data) => (this.onSelectedPopup(data, "VesselID"))}
                    // listPerPage={[15, 30, 45, 60]}
                />
                <CbLoadmore
                    ref={ref => this.popupVesselCont = ref}
                    mode={"single"}
                    // maxWidth={"sm"}
                    keyExpr={"VesselID"}
                    title={Config.lang("ERP_Ma_tau")}
                    onClose={() => {
                        this.props.filterCbVessel.limit = 20;
                        this.props.filterCbVessel.skip = 0;
                    }}
                    filter={filterCbVessel}
                    column={columnVessel}
                    onSave={(data) => (this.onSelectedPopup(data, "VesselIDCont"))}
                    // listPerPage={[15, 30, 45, 60]}
                />
                <CbLoadmore
                    ref={ref => this.popupShippingAgent1 = ref}
                    mode={"single"}
                    // maxWidth={"sm"}
                    keyExpr={"ObjectID"}
                    title={Config.lang("ERP_Dai_ly_tau")}
                    onClose={(data) => {
                        this.props.filterShippingAgent1.limit = 20;
                        this.props.filterShippingAgent1.skip = 0;
                    }}
                    filter={filterShippingAgent1}
                    column={columnShippingAgent1}
                    onSave={(data) => this.onSelectedPopup(data, "ObjectID")}
                    // listPerPage={[15, 30, 45, 60]}
                />
                <CbLoadmore
                    ref={ref => this.popupShippingAgent2 = ref}
                    mode={"single"}
                    // maxWidth={"sm"}
                    keyExpr={"ObjectIDCont"}
                    title={Config.lang("ERP_Dai_ly_tau")}
                    onClose={(data) => {
                        this.props.filterShippingAgent2.limit = 20;
                        this.props.filterShippingAgent2.skip = 0;
                    }}
                    filter={filterShippingAgent2}
                    column={columnShippingAgent2}
                    onSave={(data) => this.onSelectedPopup(data, "ObjectIDCont")}
                    // listPerPage={[15, 30, 45, 60]}
                />
                <div style={{ maxHeight: 540, height: 540 }}>
                    <TabContent
                        style={{ paddingTop: 15, paddingBottom: 15, backgroundColor: '#fff' }}
                        activeKey={EStatus}
                        disableSwipe={true}
                        lazyLoading={true}
                    >
                        <TabPanel index={0}>
                            <React.Fragment>
                                <GridContainer
                                    reference={ref => this.gridTab0 = ref}
                                    dataSource={dataGrid}
                                    columnAutoWidth={true}
                                    loading={isLoadForm}
                                    elementAttr={{
                                        class: "noBGSelect",
                                    }}
                                    // onDbCellClick={e => console.log(e)}
                                    listPerPage={[15, 30, 45, 60]}
                                    typePaging={"normal"}
                                    height={460}
                                    typeShort={true}
                                    keyExpr={"QuotationItemID"}
                                    showColumnLines={false}
                                    showRowLines={false}
                                    hoverStateEnabled={true}
                                    allowCellSelection={true}
                                    rowAlternationEnabled={true}
                                    pagerFullScreen={false}
                                    allowColumnResizing
                                    columnResizingMode='widget'
                                    editing={{
                                        mode: "cell",
                                        refreshMode: "reshape",
                                        allowUpdating: !isView,
                                        startEditAction: "click",
                                        texts: {
                                            confirmDeleteMessage: "",
                                        },
                                    }}
                                    // onRowClick={this.onRowClick}
                                    onCellClick={this.onRowClick}
                                    sorting={{
                                        mode: "none"
                                    }}
                                    onKeyDown={(e) => {
                                        Config.onEnterAndTabKeyNavigation(e, this.selectedRange, this.gridTab0,
                                            (rowIndex) => this.selectedRange.startRowIndex = rowIndex
                                            , (columnIndex) => this.selectedRange.startColumnIndex = columnIndex);
                                    }}
                                    onRowUpdated={(e) => {
                                        e.data.IsUpdated = 1;
                                        if (this.isCalc) {
                                            this.props.onRefW05F0005.setQuantity();
                                            this.isCalc = false;
                                        }
                                        this.props.onRefW05F0005.setIsUpdate();
                                        if (this.gridTab0 && this.gridTab0.instance) {
                                            const {startRowIndex, startColumnIndex} = this.selectedRange;
                                            const dataField                         = ["InventoryID", "ItemNameU", "Amount", "CAmount", "UnitPackID", "EstimateID", "OriginalReduce", "CReduce", "ObjectName", "PackingMethodName", "PackingTypeName", "MarkingName", "RateReduce", "DeliveryQuantity"];
                                            let currentFieldName                    = this.gridTab0.instance.getVisibleColumns()[startColumnIndex]?.dataField;
                                            if (!dataField.includes(currentFieldName) && !_.isUndefined(currentFieldName)) {
                                                setTimeout(() => this.gridTab0.instance.editCell(startRowIndex, startColumnIndex), 100);
                                            }
                                        }
                                    }}
                                    onEditorPreparing={(e) => {
                                        //Min Max cho ngày Từ Và Đến
                                        const currentRow = e.row?.data;
                                        if (e.dataField === "DeliveryDateFrom" && currentRow && currentRow.DeliveryDateTo) {
                                            e.editorOptions.max = currentRow.DeliveryDateTo;
                                        } else if (e.dataField === "DeliveryDateTo" && currentRow && currentRow.DeliveryDateFrom) {
                                            e.editorOptions.min = currentRow.DeliveryDateFrom;
                                        }
                                    }}
                                    onRowUpdating={(e) => {
                                        const newKeys = Object.keys(e.newData);
                                        if (newKeys.find(k => k.includes("ACol")) || newKeys.includes("UnitPrice")) {
                                            let AdjustedUnitPrice = Number(newKeys.includes("UnitPrice") ? e.newData.UnitPrice  : e.oldData.UnitPrice);
                                            for (let col of dataAdjustColumns) {
                                                if (col.Display === 1) {
                                                    if (newKeys.includes(col.ColID)) {
                                                        AdjustedUnitPrice += Number(e.newData[col.ColID]);
                                                        e.newData["C_" + col.ColID] = e.newData[col.ColID] * ExchangeRate;
                                                    } else if (e.oldData[col.ColID]) {
                                                        AdjustedUnitPrice += Number(e.oldData[col.ColID]);
                                                    }
                                                }
                                            }
                                            e.newData.AdjustedUnitPrice = AdjustedUnitPrice;
                                        }
                                        if (!_.isUndefined(e.newData.Quantity)) {
                                            if (e.newData.Quantity < e.oldData.QuantityMapping) {
                                                e.newData.Quantity = e.oldData.Quantity;
                                                Config.popup.show("INFO", `${Config.lang("ERP_Thong_bao_lot_da_duoc_mapping")} ${e.oldData.QuantityMapping}`);
                                            } else {
                                                this.isCalc = true;
                                                e.newData.Amount = e.newData.Quantity * e.oldData.AdjustedUnitPrice;
                                                e.newData.CAmount = e.newData.Quantity * e.oldData.AdjustedUnitPrice * ExchangeRate;
                                                e.newData.OriginalReduce = e.newData.Amount * (e.oldData.RateReduce ? e.oldData.RateReduce : 0);
                                                e.newData.CReduce = e.newData.CAmount * (e.oldData.RateReduce ? e.oldData.RateReduce : 0);
                                                e.newData.ActualAmount = e.oldData.ActualUnitPrice * e.newData.Quantity;
                                            }
                                        }
                                        if (!_.isUndefined(e.newData.RateReduce)) {
                                            e.newData.OriginalReduce = e.newData.RateReduce * e.oldData.Amount;
                                            e.newData.CReduce = e.newData.RateReduce * e.oldData.Amount * ExchangeRate;
                                            // this.isCalc = true; //  RateReduce không có tính ở Master
                                        }
                                        if (!_.isUndefined(e.newData.UnitPrice)) {
                                            e.newData.OriginalReduce = e.oldData.RateReduce * e.newData.Amount;
                                            e.newData.CReduce = e.oldData.RateReduce * e.newData.Amount * ExchangeRate;
                                            this.isCalc = true; // Có tính master
                                        }
                                        if (Object.keys(e.newData).includes("AdjustedUnitPrice")) {
                                            e.newData.Amount = e.newData.AdjustedUnitPrice * e.oldData.Quantity;
                                            e.newData.CAmount = e.oldData.Quantity * e.newData.AdjustedUnitPrice * ExchangeRate;
                                            e.newData.OriginalReduce = e.newData.Amount * (e.oldData.RateReduce ? e.oldData.RateReduce : 0);
                                            e.newData.CReduce = e.newData.CAmount * (e.oldData.RateReduce ? e.oldData.RateReduce : 0);
                                            this.isCalc = true;
                                        }
                                        if (e.newData.LocationNo) {
                                            if (e.newData.LocationNo === '+') {
                                                e.cancel = true;
                                                this.setOpenLocationNoModal();
                                            }else{
                                                const _LocationNo = cbLocationNo.find(rs => rs.LocationNo === e.newData.LocationNo);
                                                if(_LocationNo){
                                                    e.newData.LocationNoDes = _LocationNo['LocationNoDes'];
                                                }
                                            }
                                        }
                                        if (e.newData.LotNo && e.oldData.fumigation) {
                                            e.oldData.fumigation.detail.map(rs => {
                                                rs.LotNo = e.newData.LotNo;
                                                return rs;
                                            })
                                            e.oldData.fumigation.method.map(rs => {
                                                rs.LotNo = e.newData.LotNo;
                                                return rs;
                                            })
                                        }
                                        if (e.newData.ActualUnitPrice) {
                                            e.newData.ActualAmount = e.newData.ActualUnitPrice * e.oldData.Quantity;
                                        }
                                        // e.component.endUpdate();
                                    }}
                                    onCellSelectionChanged={(e) => {
                                        let allVisibleColumn = [];
                                        const notEditColumn = ["IsChecked"];
                                        const popColumn = ["UnitPackID", "EstimateID"];
                                        const currentFieldName = this.gridTab0.instance.getVisibleColumns()[e.selectedRange?.startColumnIndex]?.dataField;
                                        if (!notEditColumn.includes(currentFieldName)) {
                                            if (e.selectedRange) this.selectedRange = e.selectedRange;
                                            if (this.gridTab0.instance.getVisibleColumns().length > 0) {
                                                allVisibleColumn = (this.gridTab0.instance.getVisibleColumns().filter(item => (item.visible && item.allowEditing) || popColumn.includes(item.dataField))).map(obj => obj.dataField);
                                            }
                                            if (!e.component.hasEditData() && e.selectedRange && e.selectedRange.rowType !== "header"
                                                && e.selectedRange.rowType !== "" && !isView && allVisibleColumn.includes(currentFieldName)) {
                                                if (JSON.stringify(this.state.selectedRange) !== JSON.stringify(e.selectedRange)) {
                                                    // this.selectedRange = e.selectedRange;
                                                    if (!_.isUndefined(currentFieldName)) {
                                                        this.setState({ selectedRange: e.selectedRange }, () => {
                                                            const dataField = ["InventoryID", "ItemNameU", "Amount", "CAmount", "UnitPackID", "EstimateID", "OriginalReduce", "CReduce", "ObjectName"];
                                                            if (!dataField.includes(currentFieldName) && this.gridTab0) {
                                                                setTimeout(() => {
                                                                    this.gridTab0.instance.editCell(e.selectedRange.startRowIndex, e.selectedRange.startColumnIndex)
                                                                }, 100);
                                                            }
                                                        });
                                                    }
                                                }
                                            }
                                        }
                                    }}
                                    // onCellPrepared={e => {
                                    //     if (e.row?.data?.IsDifferentLot === 1) {
                                    //         e.cellElement.style.pointerEvents = 'none';
                                    //         e.cellElement.style.opacity = 0.5;
                                    //     }
                                    // }}
                                >
                                    {/* CỘT CHECK CỦA TASK [EW-107] - KHÔNG ĐƯỢC XÓA */}
                                    <Column
                                        minWidth={50}
                                        visible={btnCombineLot && isEdit}
                                        allowEditing={false}
                                        alignment={"center"}
                                        dataField={"IsChecked"}
                                        caption={Config.lang("ERP_Chon")}
                                        cellRender={this.cellRenderRadio}
                                        cssClass={`${classes.columnGrid} ${classes.radioButton}`}
                                        fixed={btnCombineLot}
                                        fixedPosition='left'
                                    />
                                    {/* CỘT CHECK CỦA TASK [EW-107] - KHÔNG ĐƯỢC XÓA */}
                                    <Column
                                        allowEditing={allowUpdating || btnCombineLot}
                                        caption={Config.lang("ERP_Ma_lot")}
                                        dataField={"LotNo"}
                                        headerCellRender={btnCombineLot && this.renderHeaderColumns}
                                        minWidth={150}
                                        cssClass={classes.hiddenRedoBtn}
                                    />
                                    <Column
                                        caption={Config.lang("ERP_Ma_hang")}
                                        dataField={"InventoryID"}
                                        allowEditing={false}
                                        minWidth={150}
                                    />
                                    <Column
                                        caption={Config.lang("ERP_Ten_hang")}
                                        dataField={"ItemNameU"}
                                        allowEditing={false}
                                        minWidth={200}
                                    />
                                     <Column
                                        caption={Config.lang("Ten_hang_thuc_te")}
                                        dataField={"ParcelInventoryID"}
                                        allowEditing={false}
                                        minWidth={200}
                                        cellRender={(cellData) => this.cellRenderPopupAction(cellData, allowUpdating)}
                                    />
                                      <Column
                                        dataField={"ParcelInventoryName"}
                                        visible={false}
                                    />
                                    <Column
                                        allowEditing={allowUpdating}
                                        caption={Config.lang("ERP_So_luong")}
                                        dataField={"Quantity"}
                                        dataType={"number"}
                                        // editorOptions={{
                                        //     min: 0,
                                        //     showClearButton: false,
                                        //     format: {
                                        //         type: 'fixedPoint',
                                        //         precision: decimals && decimals.QuantityDecimals ? decimals.QuantityDecimals : 5,
                                        //     },
                                        // }}
                                        alignment={"right"}
                                        minWidth={150}
                                        headerCellRender={this.renderHeaderColumns}
                                        cssClass={classes.removeBorder}
                                        cellRender={this.renderNumberFormat}
                                        editCellRender={e => {
                                            setTimeout(() => e.component.focus(e.cellElement), 100);
                                            return (
                                                <NumberFormat
                                                    className={classes.numberInput}
                                                    customInput={TextField}
                                                    style={{ marginBottom: -8, marginTop: -8, backgroundColor: 'transparent' }}
                                                    thousandSeparator={true}
                                                    decimalScale={decimals && decimals.QuantityDecimals ? decimals.QuantityDecimals : 5}
                                                    value={String(e.value) || ""}
                                                    onValueChange={rs => e.setValue(Number(rs.value))}
                                                    // fixedDecimalScale
                                                    onFocus={e => e.target.select()}
                                                />
                                            )
                                        }}
                                    />
                                    <Column
                                        caption={Config.lang("ERP_So_luong_da_giao")}
                                        dataField={"DeliveryQuantity"}
                                        allowEditing={false}
                                        width={150}
                                    />
                                    <Column
                                        caption={Config.lang("ERP_Don_gia")}
                                        allowEditing={allowUpdating}
                                        dataField={"UnitPrice"}
                                        editCellRender={e => {
                                            setTimeout(() => e.component.focus(e.cellElement), 100);
                                            return (
                                                <NumberFormat
                                                    className={classes.numberInput}
                                                    customInput={TextField}
                                                    style={{ marginBottom: -8, marginTop: -8, backgroundColor: 'transparent' }}
                                                    thousandSeparator={true}
                                                    decimalScale={decimals?.OriginalDecimal ?? 0}
                                                    value={String(e.value) || ""}
                                                    onValueChange={rs => e.setValue(Number(rs.value))}
                                                    // fixedDecimalScale
                                                    onFocus={e => e.target.select()}
                                                />
                                            )
                                        }}
                                        minWidth={150}
                                        cellRender={this.renderNumberFormat}
                                    />
                                    <Column
                                        caption={Config.lang("ERP_Don_gia_dieu_chinh")}
                                        dataField={"AdjustedUnitPrice"}
                                        allowEditing={false}
                                        width={150}
                                        cellRender={this.renderNumberFormat}
                                    />
                                    {dataAdjustColumns && dataAdjustColumns.map((col, idx) => {
                                        if (!col?.Display || col?.Display === 0) return null;
                                        return <Column
                                            key={idx}
                                            caption={col?.ColDescription || ""}
                                            allowEditing={allowUpdating}
                                            dataField={col?.ColID}
                                            dataType={"number"}
                                            editCellRender={e => {
                                                setTimeout(() => e.component.focus(e.cellElement), 100);
                                                return (
                                                    <NumberFormat
                                                        className={classes.numberInput}
                                                        customInput={TextField}
                                                        style={{ marginBottom: -8, marginTop: -8, backgroundColor: 'transparent' }}
                                                        thousandSeparator={true}
                                                        decimalScale={decimals?.OriginalDecimal ?? 0}
                                                        value={String(e.value) || ""}
                                                        onValueChange={rs => e.setValue(Number(rs.value))}
                                                        onFocus={e => e.target.select()}
                                                        // fixedDecimalScale
                                                    />
                                                )
                                            }}
                                            cellRender={this.renderNumberFormat}
                                            minWidth={150}
                                        />;
                                    })}
                                    <Column
                                        caption={Config.lang("ERP_Gia_tri_lot")}
                                        dataField={"Amount"}
                                        allowEditing={false}
                                        width={150}
                                        cellRender={this.renderNumberFormat}
                                    />
                                    <Column
                                        caption={Config.lang("ERP_Gia_tri_lot_QD")}
                                        dataField={"CAmount"}
                                        allowEditing={false}
                                        width={200}
                                        cellRender={this.renderNumberFormat}
                                    />
                                    <Column
                                        caption={Config.lang("Don_gia_thuc_te")}
                                        dataField={"ActualUnitPrice"}
                                        allowEditing={allowUpdating}
                                        dataType={"number"}
                                        editCellRender={e => {
                                            setTimeout(() => e.component.focus(e.cellElement), 100);
                                            return (
                                                <NumberFormat
                                                    className={classes.numberInput}
                                                    customInput={TextField}
                                                    style={{ marginBottom: -8, marginTop: -8, backgroundColor: 'transparent' }}
                                                    thousandSeparator={true}
                                                    decimalScale={decimals?.OriginalDecimal ?? 0}
                                                    value={String(e.value) || ""}
                                                    onValueChange={rs => e.setValue(Number(rs.value))}
                                                />
                                            )
                                        }}
                                        cellRender={this.renderNumberFormat}
                                        visible={isPerW05F2004 > 3}
                                        minWidth={150}
                                    />
                                    <Column
                                        caption={Config.lang("So_tien_thuc_te")}
                                        dataField={"ActualAmount"}
                                        allowEditing={false}
                                        dataType={"number"}
                                        cellRender={this.renderNumberFormat}
                                        minWidth={150}
                                        visible={isPerW05F2004 > 3}
                                    />
                                    <Column
                                        dataField={"StatusDetail"}
                                        width={50}
                                        visible={false} />
                                    <Column
                                        allowEditing={allowUpdating}
                                        caption={Config.lang("ERP_Trang_thai")}
                                        dataField={"StatusDetailName"}
                                        width={200}
                                        // lookup={{
                                        //     dataSource: cbStatusD,
                                        //     valueExpr: "Status",
                                        //     displayExpr: "StatusName"
                                        // }}
                                        editCellRender={(e) => {
                                            const selectedItem = cbStatusD.filter((c) => {
                                                return c.Status === e.data.StatusDetail;
                                            });
                                            return (
                                                <Combo
                                                    dataSource={cbStatusD}
                                                    displayExpr={"StatusName"}
                                                    stylingMode={"underlined"}
                                                    margin={"dense"}
                                                    cssClass={classes.dateBoxGrid}
                                                    className={"no-margin"}
                                                    style={{ backgroundColor: "transparent" }}
                                                    shrink={true}
                                                    placeholder={Config.lang("ERP_Chon")}
                                                    value={selectedItem.length > 0 ? selectedItem[0] : null}
                                                    onValueChanged={(rs) => {
                                                        e.setValue(rs.value && rs.value.StatusName ? rs.value.StatusName : "");
                                                        e.component.cellValue(e.rowIndex, "StatusDetail", rs.value && rs.value.Status ? rs.value.Status : "");
                                                    }}
                                                />
                                            );
                                        }}
                                        headerCellRender={this.renderHeaderColumns}
                                    />
                                    <Column
                                        caption={Config.lang("ERP_Thoi_gian_giao_hang_du_kien")}
                                        dataField={"dateFormTo"}
                                        alignment={"center"}
                                        width={300} >
                                        <Column
                                            allowEditing={allowUpdating}
                                            caption={Config.lang("ERP_Tu")}
                                            dataField={"DeliveryDateFrom"}
                                            format={'dd/MM/yyyy'}
                                            alignment={'center'}
                                            dataType={"date"}
                                            width={150}
                                            editorOptions={{
                                                placeholder: "DD/MM/YYYY",
                                                allowUpdating: allowUpdating,
                                                dateSerializationFormat: 'yyyy-MM-dd'
                                            }}
                                            headerCellRender={this.renderHeaderColumns}
                                        />
                                        <Column
                                            allowEditing={allowUpdating}
                                            caption={Config.lang("ERP_Den")}
                                            dataField={"DeliveryDateTo"}
                                            format={"dd/MM/yyyy"}
                                            alignment={"center"}
                                            dataType={"date"}
                                            width={150}
                                            editorOptions={{
                                                placeholder: "DD/MM/YYYY",
                                                allowUpdating: allowUpdating,
                                                dateSerializationFormat: 'yyyy-MM-dd'
                                            }}
                                            headerCellRender={this.renderHeaderColumns}
                                        />
                                    </Column>
                                    <Column
                                        dataField={"PackingMethodID"}
                                        width={50}
                                        visible={false} />
                                    <Column
                                        allowEditing={allowUpdating}
                                        caption={Config.lang("ERP_Quy_cach_dong_goi")}
                                        dataField={"PackingMethodName"}
                                        // lookup={{
                                        //     dataSource: cbPackingMethod,
                                        //     valueExpr: "PackingMethodID",
                                        //     displayExpr: "PackingMethodName"
                                        // }}
                                        editCellRender={(e) => {
                                            const selectedItem = cbPackingMethod.filter((c) => {
                                                return c.PackingMethodName === e.value;
                                            });
                                            return (
                                                <Combo
                                                    dataSource={cbPackingMethod}
                                                    displayExpr={"PackingMethodName"}
                                                    stylingMode={"underlined"}
                                                    margin={"dense"}
                                                    cssClass={classes.dateBoxGrid}
                                                    className={"no-margin"}
                                                    style={{ backgroundColor: "transparent" }}
                                                    shrink={true}
                                                    placeholder={Config.lang("ERP_Chon")}
                                                    value={selectedItem.length > 0 ? selectedItem[0] : null}
                                                    onValueChanged={(rs) => {
                                                        e.setValue(rs.value && rs.value.PackingMethodName ? rs.value.PackingMethodName : "");
                                                        e.component.cellValue(e.rowIndex, "PackingMethodID", rs.value && rs.value.PackingMethodID ? rs.value.PackingMethodID : "");
                                                    }}
                                                />
                                            );
                                        }}
                                        headerCellRender={this.renderHeaderColumns}
                                        width={200}
                                    />
                                    <Column
                                        dataField={"PackingTypeID"}
                                        width={50}
                                        visible={false} />
                                    <Column
                                        allowEditing={allowUpdating}
                                        caption={Config.lang("ERP_Loai_bao_dong_goi")}
                                        dataField={"PackingTypeName"}
                                        // lookup={{
                                        //     dataSource: cbPackingType,
                                        //     valueExpr: "PackingTypeID",
                                        //     displayExpr: "PackingTypeName"
                                        // }}
                                        editCellRender={(e) => {
                                            const selectedItem = cbPackingType.filter((c) => {
                                                return c.PackingTypeName === e.value;
                                            });
                                            return (
                                                <Combo
                                                    dataSource={cbPackingType}
                                                    displayExpr={"PackingTypeName"}
                                                    stylingMode={"underlined"}
                                                    margin={"dense"}
                                                    className={"no-margin"}
                                                    style={{ backgroundColor: "transparent" }}
                                                    shrink={true}
                                                    placeholder={Config.lang("ERP_Chon")}
                                                    value={selectedItem.length > 0 ? selectedItem[0] : null}
                                                    itemRender={rs => {
                                                        return (
                                                            <span title={rs.PackingTypeName} style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'block', overflow: 'hidden' }}>{rs.PackingTypeName}</span>
                                                        )
                                                    }}
                                                    wrapItemText={false}
                                                    onValueChanged={(rs) => {
                                                        e.setValue(rs.value && rs.value.PackingTypeName ? rs.value.PackingTypeName : "");
                                                        e.component.cellValue(e.rowIndex, "PackingTypeID", rs.value && rs.value.PackingTypeID ? rs.value.PackingTypeID : "");
                                                    }}
                                                />
                                            );
                                        }}
                                        headerCellRender={this.renderHeaderColumns}
                                        width={250}
                                    />
                                    <Column
                                        dataField={"MarkingID"}
                                        width={50}
                                        visible={false} />
                                    <Column
                                        allowEditing={allowUpdating}
                                        caption={Config.lang("ERP_Nhan_mac")}
                                        dataField={"MarkingName"}
                                        // lookup={{
                                        //     dataSource: cbMarking,
                                        //     valueExpr: "MarkingID",
                                        //     displayExpr: "MarkingName"
                                        // }}
                                        editCellRender={(e) => {
                                            const selectedItem = cbMarking.filter((c) => {
                                                return c.MarkingName === e.value;
                                            });
                                            return (
                                                <Combo
                                                    dataSource={cbMarking}
                                                    displayExpr={"MarkingName"}
                                                    stylingMode={"underlined"}
                                                    margin={"dense"}
                                                    cssClass={classes.dateBoxGrid}
                                                    className={"no-margin"}
                                                    style={{ backgroundColor: "transparent" }}
                                                    shrink={true}
                                                    placeholder={Config.lang("ERP_Chon")}
                                                    value={selectedItem.length > 0 ? selectedItem[0] : null}
                                                    itemRender={rs => {
                                                        return (
                                                            <span title={rs.MarkingName} style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'block', overflow: 'hidden' }}>{rs.MarkingName}</span>
                                                        )
                                                    }}
                                                    wrapItemText={false}
                                                    onValueChanged={(rs) => {
                                                        e.setValue(rs.value && rs.value.MarkingName ? rs.value.MarkingName : "");
                                                        e.component.cellValue(e.rowIndex, "MarkingID", rs.value && rs.value.MarkingID ? rs.value.MarkingID : "");
                                                    }}
                                                />
                                            );
                                        }}
                                        width={250}
                                        headerCellRender={this.renderHeaderColumns}
                                    />
                                    <Column
                                        allowEditing={allowUpdating}
                                        caption={Config.lang("ERP_Thong_tin_bao_bi")}
                                        dataField={"PackNote"}
                                        cssClass={classes.hiddenRedoBtn}
                                        headerCellRender={this.renderHeaderColumns}
                                        minWidth={250}
                                    />
                                    <Column
                                        dataField={"UnitPackName"}
                                        width={10}
                                        visible={false}
                                    />
                                    <Column
                                        caption={Config.lang("ERP_Don_vi_det_bao")}
                                        dataField={"UnitPackID"} width={200}
                                        headerCellRender={this.renderHeaderColumns}
                                        cellRender={(cellData) => this.cellRenderPopupAction(cellData, allowUpdating)}
                                        allowEditing={false}
                                    />
                                     <Column
                                        caption={Config.lang("Thoi_gian_giao_bao")}
                                        width={150}
                                        dataField="ParkingDeliveryDate"
                                        format={"dd/MM/yyyy"}
                                        dataType={"date"}
                                        editorOptions={{
                                            placeholder: "DD/MM/YYYY",
                                            allowUpdating: allowUpdating,
                                            dateSerializationFormat: 'yyyy-MM-dd'
                                        }}
                                        allowEditing={allowUpdating}
                                        alignment="center"
                                    />
                                    <Column
                                        dataField={"EstimateName"} width={10} visible={false}
                                    />
                                    <Column
                                        caption={Config.lang("ERP_Giam_dinh")}
                                        dataField={"EstimateID"}
                                        width={200}
                                        headerCellRender={this.renderHeaderColumns}
                                        cellRender={(cellData) => this.cellRenderPopupAction(cellData, allowUpdating)}
                                        allowEditing={false}
                                    />
                                    <Column
                                        caption={Config.lang("Cong_ty_bao_hiem")}
                                        dataField={"InsuranceID"} width={200}
                                        cellRender={(cellData) => this.cellRenderPopupAction(cellData, allowUpdating)}
                                        allowEditing={false}
                                    />
                                    <Column dataField={"InsuranceName"} width={10} visible={false} />
                                    <Column
                                        caption={Config.lang("Phuong_thuc_thanh_toan")}
                                        dataField={"PaymentMethodID"}
                                        width={200}
                                        lookup={{
                                            dataSource: getCboPaymentMenthod,
                                            displayExpr: 'PaymentMethodName',
                                            valueExpr: 'PaymentMethodID'
                                        }}
                                        allowEditing={allowUpdating}
                                    />
                                    <Column dataField={"GuaranteeName"} width={10} visible={false} />
                                    <Column dataField={"GuaranteeTypeID"} width={10} visible={false} />
                                    <Column
                                        caption={Config.lang("Don_vi_uy_thac")}
                                        dataField={"GuaranteeID"}
                                        cellRender={(cellData) => this.cellRenderPopupAction(cellData, allowUpdating)}
                                        allowEditing={false}
                                        width={250}
                                    />
                                    <Column
                                        allowEditing={allowUpdating}
                                        caption={Config.lang("ERP_Ty_le_deposit")}
                                        dataField={"RateReduce"}
                                        dataType={"number"}
                                        // editorOptions={{
                                        //     min: 0,
                                        //     showClearButton: true,
                                        //     format: {
                                        //         type: 'fixedPoint',
                                        //         precision: decimals && decimals.ExchangeRateDecimals ? decimals.ExchangeRateDecimals : 2,
                                        //     },
                                        // }}
                                        minWidth={150}
                                        headerCellRender={this.renderHeaderColumns}
                                        cellRender={this.renderNumberFormat}
                                        cssClass={classes.removeBorder}
                                        editCellRender={e => {
                                            setTimeout(() => {
                                                e.component.focus(e.cellElement)
                                            }, 100);
                                            return (
                                                <NumberFormat
                                                    className={classes.numberInput}
                                                    style={{ marginBottom: -8, marginTop: -8, backgroundColor: 'transparent' }}
                                                    customInput={TextField}
                                                    thousandSeparator={true}
                                                    decimalScale={decimals?.OriginalDecimal ?? 0}
                                                    value={String(e.value) || ""}
                                                    onValueChange={rs => e.setValue(Number(rs.value))}
                                                    // fixedDecimalScale
                                                    onFocus={e => e.target.select()}
                                                />
                                            )
                                        }}

                                    />
                                    <Column
                                        caption={Config.lang("ERP_Thanh_tien_deposit")}
                                        dataField={"OriginalReduce"}
                                        allowEditing={allowUpdating}
                                        dataType={"number"}
                                        editCellRender={e => {
                                            setTimeout(() => {
                                                e.component.focus(e.cellElement)
                                            }, 100);
                                            return (
                                                <NumberFormat
                                                    className={classes.numberInput}
                                                    style={{ marginBottom: -8, marginTop: -8, backgroundColor: 'transparent' }}
                                                    customInput={TextField}
                                                    thousandSeparator={true}
                                                    decimalScale={decimals?.OriginalDecimal ?? 0}
                                                    value={String(e.value) || ""}
                                                    onValueChange={rs => e.setValue(Number(rs.value))}
                                                />
                                            )
                                        }}
                                        width={150}
                                        cellRender={this.renderNumberFormat}
                                    />
                                    <Column
                                        caption={Config.lang("ERP_Thanh_tien_deposit_QD")}
                                        dataField={"CReduce"}
                                        allowEditing={allowUpdating}
                                        dataType={"number"}
                                        format={{
                                            type: "decimal",
                                            min: 0
                                        }}
                                        editCellRender={e => {
                                            setTimeout(() => {
                                                e.component.focus(e.cellElement)
                                            }, 100);
                                            return (
                                                <NumberFormat
                                                    className={classes.numberInput}
                                                    style={{ marginBottom: -8, marginTop: -8, backgroundColor: 'transparent' }}
                                                    customInput={TextField}
                                                    thousandSeparator={true}
                                                    decimalScale={decimals?.ConvertedDecimal ?? 2}
                                                    value={String(e.value) || ""}
                                                    onValueChange={rs => e.setValue(Number(rs.value))}
                                                />
                                            )
                                        }}
                                        cellRender={this.renderNumberFormat}
                                        width={200}
                                    />
                                    <Column
                                        allowEditing={allowUpdating}
                                        caption={Config.lang("Ghi_chu")}
                                        dataField={'NotesU'}
                                        headerCellRender={this.renderHeaderColumns}
                                        width={250}
                                    />
                                    <Column
                                        allowEditing={allowUpdating}
                                        caption={Config.lang("Lo")}
                                        dataField={"LocationNo"} width={200}
                                        headerCellRender={this.renderHeaderColumns}
                                        lookup={{
                                            dataSource: cbLocationNo,
                                            displayExpr: 'LocationNoDes',
                                            valueExpr: 'LocationNo'
                                        }}
                                    />
                                    {btnAction &&
                                        <Column
                                            fixed={true}
                                            alignment={"right"}
                                            fixedPosition={"right"}
                                            visible={!Config.isMobile}
                                            cellRender={this.renderButtonAction}
                                            allowEditing={false}
                                        />}
                                </GridContainer>
                            </React.Fragment>
                        </TabPanel>
                        <TabPanel index={1}>
                            <React.Fragment>
                                <GridContainer
                                    reference={ref => this.gridTab1 = ref}
                                    dataSource={dataGrid}
                                    loading={isLoadForm}
                                    columnAutoWidth={true}
                                    elementAttr={{
                                        class: "noBGSelect",
                                    }}
                                    listPerPage={[15, 30, 45, 60]}
                                    typePaging={"normal"}
                                    keyExpr={"QuotationItemID"}
                                    height={460}
                                    typeShort={true}
                                    showColumnLines={false}
                                    showRowLines={false}
                                    hoverStateEnabled={true}
                                    onRowClick={this.onRowClick}
                                    allowCellSelection={true}
                                    rowAlternationEnabled={true}
                                    pagerFullScreen={false}
                                    allowColumnResizing
                                    columnResizingMode='widget'
                                    editing={{
                                        mode: "cell",
                                        refreshMode: "reshape",
                                        allowUpdating: true,
                                        startEditAction: "click",
                                        texts: {
                                            confirmDeleteMessage: "",
                                        },
                                    }}
                                    sorting={{
                                        mode: "none"
                                    }}
                                    onKeyDown={(e) => {
                                        Config.onEnterAndTabKeyNavigation(e, this.selectedRange, this.gridTab1,
                                            (rowIndex) => this.selectedRange.startRowIndex = rowIndex
                                            , (columnIndex) => this.selectedRange.startColumnIndex = columnIndex);
                                    }}
                                    onRowUpdated={(e) => {
                                        e.data.IsUpdated = 1;
                                        this.props.onRefW05F0005.setIsUpdate();
                                        const { startRowIndex, startColumnIndex } = this.selectedRange;
                                        const dataField = ["InventoryID", "ItemNameU", "Amount", "CAmount", "UnitPackID", "EstimateID", "OriginalReduce", "CReduce", "ObjectName"];
                                        const currentFieldName = this.gridTab1.instance.getVisibleColumns()[startColumnIndex]?.dataField;
                                        if (!dataField.includes(currentFieldName)) {
                                            setTimeout(() => this.gridTab1?.instance.editCell(startRowIndex, startColumnIndex), 100);
                                        }
                                    }}
                                    onCellSelectionChanged={(e) => {
                                        const notEditColumn = ["IsChecked"];
                                        const currentFieldName = this.gridTab1.instance.getVisibleColumns()[e.selectedRange?.startColumnIndex]?.dataField;
                                        let allVisibleColumn = [];
                                        if (!notEditColumn.includes(currentFieldName)) {
                                            if (e.selectedRange) this.selectedRange = e.selectedRange;
                                            if (this.gridTab1.instance.getVisibleColumns().length > 0) {
                                                allVisibleColumn = (this.gridTab1.instance.getVisibleColumns().filter(item => item.visible && item.allowEditing)).map(obj => obj.dataField);
                                            }
                                            if (!_.isUndefined(currentFieldName) && !e.component.hasEditData() && e.selectedRange && e.selectedRange.rowType !== "header" && e.selectedRange.rowType !== "" && allVisibleColumn.includes(currentFieldName)) {
                                                if (JSON.stringify(this.state.selectedRange) !== JSON.stringify(e.selectedRange)) {
                                                    this.setState({ selectedRange: e.selectedRange });
                                                    // this.selectedRange = e.selectedRange;
                                                }
                                            }
                                        }
                                    }}
                                    // onCellPrepared={e => {
                                    //     if (e.row?.data?.IsDifferentLot === 1) {
                                    //         e.cellElement.style.pointerEvents = 'none';
                                    //         e.cellElement.style.opacity = 0.5;
                                    //     }
                                    // }}
                                >
                                    {/* CỘT CHECK CỦA TASK [EW-107] - KHÔNG ĐƯỢC XÓA */}
                                    <Column
                                        minWidth={50}
                                        visible={btnCombineLot && isEdit}
                                        allowEditing={false}
                                        alignment={"center"}
                                        dataField={"IsChecked"}
                                        caption={Config.lang("ERP_Chon")}
                                        cellRender={this.cellRenderRadio}
                                        cssClass={`${classes.columnGrid} ${classes.radioButton}`}
                                        fixed={btnCombineLot}
                                        fixedPosition='left'
                                    />
                                    {/* CỘT CHECK CỦA TASK [EW-107] - KHÔNG ĐƯỢC XÓA */}
                                    <Column
                                        allowEditing={allowUpdating || btnCombineLot}
                                        caption={Config.lang("ERP_Ma_lot")}
                                        dataField={"LotNo"}
                                        headerCellRender={btnCombineLot && this.renderHeaderColumns}
                                        minWidth={150}
                                        cssClass={classes.hiddenRedoBtn}
                                    />
                                    <Column
                                        caption={Config.lang("ERP_Ma_hang")}
                                        dataField={"InventoryID"}
                                        allowEditing={false}
                                        minWidth={150}
                                    />
                                    <Column
                                        caption={Config.lang("ERP_Ten_hang")}
                                        dataField={"ItemNameU"}
                                        allowEditing={false}
                                        minWidth={150}
                                    />
                                    {tabDetailSupplement.filter(rs => rs.Disabled !== 1).map((item, index) => (
                                        <Column
                                            allowEditing={allowUpdating}
                                            key={index}
                                            caption={item.Caption}
                                            dataField={item.InfoID}
                                            dataType={"number"}
                                            headerCellRender={this.renderHeaderColumns}
                                            cellRender={this.renderNumberFormat} />
                                    ))}
                                    {btnAction &&
                                        <Column
                                            fixed={true}
                                            alignment={"right"}
                                            fixedPosition={"right"}
                                            visible={!Config.isMobile}
                                            cellRender={this.renderButtonAction}
                                            allowEditing={false}
                                        />}
                                </GridContainer>
                            </React.Fragment>
                        </TabPanel>
                        <TabPanel index={2}>
                            <React.Fragment>
                                <GridContainer
                                    reference={ref => this.gridTab2 = ref}
                                    dataSource={dataGrid}
                                    loading={isLoadForm}
                                    elementAttr={{
                                        class: "noBGSelect",
                                        // style: "max-height: 500px"
                                    }}
                                    columnAutoWidth={true}
                                    listPerPage={[15, 30, 45, 60]}
                                    typePaging={"normal"}
                                    height={460}
                                    keyExpr={"QuotationItemID"}
                                    typeShort={true}
                                    showColumnLines={false}
                                    showRowLines={false}
                                    hoverStateEnabled={true}
                                    onRowClick={this.onRowClick}
                                    allowCellSelection={true}
                                    rowAlternationEnabled={true}
                                    pagerFullScreen={false}
                                    allowColumnResizing
                                    columnResizingMode='widget'
                                    editing={{
                                        mode: "cell",
                                        refreshMode: "reshape",
                                        allowUpdating: true,
                                        startEditAction: "click",
                                        texts: {
                                            confirmDeleteMessage: "",
                                        },
                                    }}
                                    sorting={{
                                        mode: "none"
                                    }}
                                    onKeyDown={(e) => {
                                        Config.onEnterAndTabKeyNavigation(e, this.selectedRange, this.gridTab2,
                                            (rowIndex) => this.selectedRange.startRowIndex = rowIndex
                                            , (columnIndex) => this.selectedRange.startColumnIndex = columnIndex);
                                    }}
                                    onRowUpdated={(e) => {
                                        e.data.IsUpdated = 1;
                                        this.props.onRefW05F0005.setIsUpdate();
                                        const { startRowIndex, startColumnIndex } = this.selectedRange;
                                        const dataField = ["InventoryID", "ItemNameU", "Amount", "CAmount", "UnitPackID", "EstimateID", "OriginalReduce", "CReduce", "ObjectID"];
                                        const currentFieldName = this.gridTab2.instance.getVisibleColumns()[startColumnIndex]?.dataField;
                                        if (!dataField.includes(currentFieldName)) {
                                            setTimeout(() => this.gridTab2?.instance.editCell(startRowIndex, startColumnIndex), 100);
                                        }
                                    }}
                                    onCellSelectionChanged={(e) => {
                                        const currentFieldName = this.gridTab2.instance.getVisibleColumns()[e.selectedRange?.startColumnIndex]?.dataField;
                                        let allVisibleColumn = [];
                                        const modalField = ["ObjectID"];
                                        const notEditColumn = ["IsChecked"];
                                        if (!notEditColumn.includes(currentFieldName)) {
                                            if (e.selectedRange) this.selectedRange = e.selectedRange;
                                            if (this.gridTab2.instance.getVisibleColumns().length > 0) {
                                                allVisibleColumn = (this.gridTab2.instance.getVisibleColumns().filter(item => item.visible && item.allowEditing)).map(obj => obj.dataField);
                                            }
                                            if (e.component.hasEditData() && !modalField.includes(currentFieldName)) {
                                                this.gridTab2.instance.saveEditData(); // Fix lỗi không xóa được data lần đầu tiên
                                            }
                                            if (!_.isUndefined(currentFieldName) && !e.component.hasEditData() && e.selectedRange && e.selectedRange.rowType !== "header" && e.selectedRange.rowType !== "" && allVisibleColumn.includes(currentFieldName)) {
                                                if (JSON.stringify(this.state.selectedRange) !== JSON.stringify(e.selectedRange)) {
                                                    this.setState({ selectedRange: e.selectedRange });
                                                    // this.selectedRange = e.selectedRange;
                                                }
                                            }
                                        }
                                    }}
                                    // onCellPrepared={e => {
                                    //     if (e.row?.data?.IsDifferentLot === 1) {
                                    //         e.cellElement.style.pointerEvents = 'none';
                                    //         e.cellElement.style.opacity = 0.5;
                                    //     }
                                    // }}
                                >
                                    {/* CỘT CHECK CỦA TASK [EW-107] - KHÔNG ĐƯỢC XÓA */}
                                    <Column
                                        minWidth={50}
                                        visible={btnCombineLot && isEdit}
                                        allowEditing={false}
                                        alignment={"center"}
                                        dataField={"IsChecked"}
                                        caption={Config.lang("ERP_Chon")}
                                        cellRender={this.cellRenderRadio}
                                        cssClass={`${classes.columnGrid} ${classes.radioButton}`}
                                        fixed={btnCombineLot}
                                        fixedPosition='left'
                                    />
                                    {/* CỘT CHECK CỦA TASK [EW-107] - KHÔNG ĐƯỢC XÓA */}
                                    <Column
                                        allowEditing={allowUpdating || btnCombineLot}
                                        caption={Config.lang("ERP_Ma_lot")}
                                        dataField={"LotNo"}
                                        headerCellRender={btnCombineLot && this.renderHeaderColumns}
                                        minWidth={150}
                                        cssClass={classes.hiddenRedoBtn}
                                    />
                                    <Column
                                        caption={Config.lang("ERP_Ma_hang")}
                                        dataField={"InventoryID"}
                                        allowEditing={false}
                                        minWidth={150}
                                    />
                                    <Column
                                        caption={Config.lang("ERP_Ten_hang")}
                                        dataField={"ItemNameU"}
                                        allowEditing={false}
                                        minWidth={150}
                                    />
                                    {/* Ẩn cột Ma_tau */}
                                    {/* <Column
                                    caption={Config.lang("ERP_Ma_tau")}
                                    dataField={"VesselID"}
                                    headerCellRender={this.renderHeaderColumns}
                                    cellRender={(cellData) => this.cellRenderPopupAction(cellData,allowUpdating)}
                                    allowEditing={false}
                                    width={150}
                                /> */}
                                    {/* Ẩn cột Ma_tau */}
                                    <Column
                                        allowEditing={allowUpdating}
                                        caption={Config.lang("ERP_Ten_tau")}
                                        dataField={"VesselName"}
                                        minWidth={180}
                                        cssClass={classes.hiddenRedoBtn}
                                        headerCellRender={this.renderHeaderColumns}
                                    />
                                    <Column
                                        dataField={"ObjectName"}
                                        width={10}
                                        visible={false}
                                    />
                                    <Column
                                        caption={Config.lang("ERP_Dai_ly_tau")}
                                        dataField={"ObjectID"}
                                        width={180}
                                        headerCellRender={this.renderHeaderColumns}
                                        cellRender={(cellData) => this.cellRenderPopupAction(cellData, allowUpdating)}
                                        allowEditing={false}
                                    />
                                    <Column
                                        allowEditing={allowUpdating}
                                        caption={Config.lang("ERP_Ngay_tau_vao")}
                                        dataField={"DateVesselIn"}
                                        format={"dd/MM/yyyy"}
                                        alignment={"center"}
                                        dataType={"date"}
                                        width={150}
                                        editorOptions={{
                                            placeholder: "DD/MM/YYYY",
                                            allowUpdating: allowUpdating,
                                            dateSerializationFormat: 'yyyy-MM-dd'
                                        }}
                                        headerCellRender={this.renderHeaderColumns}
                                    />
                                    <Column
                                        caption={Config.lang("ERP_Ngay_tau_di")}
                                        dataField={"DateVesselOut"}
                                        format={"dd/MM/yyyy"}
                                        alignment={"center"}
                                        dataType={"date"}
                                        width={150}
                                        editorOptions={{
                                            placeholder: "DD/MM/YYYY",
                                            allowUpdating: allowUpdating,
                                            dateSerializationFormat: 'yyyy-MM-dd'
                                        }}
                                        headerCellRender={this.renderHeaderColumns}
                                    />
                                    <Column dataField={"PortToBoat"} width={50} visible={false} />
                                    <Column
                                        allowEditing={allowUpdating}
                                        caption={Config.lang("ERP_Cang_di")}
                                        dataField={"PortToBoatName"}
                                        // lookup={{
                                        //     dataSource: cbPort,
                                        //     valueExpr: "PortID",
                                        //     displayExpr: "PortName"
                                        // }}
                                        editCellRender={(e) => {
                                            const selectedItem = cbPort.filter((c) => {
                                                return c.PortName === e.value;
                                            });
                                            return (
                                                <Combo
                                                    dataSource={cbPort}
                                                    displayExpr={"PortName"}
                                                    stylingMode={"underlined"}
                                                    margin={"dense"}
                                                    cssClass={classes.dateBoxGrid}
                                                    className={"no-margin"}
                                                    style={{ backgroundColor: "transparent" }}
                                                    shrink={true}
                                                    placeholder={Config.lang("ERP_Chon")}
                                                    value={selectedItem.length > 0 ? selectedItem[0] : null}
                                                    onValueChanged={(rs) => {
                                                        e.setValue(rs.value && rs.value.PortName ? rs.value.PortName : "");
                                                        e.component.cellValue(e.rowIndex, "PortToBoat", rs.value && rs.value.PortID ? rs.value.PortID : "");
                                                    }}
                                                />
                                            );
                                        }}
                                        headerCellRender={this.renderHeaderColumns}
                                        width={150}
                                    />
                                    <Column dataField={"PortFromBoat"} width={50} visible={false} />
                                    <Column
                                        allowEditing={allowUpdating}
                                        caption={Config.lang("ERP_Cang_den")}
                                        dataField={"PortFromBoatName"}
                                        // lookup={{
                                        //     dataSource: cbPort,
                                        //     valueExpr: "PortID",
                                        //     displayExpr: "PortName"
                                        // }}
                                        editCellRender={(e) => {
                                            const selectedItem = cbPort.filter((c) => {
                                                return c.PortName === e.value;
                                            });
                                            return (
                                                <Combo
                                                    dataSource={cbPort}
                                                    displayExpr={"PortName"}
                                                    stylingMode={"underlined"}
                                                    margin={"dense"}
                                                    cssClass={classes.dateBoxGrid}
                                                    className={"no-margin"}
                                                    style={{ backgroundColor: "transparent" }}
                                                    shrink={true}
                                                    placeholder={Config.lang("ERP_Chon")}
                                                    value={selectedItem.length > 0 ? selectedItem[0] : null}
                                                    onValueChanged={(rs) => {
                                                        e.setValue(rs.value && rs.value.PortName ? rs.value.PortName : "");
                                                        e.component.cellValue(e.rowIndex, "PortFromBoat", rs.value && rs.value.PortID ? rs.value.PortID : "");
                                                    }}
                                                />
                                            );
                                        }}
                                        headerCellRender={this.renderHeaderColumns}
                                        width={150}
                                    />
                                    {btnAction &&
                                        <Column
                                            fixed={true}
                                            alignment={"right"}
                                            fixedPosition={"right"}
                                            visible={!Config.isMobile}
                                            cellRender={(e) => this.renderButtonAction(e, "grid2")}
                                            allowEditing={false}
                                        />}
                                </GridContainer>
                            </React.Fragment>
                        </TabPanel>
                        <TabPanel index={3}>
                            <React.Fragment>
                                <GridContainer
                                    reference={ref => this.gridTab3 = ref}
                                    dataSource={dataGrid}
                                    loading={isLoadForm}
                                    elementAttr={{
                                        class: "noBGSelect",
                                    }}
                                    typeShort={true}
                                    showRowLines={false}
                                    columnAutoWidth={true}
                                    hoverStateEnabled={true}
                                    allowCellSelection={true}
                                    rowAlternationEnabled={true}
                                    listPerPage={[15, 30, 45, 60]}
                                    typePaging={"normal"}
                                    height={460}
                                    keyExpr={"QuotationItemID"}
                                    onRowClick={this.onRowClick}
                                    pagerFullScreen={false}
                                    allowColumnResizing
                                    columnResizingMode='widget'
                                    editing={{
                                        mode: "cell",
                                        refreshMode: "reshape",
                                        allowUpdating: true,
                                        startEditAction: "click",
                                        texts: {
                                            confirmDeleteMessage: "",
                                        },
                                    }}
                                    sorting={{
                                        mode: "none"
                                    }}
                                    onKeyDown={(e) => {
                                        Config.onEnterAndTabKeyNavigation(e, this.selectedRange, this.gridTab3,
                                            (rowIndex) => this.selectedRange.startRowIndex = rowIndex
                                            , (columnIndex) => this.selectedRange.startColumnIndex = columnIndex);
                                    }}
                                    onRowUpdated={(e) => {
                                        e.data.IsUpdated = 1;
                                        this.props.onRefW05F0005.setIsUpdate();
                                        const { startRowIndex, startColumnIndex } = this.selectedRange;
                                        const dataField = ["InventoryID", "ItemNameU", "Amount", "CAmount", "UnitPackID", "EstimateID", "OriginalReduce", "CReduce", "ObjectName", "ObjectIDCont", "DateActual", "PortToContName", "PortFromContName"];
                                        const currentFieldName = this.gridTab3.instance.getVisibleColumns()[startColumnIndex]?.dataField;
                                        if (!dataField.includes(currentFieldName) && !_.isUndefined(currentFieldName)) {
                                            setTimeout(() => this.gridTab3?.instance.editCell(startRowIndex, startColumnIndex), 100);
                                        }
                                    }}
                                    onCellSelectionChanged={(e) => {
                                        const currentFieldName = this.gridTab3.instance.getVisibleColumns()[e.selectedRange?.startColumnIndex]?.dataField;
                                        let allVisibleColumn = [];
                                        const notEditColumn = ["IsChecked"];
                                        const modalField = ["ObjectIDCont"];
                                        if (!notEditColumn.includes(currentFieldName)) {
                                            if (e.selectedRange) this.selectedRange = e.selectedRange;
                                            if (this.gridTab3.instance.getVisibleColumns().length > 0) {
                                                allVisibleColumn = (this.gridTab3.instance.getVisibleColumns().filter(item => item.visible && item.allowEditing)).map(obj => obj.dataField);
                                            }
                                            if (e.component.hasEditData() && !modalField.includes(currentFieldName)) {
                                                this.gridTab3.instance.saveEditData(); // Fix lỗi không xóa được data lần đầu tiên
                                            }
                                            if (!_.isUndefined(currentFieldName) && !e.component.hasEditData() && e.selectedRange && e.selectedRange.rowType !== "header" && e.selectedRange.rowType !== "" && allVisibleColumn.includes(currentFieldName)) {
                                                if (JSON.stringify(this.state.selectedRange) !== JSON.stringify(e.selectedRange)) {
                                                    this.setState({ selectedRange: e.selectedRange });
                                                    // this.selectedRange = e.selectedRange;
                                                }
                                            }
                                        }
                                    }}
                                    // onCellPrepared={e => {
                                    //     if (e.row?.data?.IsDifferentLot === 1) {
                                    //         e.cellElement.style.pointerEvents = 'none';
                                    //         e.cellElement.style.opacity = 0.5;
                                    //     }
                                    // }}
                                >
                                    {/* CỘT CHECK CỦA TASK [EW-107] - KHÔNG ĐƯỢC XÓA */}
                                    <Column
                                        minWidth={50}
                                        visible={btnCombineLot && isEdit}
                                        allowEditing={false}
                                        alignment={"center"}
                                        dataField={"IsChecked"}
                                        caption={Config.lang("ERP_Chon")}
                                        cellRender={this.cellRenderRadio}
                                        cssClass={`${classes.columnGrid} ${classes.radioButton}`}
                                        fixed={btnCombineLot}
                                        fixedPosition='left'
                                    />
                                    {/* CỘT CHECK CỦA TASK [EW-107] - KHÔNG ĐƯỢC XÓA */}
                                    <Column
                                        allowEditing={allowUpdating || btnCombineLot}
                                        caption={Config.lang("ERP_Ma_lot")}
                                        dataField={"LotNo"}
                                        headerCellRender={btnCombineLot && this.renderHeaderColumns}
                                        minWidth={150}
                                        cssClass={classes.hiddenRedoBtn}
                                    />
                                    <Column
                                        caption={Config.lang("ERP_Ma_hang")}
                                        dataField={"InventoryID"}
                                        allowEditing={false}
                                        minWidth={150}
                                    />
                                    <Column
                                        caption={Config.lang("ERP_Ten_hang")}
                                        dataField={"ItemNameU"}
                                        allowEditing={false}
                                        minWidth={150}
                                    />
                                    <Column
                                        allowEditing={allowUpdating}
                                        caption={Config.lang("ERP_Ma_booking")}
                                        dataField={"BookingNo"}
                                        headerCellRender={this.renderHeaderColumns}
                                        minWidth={150}
                                        cssClass={classes.hiddenRedoBtn}
                                    />
                                    <Column
                                        allowEditing={allowUpdating}
                                        caption={Config.lang("ERP_So_luong_di_cua_booking")}
                                        dataField={"QuantityBooking"}
                                        dataType={"number"}
                                        headerCellRender={this.renderHeaderColumns}
                                        minWidth={200}
                                        cellRender={this.renderNumberFormat}
                                    />
                                     <Column dataField={"PortToCont"}
                                        width={50}
                                        visible={false} />
                                    <Column
                                        allowEditing={allowUpdating}
                                        caption={Config.lang("ERP_Cang_di")}
                                        dataField={"PortToContName"}
                                        // lookup={{
                                        //     dataSource: cbPort,
                                        //     valueExpr: "PortID",
                                        //     displayExpr: "PortName"
                                        // }}
                                        editCellRender={(e) => {
                                            const selectedItem = cbPort.filter((c) => {
                                                return c.PortName === e.value;
                                            });
                                            return (
                                                <Combo
                                                    dataSource={cbPort}
                                                    displayExpr={"PortName"}
                                                    stylingMode={"underlined"}
                                                    margin={"dense"}
                                                    cssClass={classes.dateBoxGrid}
                                                    className={"no-margin"}
                                                    style={{ backgroundColor: "transparent" }}
                                                    shrink={true}
                                                    placeholder={Config.lang("ERP_Chon")}
                                                    value={selectedItem.length > 0 ? selectedItem[0] : null}
                                                    onValueChanged={(rs) => {
                                                        e.setValue(rs.value && rs.value.PortName ? rs.value.PortName : "");
                                                        e.component.cellValue(e.rowIndex, "PortToCont", rs.value && rs.value.PortID ? rs.value.PortID : "");
                                                    }}
                                                />
                                            );
                                        }}
                                        headerCellRender={this.renderHeaderColumns}
                                        width={150}
                                    />
                                    <Column dataField={"PortFromCont"} width={50} visible={false} />
                                    <Column
                                        allowEditing={allowUpdating}
                                        caption={Config.lang("ERP_Cang_den")}
                                        dataField={"PortFromContName"}
                                        // lookup={{
                                        //     dataSource: cbPort,
                                        //     valueExpr: "PortID",
                                        //     displayExpr: "PortName"
                                        // }}
                                        editCellRender={(e) => {
                                            const selectedItem = cbPort.filter((c) => {
                                                return c.PortName === e.value;
                                            });
                                            return (
                                                <Combo
                                                    dataSource={cbPort}
                                                    displayExpr={"PortName"}
                                                    stylingMode={"underlined"}
                                                    margin={"dense"}
                                                    cssClass={classes.dateBoxGrid}
                                                    className={"no-margin"}
                                                    style={{ backgroundColor: "transparent" }}
                                                    shrink={true}
                                                    placeholder={Config.lang("ERP_Chon")}
                                                    value={selectedItem.length > 0 ? selectedItem[0] : null}
                                                    onValueChanged={(rs) => {
                                                        e.setValue(rs.value && rs.value.PortName ? rs.value.PortName : "");
                                                        e.component.cellValue(e.rowIndex, "PortFromCont", rs.value && rs.value.PortID ? rs.value.PortID : "");
                                                    }}
                                                />
                                            );
                                        }}
                                        headerCellRender={this.renderHeaderColumns}
                                        width={150}
                                    />
                                    <Column
                                        allowEditing={allowUpdating}
                                        caption={Config.lang("ERP_Ngay_thuc_te_booking")}
                                        dataField={"DateActual"}
                                        format={"dd/MM/yyyy"}
                                        alignment={"center"}
                                        dataType={"date"}
                                        width={200}
                                        editorOptions={{
                                            placeholder: "DD/MM/YYYY",
                                            allowUpdating: allowUpdating,
                                            dateSerializationFormat: 'yyyy-MM-dd'
                                        }}
                                        headerCellRender={this.renderHeaderColumns}
                                    />
                                    {/* Ẩn cột mã tàu */}
                                    {/* <Column
                                    caption={Config.lang("ERP_Ma_tau")}
                                    dataField={"VesselIDCont"}
                                    headerCellRender={this.renderHeaderColumns}
                                    width={150}
                                /> */}
                                    {/* Ẩn cột mã tàu */}
                                    <Column
                                        allowEditing={allowUpdating}
                                        caption={Config.lang("ERP_Ten_tau")}
                                        dataField={"VesselNameCont"}
                                        minWidth={200}
                                        cssClass={classes.hiddenRedoBtn}
                                        headerCellRender={this.renderHeaderColumns}
                                    />
                                    <Column
                                        dataField={"ObjectNameCont"}
                                        width={10}
                                        visible={false}
                                    />
                                    <Column
                                        caption={Config.lang("ERP_Dai_ly_tau")}
                                        dataField={"ObjectIDCont"}
                                        width={180}
                                        headerCellRender={this.renderHeaderColumns}
                                        cellRender={(cellData) => this.cellRenderPopupAction(cellData, allowUpdating)}
                                        allowEditing={false}
                                    />
                                    <Column
                                        allowEditing={allowUpdating}
                                        caption={Config.lang("ERP_Trong_luong_cont")}
                                        dataField={"ContWeight"}
                                        dataType={"number"}
                                        headerCellRender={this.renderHeaderColumns}
                                        minWidth={150}
                                        cssClass={classes.hiddenRedoBtn}
                                    />
                                    {btnAction &&
                                        <Column
                                            fixed={true}
                                            alignment={"right"}
                                            fixedPosition={"right"}
                                            visible={!Config.isMobile}
                                            cellRender={(e) => this.renderButtonAction(e, "grid3")}
                                            allowEditing={false}
                                        />}
                                </GridContainer>
                            </React.Fragment>
                        </TabPanel>
                    </TabContent>
                </div>
                {dataGrid.length > 0 && <ListIconAction onOpenLOT={this.onOpenLOT} onModalPopup={this.onModalPopup} {...this.props} ref={ref => this.popoverGrid = ref} />}
            </>
        );
    }
}

const ListIconAction = React.memo(React.forwardRef((props, ref) => {
    const { classes, isView, isEdit, isAdd, onOpenLOT, onModalPopup } = props;
    const popoverGrid = React.useRef(null);
    const [rowData, setRowData] = React.useState(null);

    React.useImperativeHandle(ref, () => ({
        show: (target, data) => {
            if (target && popoverGrid.current) {
                popoverGrid.current.instance.show(target);
                setRowData(data);
            }
        },
        hide: () => {
            popoverGrid.current.instance.hide();
        }
    }))

    const btnTachLot = isAdd || (isEdit && rowData?.IsMapping === 0);
    const btnYeuCauTachLot = (isView || isEdit) && rowData?.IsMapping === 1;
    return (
        <PopoverAction
            reference={ref => popoverGrid.current = ref}
            position={Config.isMobile ? "right" : "left"}
            maxWidth={300}
            deferRendering={false}>
            <div style={{ margin: '5px 10px' }}>
                {btnTachLot &&
                    <Tooltip className={"text-left"} title={Config.lang("ERP_Tach_lot")}>
                        <ButtonGeneral
                            className={classes.divActionName}
                            aria-label={Config.lang("ERP_Tach_lot")}
                            color={"default"}
                            variant={"custom"}
                            onClick={() => onOpenLOT("LOT")}
                            name={Config.lang("ERP_Tach_lot")} />
                    </Tooltip>
                }
                {btnYeuCauTachLot &&
                    <Tooltip className={"text-left"}
                        title={Config.lang("ERP_Yeu_cau_tach_lot")}>
                        <ButtonGeneral
                            className={classes.divActionName}
                            aria-label={Config.lang("ERP_Yeu_cau_tach_lot")}
                            color={"default"}
                            variant={"custom"}
                            onClick={() => onOpenLOT("Request_LOT")}
                            name={Config.lang("ERP_Yeu_cau_tach_lot")} />
                    </Tooltip>
                }
                <Tooltip className={"text-left"}
                    title={Config.lang("ERP_Phun_trung")}>
                    <ButtonGeneral
                        className={classes.divActionName}
                        aria-label={Config.lang("ERP_Phun_trung")}
                        color={"default"}
                        variant={"custom"}
                        onClick={() => onOpenLOT("PhunTrung")}
                        name={Config.lang("ERP_Phun_trung")} />
                </Tooltip>
                <Tooltip className={"text-left"}
                    title={Config.lang("ERP_Cap_nhat_chung_tu")}>
                    <ButtonGeneral
                        className={classes.divActionName}
                        color={"default"}
                        variant={"custom"}
                        onClick={() => onModalPopup(true, "receiptModal")}
                        name={Config.lang("ERP_Cap_nhat_chung_tu")} />
                </Tooltip>
                <Tooltip className={"text-left"}
                    title={Config.lang("ERP_Thong_tin_thanh_toan_lot")}>
                    <ButtonGeneral
                        className={classes.divActionName}
                        color={"default"}
                        variant={"custom"}
                        onClick={() => onModalPopup(true, "paymentModal")}
                        name={Config.lang("ERP_Thong_tin_thanh_toan_lot")} />
                </Tooltip>
            </div>
        </PopoverAction>
    )
}))

export default compose(
    connect((state) => ({
        dataAdjustColumns: state.W05F0005.dataAdjustColumns,
        getCboPaymentMenthod: state.W05F0005.getCboPaymentMenthod,
        }),
        dispatch => ({
            W05F0005Actions: bindActionCreators(W05F0005Actions, dispatch)
        })
    ), withStyles(styles, { withTheme: true }))(W05F0005TabContent);
