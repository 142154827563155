import { Tab, Tabs } from "@material-ui/core";
import React, { Component } from 'react';
import Config from '../../../../config';
class W05F0005Tabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            indexTab: 0,
        }
    }
    renderTab = () => {
        const { funcGetEStatus } = this.props;
        const tabStatus = [{
            EStatus: 0,
            EStatusName: "ERP_Thong_tin_chung"
        },
        {
            EStatus: 1,
            EStatusName: "ERP_Thong_tin_phu"
        },
        {
            EStatus: 2,
            EStatusName: "ERP_Di_tau"
        },
        {
            EStatus: 3,
            EStatusName: "ERP_Di_cont"
        }];
        return tabStatus.map(({ EStatus, EStatusName }, i) => {
            return <Tab
                key={i}
                value={EStatus}
                label={Config.lang(EStatusName)}
                onClick={() => funcGetEStatus(EStatus)}
            />
        });
    };
    handleChanged = (event, value) => {
        this.setState({ indexTab: value });
    };

    render() {
        let { indexTab } = this.state;
        return (
            <>
                <Tabs
                    indicatorColor={"primary"}
                    textColor={"primary"}
                    value={indexTab}
                    variant={"scrollable"}
                    scrollButtons={Config.isMobile ? "on" : "off"}
                    onChange={this.handleChanged}
                >
                    {this.renderTab()}
                </Tabs>
            </>
        );
    }
}

export default W05F0005Tabs;