import React, { Component } from 'react';
import Config from '../../../../config';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as W05F0005Actions from '../../../../redux/W0X/W05F0005/W05F0005_actions';
import { Column } from "devextreme-react/data-grid";
import GridContainer from "../../../grid-container/grid-container";
import Divider from "@material-ui/core/Divider";
import Modal                   from '../../../common/modal/modal';
import ButtonGeneral           from "../../../common/button/button-general";
import { FormGroup, Row, Col } from "react-bootstrap";
import { FormLabel as Label }  from "@material-ui/core";
import { TextField }           from "@material-ui/core";
import InputAdornment          from "@material-ui/core/InputAdornment";
import SearchIcon              from '@material-ui/icons/Search';
import Typography              from "@material-ui/core/Typography";
import _                       from 'lodash';
import NumberFormat            from "react-number-format";
class W05F0005Popup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            oldData: [],
            totalDataGrid: 0,
            selectedRowKeys: [],
            filterGridValue: [],
        }
        this.filter = {
            StrSearch: '',
            ContractID: '',
            QuotationID: '',
            skip: 0,
            limit: 20,
        }
        this.tmpSelectedRowKeys = [];
        this.tmpSelectedRowData = [];
        this.timer = null;
    }
    componentDidMount() {
        let { isView } = this.props;
        let selectedRowKeys = this.props.selectedRowKeys ? this.props.selectedRowKeys : this.state.selectedRowKeys;
        let dataGrid = this.props.dataGrid ? this.props.dataGrid : this.state.dataGrid;
        if (!isView) {
            this.filter.ContractID = this.props.ContractID;
            this.filter.QuotationID = this.props.QuotationID;
        }
        this.getListInventories(selectedRowKeys, dataGrid);
    }
    // componentDidUpdate(preProps) {
    //     if (preProps.opend !== this.props.opend) {
    //         this.getListInventories();
    //     }
    // }

    getListInventories = (selectedRowKeys, dataGrid) => {
        let { isEdit, isCopy } = this.props;
        this.setState({ loading: true })
        this.props.W05F0005Actions.getListInventories(this.filter, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            let rowKeys = selectedRowKeys.length && selectedRowKeys ? selectedRowKeys : [];
            if (isEdit || isCopy) {
                dataGrid.map(item => {
                    let isPush = rowKeys.indexOf(item.SalesItemID) > -1;
                    if (!isPush) {
                        rowKeys.push(item.SalesItemID);
                    }
                    return item;
                })
            }
            this.setState({ dataGrid: data.rows, totalDataGrid: data.total, loading: false, selectedRowKeys: rowKeys });

        });
    }
    getDataAll = async (data) => {
        let StatusDetailName = this.props.cbStatusD.find(rs => rs.Status === '1');
        await data.forEach((val, index) => {
            val.StatusDetail = '1';
            val.StatusDetailName = _.isUndefined(StatusDetailName) ? '' : StatusDetailName.StatusName;
            val.Amount = val.Quantity * val.UnitPrice;
            val.CAmount = val.Quantity * val.UnitPrice * val.ExchangeRate;
            val.DeliveryDateTo = "";
            val.DeliveryDateFrom = "";
            val.DeliveryDate = "";
            val.UnitPackID = "";
            val.UnitPackName = "";
            val.EstimateID = "";
            val.EstimateName = "";
            val.VesselID = "";
            val.VesselName = "";
            val.ObjectName = "";
            val.DateVesselIn = "";
            val.DateVesselOut = "";
            val.PortToBoat = "";
            val.PortFromBoat = "";
            val.BookingNo = "";
            val.QuantityBooking = "";
            val.DateActual = "";
            val.VesselIDCont = "";
            val.VesselNameCont = "";
            val.ObjectNameCont = "";
            val.ContWeight = "";
            val.PortToCont = "";
            val.PortToContName = "";
            val.PortFromCont = "";
            val.PortFromContName = "";
            val.PackNote = "";
            val.NotesU = "";
            val.PackingTypeID = _.isNull(val.PackingTypeID) ? '' : val.PackingTypeID;
            val.PackingTypeName = _.isNull(val.PackingTypeName) ? '' : val.PackingTypeName;
            val.MarkingID = _.isNull(val.MarkingID) ? '' : val.MarkingID;
            val.MarkingName = _.isNull(val.MarkingName) ? '' : val.MarkingName;
            val.QuotationItemID = index + val.SalesItemID;
            val.PortToBoatName = '';
            val.PortToBoat = '';
            val.PortFromBoatName = '';
            val.PortFromBoat = '';
            val.fumigation = {
                method: [],
                detail: []
            };
            val.PaymentMethodID = '';
            val.LocationNo = '';
            val.LocationNoDes = '';
            val.ActualAmount = '';
            this.tmpSelectedRowKeys.push(val.SalesItemID);
            this.tmpSelectedRowData.push(val);
        })
    }

    onSelectionChanged = async (e) => {
        let isChoose = true;
        await e.selectedRowsData.map(item => {
            if (this.props.selectedRowKeys.indexOf(item['SalesItemID']) <= -1) {
                if (item.Quantity <= 0) {
                    isChoose = false;
                }
            }
            return item;
        });
        if (isChoose) {
            const { selectedRowsData, selectedRowKeys } = e;
            let selectedRowKeyData = selectedRowKeys;
            // console.log(selectedRowKeys)
            if (e.currentSelectedRowKeys.length > 1 || e.selectedRowKeys.length === this.state.dataGrid) { // Check all và filter theo ContractID đầu tiên
                selectedRowKeyData = selectedRowsData.filter(obj => obj.ContractID === selectedRowsData[0].ContractID).map(item => item.SalesItemID);
                const dataFilter = e.selectedRowsData.filter(rs => rs.ContractID === e.selectedRowsData[0]['ContractID']);
                await this.getDataAll(dataFilter);
            } else {
                this.setSelect(e);
            }
            this.setState({
                selectedRowKeys: selectedRowKeyData,
                filterGridValue: selectedRowKeys.length ? ["ContractID", "contains", selectedRowsData[0].ContractID] : []
            });
            const { onSelect } = this.props;
            if (onSelect && selectedRowsData && selectedRowsData.length > 0) {
                onSelect(selectedRowsData[0].ContractID);
            }
        } else {
            Config.popup.show("INFO", Config.lang('ERP_So_luong_khong_duoc_bang_0'));
        }


    };
    setSelect = (e) => {
        const { dataGrid } = this.state;
        const currentSelectedRowKeys = e.currentSelectedRowKeys;
        const currentDeselectedRowKeys = e.currentDeselectedRowKeys;
        if (currentDeselectedRowKeys.length > 0) {
            this.tmpSelectedRowKeys = this.tmpSelectedRowKeys.filter((e) => {
                return currentDeselectedRowKeys.indexOf(e) < 0;
            });
            this.tmpSelectedRowData = this.tmpSelectedRowData.filter((d) => {
                return currentDeselectedRowKeys.indexOf(d.SalesItemID) < 0;
            });
        } else if (currentSelectedRowKeys.length > 0) {
            currentSelectedRowKeys.forEach((val) => {
                if (this.tmpSelectedRowKeys.indexOf(val) < 0) {
                    this.tmpSelectedRowKeys.push(val);
                }
            });
            dataGrid.forEach((val, index) => {
                const isExist = this.tmpSelectedRowData.filter((d) => d.SalesItemID === val.SalesItemID);
                if (this.tmpSelectedRowKeys.indexOf(val.SalesItemID) > -1 && isExist.length < 1) {
                    this.props.tabDetailSupplement && this.props.tabDetailSupplement.map((item) => {
                        return val[item.InfoID] = "";
                    });
                    let StatusDetailName = this.props.cbStatusD.find(rs => rs.Status === '1');
                    // console.log(val)
                    val.StatusDetail = '1';
                    val.StatusDetailName = _.isUndefined(StatusDetailName) ? '' : StatusDetailName.StatusName;
                    val.Amount = val.Quantity * val.UnitPrice;
                    val.CAmount = val.Quantity * val.UnitPrice * val.ExchangeRate;
                    val.DeliveryDateTo = "";
                    val.DeliveryDateFrom = "";
                    val.DeliveryDate = "";
                    val.UnitPackID = "";
                    val.UnitPackName = "";
                    val.EstimateID = "";
                    val.EstimateName = "";
                    val.VesselID = "";
                    val.VesselName = "";
                    val.ObjectName = "";
                    val.DateVesselIn = "";
                    val.DateVesselOut = "";
                    val.PortToBoat = "";
                    val.PortFromBoat = "";
                    val.BookingNo = "";
                    val.QuantityBooking = "";
                    val.DateActual = "";
                    val.VesselIDCont = "";
                    val.VesselNameCont = "";
                    val.ObjectNameCont = "";
                    val.ContWeight = "";
                    val.PortToCont = "";
                    val.PortToContName = "";
                    val.PortFromCont = "";
                    val.PortFromContName = "";
                    val.PackNote = "";
                    val.NotesU = "";
                    val.PackingTypeID = _.isNull(val.PackingTypeID) ? '' : val.PackingTypeID;
                    val.PackingTypeName = _.isNull(val.PackingTypeName) ? '' : val.PackingTypeName;
                    val.MarkingID = _.isNull(val.MarkingID) ? '' : val.MarkingID;
                    val.MarkingName = _.isNull(val.MarkingName) ? '' : val.MarkingName;
                    val.QuotationItemID = index + val.SalesItemID;
                    val.PortToBoatName = '';
                    val.PortToBoat = '';
                    val.PortFromBoatName = '';
                    val.PortFromBoat = '';
                    val.fumigation = {
                        method: [],
                        detail: []
                    };
                    val.PaymentMethodID = '';
                    val.LocationNo = '';
                    val.LocationNoDes = '';
                    val.ActualAmount = '';
                    this.tmpSelectedRowData.push(val);
                }
            });
        }
    }
    onSelect = () => {
        this.props.dataSource(this.tmpSelectedRowData, this.tmpSelectedRowKeys);
        this.props.onClose();
    }
    onFilter = (StrSearch) => {
        this.filter.StrSearch = StrSearch;
        let { isEdit } = this.props;
        let selectedRowKeys = this.props.selectedRowKeys ? this.props.selectedRowKeys : this.state.selectedRowKeys;
        let dataGrid = this.props.dataGrid ? this.props.dataGrid : this.state.dataGrid;
        this.getListInventories(selectedRowKeys, dataGrid, isEdit);
    }
    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        let { isEdit } = this.props;
        let selectedRowKeys = this.props.selectedRowKeys ? this.props.selectedRowKeys : this.state.selectedRowKeys;
        let dataGrid = this.props.dataGrid ? this.props.dataGrid : this.state.dataGrid;
        this.getListInventories(selectedRowKeys, dataGrid, isEdit);
    };

    onChangePerPage = (perPage) => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        let { isEdit } = this.props;
        let selectedRowKeys = this.props.selectedRowKeys ? this.props.selectedRowKeys : this.state.selectedRowKeys;
        let dataGrid = this.props.dataGrid ? this.props.dataGrid : this.state.dataGrid;
        this.getListInventories(selectedRowKeys, dataGrid, isEdit);
    };

    renderNumberFormat = (e) => {
        if (!e) return false;
        const { value } = e || {};
        return <NumberFormat value={value} displayType={"text"} thousandSeparator={true} />
    };

    render() {
        let { dataGrid, totalDataGrid, selectedRowKeys, loading, filterGridValue } = this.state;
        let { opend } = this.props;
        return (
            <div>
                <Modal open={opend}
                    fullWidth={true}
                    maxWidth={'xl'}
                >
                    <Modal.Title disableTypography>
                        <div className={"display_row align-center align-between flex-wrap"} style={{ width: "100%" }}>
                            <div className={"display_row align-center"}>
                                <Typography variant="h6" className={"mgr10"}>{Config.lang("ERP_Danh_sach_hang_hoa_chua_trien_khai")}</Typography>
                            </div>
                            <div>
                                <ButtonGeneral name={Config.lang('ERP_Dong1')}
                                    typeButton={'cancel'}
                                    // disabled={loading}
                                    className={"mgr5"}
                                    style={{ textTransform: 'uppercase' }}
                                    size={"large"}
                                    onClick={() => this.props.onClose()} />
                                <ButtonGeneral name={Config.lang('ERP_Chon')}
                                    color={"primary"}
                                    variant={"contained"}
                                    loading={loading}
                                    style={{ textTransform: 'uppercase' }}
                                    size={"large"}
                                    onClick={this.onSelect} />
                            </div>
                        </div>
                    </Modal.Title>
                    <Divider />
                    <Modal.Content>
                        <FormGroup>
                            <Row >
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <Label className={"mgr5"} style={{ paddingBottom: 10 }}>{Config.lang("ERP_Tim_kiem_hang_hoa")} </Label>
                                    <TextField
                                        variant={"outlined"}
                                        fullWidth
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            clearTimeout(this.timer);
                                            this.timer = setTimeout(() => {
                                                this.onFilter(value);
                                            }, 500)
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        <GridContainer
                            reference={ref => this.dataGrid = ref}
                            totalItems={totalDataGrid}
                            itemPerPage={this.filter.limit}
                            skipPerPage={this.filter.skip}
                            listPerPage={[15, 30, 45, 60]}
                            dataSource={dataGrid}
                            disabled={false}
                            keyExpr={'SalesItemID'}

                            gridProps={{
                                style: {
                                    minHeight: 400,
                                }
                            }}
                            showBorders={false}
                            columnAutoWidth={true}
                            pagerFullScreen={false}
                            allowColumnResizing={true}
                            rowAlternationEnabled={true}
                            typeShort={window.innerWidth < 768}
                            loadPanel={{
                                enabled: loading
                            }}
                            height={"calc(100vh - 250px)"}
                            selection={{
                                allowSelectAll: true,
                                mode: "multiple",
                                selectAllMode: "allPages",
                                showCheckBoxesMode: "always"
                            }}
                            filterRow={{
                                visible: false,
                                showOperationChooser: false,
                            }}
                            filterPanel={{
                                visible: false,
                            }}
                            filterBuilderPopup={{
                                onInitialized: (e) => {
                                    e.component.dispose();
                                    e.element.remove();
                                }
                            }}
                            filterValue={filterGridValue}
                            selectedRowKey={selectedRowKeys}
                            onChangePage={this.onChangePage}
                            onChangePerPage={this.onChangePerPage}
                            onSelectionChanged={this.onSelectionChanged}
                        >
                            <Column
                                dataField={'ContractID'}
                                alignment={"left"}
                                visible={false}
                            />
                            <Column
                                caption={Config.lang("ERP_So_hop_dong")}
                                dataField={'ContractNo'}
                                fixed
                            />
                            <Column
                                caption={Config.lang("ERP_Ma_hang")}
                                dataField={'InventoryID'}
                                fixed
                            />
                            <Column
                                caption={Config.lang("ERP_Ten_hang")}
                                dataField={'ItemNameU'}
                                fixed
                            />
                            <Column
                                caption={Config.lang("ERP_Tong_so_luong")}
                                dataField={'SumQuantity'}
                                cellRender={this.renderNumberFormat}
                            />
                            <Column
                                caption={Config.lang("ERP_So_luong_da_trien_khai")}
                                dataField={'DepQuantity'}
                                cellRender={this.renderNumberFormat}
                            />
                            <Column
                                caption={Config.lang("ERP_So_luong_chua_trien_khai")}
                                dataField={'Quantity'}
                                cellRender={this.renderNumberFormat}
                            />
                            <Column
                                caption={Config.lang("ERP_So_luong_cho_phep")}
                                dataField={'StillAllowQty'}
                                cellRender={this.renderNumberFormat}
                            />
                            <Column
                                caption={Config.lang("ERP_Quy_cach_dong_goi")}
                                dataField={'PackingMethodName'}
                            />
                            <Column
                                caption={Config.lang("ERP_Loai_bao_dong_goi")}
                                dataField={'PackingTypeName'}
                            />
                            <Column
                                caption={Config.lang("ERP_Nhan_mac")}
                                dataField={'MarkingName'}
                            />
                        </GridContainer>
                    </Modal.Content>
                </Modal>

            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        W05F0005Actions: bindActionCreators(W05F0005Actions, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(W05F0005Popup);
