import { FormLabel as Label, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Column, RequiredRule } from "devextreme-react/data-grid";
import _ from "lodash";
import moment from 'moment';
import PropTypes from "prop-types";
import React, { Component } from "react";
import NumberFormat from 'react-number-format';
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as w05f0005Actions from "../../../../redux/W0X/W05F0005/W05F0005_actions";
import Icons from "../../../common/icons/";
import Modal from "../../../common/modal/modal";
import GridActionBar from "../../../grid-container/grid-actionbar";
import GridContainer from "../../../grid-container/grid-container";
import PopoverGrid from "../../../grid-container/popover-grid";

const styles = {
    lotCode: {
        display: 'block',
        textAlign: 'right',
        marginBottom: 15
    },
    divHeaderTitle: {
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontSize: '0.85rem',
        textTransform: 'uppercase',
        color: '#8F9BB3',
    },
    divHeaderItem: {
        marginLeft: 8,
        fontSize: 16,
        fontWeight: '600',
        fontStyle: 'normal',
    },
    divIconItem: {
        padding: 0,
        width: 30,
        height: 30,
        marginRight: 4,
        '&:last-child': {
            marginRight: 0
        }
    },
    numberFormatInput: {
        backgroundColor: 'transparent',
        border: 'none',
        margin: 0
    }
};

class W05F0005PaymentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalPayment: 0,
            btnSaveStatus: true,
            paymentLoaded: false,
            btnActionStatus: false,
            currentRowSelected: {},
            defaultPaymentMethodData: {},
            dataPayment: [],
            paymentPopupAction: null,
            actionRow: null
        };
        this.filterPayment = {
            LotNo: "",
            ContractID: "",
            QuotationID: "",
            PaymentInforID: "",
            QuotationItemID: null
        };
        this.paymentGrid = null;
        this.dataPaymentOld = [];
        this.countPaymentInforID = 0;
        this.selectedRange = {};
    }

    componentDidMount = async () => {
        const { rowData, getFlexCaption, getCboPaymentMenthod, isView } = this.props;
        let dataPayment = rowData.payments;
        if (!_.isEmpty(dataPayment)) {
            dataPayment = dataPayment.map(rs => ({ ...rs })); //Block save data between two datagrid
            const totalPayment = dataPayment.length;
            await dataPayment.forEach((item, i) => {
                if (_.isUndefined(item.keyData)) item["keyData"] = this.countPaymentInforID - (i + 1);
            });
            let maxValueOfKeydata = Math.max.apply(Math, dataPayment.map((obj) => obj.keyData)); //Fin biggest number in keyData
            this.countPaymentInforID = maxValueOfKeydata + 1; //then plus 1 
            this.setState({ dataPayment, totalPayment }, () => {
                this.dataPaymentOld = this.state.dataPayment.map(item => ({ ...item }))
            })
        }
        if (isView) this.setState({ btnActionStatus: true })
        if (_.isEmpty(rowData)) return
        if (_.isEmpty(getFlexCaption)) await this.getFlexCaption();
        if (_.isEmpty(getCboPaymentMenthod)) await this.getPaymentMenthod();
        const { ContractID, QuotationID, QuotationItemID } = rowData;
        this.filterPayment.ContractID = ContractID;
        this.filterPayment.QuotationID = QuotationID;
        if (!_.isUndefined(QuotationItemID)) this.filterPayment.QuotationItemID = QuotationItemID;
    }

    getFlexCaption = () => {
        const GroupInfo = "V";
        const InfoID = "W05V";
        const params = {
            GroupInfo,
            InfoID,
            Language: Config.language || "84"
        };
        // this.setState({ paymentLoaded: true })
        this.props.w05f0005Actions.getFlexCaption(params, (error) => {
            // this.setState({ paymentLoaded: false })
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
        });
    };

    getPaymentMenthod = () => {
        this.props.w05f0005Actions.loadCboPaymentMethod(async (error) => {
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
        });
    };

    getPaymentMethodDefault = async (ContractID, cb) => {
        const params = { ContractID };
        await this.props.w05f0005Actions.getPaymentMethodDefault(params, cb)
    };

    onSavePayment = async () => {
        await this.paymentGrid.instance.saveEditData();
        let dataPaymentsOder = [];
        const { reLoadDataSource, rowData } = this.props;
        const { dataPayment } = this.state;
        const dataPaymentSource = this.paymentGrid.instance.option("dataSource");
        const oldData = this.dataPaymentOld;
        const dataPayments = dataPaymentSource ? dataPaymentSource : dataPayment;
        let dataPaymentAddNew = dataPayments.filter((obj) => !oldData.some((obj2) => obj.PaymentInforID === obj2.PaymentInforID));
        const newestPaymentData = dataPaymentAddNew.reverse();
        dataPaymentsOder = !_.isEmpty(newestPaymentData) ? [...new Set(newestPaymentData.concat(dataPayments))] : dataPayments;
        const data = {
            LotNo: rowData.LotNo,
            QuotationItemID: rowData.QuotationItemID,
            payments: dataPaymentsOder
        }
        await reLoadDataSource(data, "payments");
        if (!this.state.btnSaveStatus) this.setState({ btnSaveStatus: true });
    };

    cellRender = (e, fieldType) => {
        const { data } = e.row;
        const { column } = e;
        const { getDecimal } = this.props;
        if (!data || !column || !column.dataField) return;
        if (fieldType === "number") {
            return <NumberFormat decimalScale={getDecimal.OriginalDecimal || 0} value={_.toNumber(e.value)} displayType={'text'} thousandSeparator={true} />
        }
        let tranFormField = data[column.dataField];
        if (!_.isEmpty(tranFormField) || _.isNumber(tranFormField)) {
            data[column.dataField] = data && (tranFormField || tranFormField === 0) ? tranFormField : "";
        }
        const limitFieldName = (column.dataField).slice(0, parseInt("-" + ((column.dataField).length - 3).toString()));
        const parseDateTime = !_.isEmpty(tranFormField) ? moment.utc(tranFormField).format("DD/MM/YYYY") : moment().format("DD/MM/YYYY");
        if (limitFieldName === "Dat" && !_.isNull(tranFormField) && !_.isNumber(tranFormField)) {
            tranFormField = !_.isUndefined(tranFormField) ? parseDateTime : moment();
        }
        return tranFormField;
    };

    renderPaymentColumn = () => {
        const { getFlexCaption, classes, getDecimal } = this.props;
        const supColumn = getFlexCaption.map((item, i) => {
            const { Caption } = item;
            const width = Caption.length > 20 ? 330 : 150;
            let { FieldName } = item;
            let fieldType = "";
            let formatDate = "";
            let alignment = "left";
            const limitFieldName = FieldName.slice(0, parseInt("-" + (FieldName.length - 3).toString()));
            switch (limitFieldName) {
                case "Num":
                    fieldType = "number";
                    alignment = "right";
                    break;
                case "Dat":
                    fieldType = "date";
                    alignment = "center";
                    formatDate = "dd/MM/yyyy";
                    break;
                case "Str":
                    fieldType = "string";
                    break;
                default:
                    break;
            }
            return (
                <Column
                    key={i}
                    caption={Caption}
                    dataField={FieldName}
                    dataType={fieldType}
                    format={formatDate}
                    width={width}
                    alignment={alignment}
                    // cellRender={(e) => this.cellRender(e, fieldType)}
                    {...(fieldType === "number" ? {
                        cellRender: (e) => (
                            <NumberFormat decimalScale={getDecimal.OriginalDecimal || 0} value={_.toNumber(e.value)} displayType={'text'} thousandSeparator={true} />
                        ),
                        editCellRender: (e) => {
                            return (
                                <NumberFormat
                                    style={{ width: '100%' }}
                                    className={classes.numberFormatInput}
                                    thousandSeparator={true}
                                    decimalScale={getDecimal.OriginalDecimal || 0}
                                    isNumericString={true}
                                    value={e.value}
                                    onValueChange={(c) => e.setValue(_.toNumber(c.value))}
                                />
                            )
                        }
                    } :
                        fieldType === "date" ? {
                            editorOptions: {
                                placeholder: "DD/MM/YYYY",
                                dateSerializationFormat: 'yyyy-MM-dd'
                            }
                        } : {})
                    }
                />
            )
        });
        return supColumn;
    };

    handleClick = (event, data, stateName, actionRow = {}) => {
        const target = event.currentTarget; //can fix popup position bug
        setTimeout(() => this.setState({ [stateName]: target, currentRowSelected: data && data, actionRow }), 0);
    };

    handleClose = (stateName) => {
        this.setState({ [stateName]: null });
    };

    renderIconAction = (e) => {
        const { data } = e.row;
        const { classes } = this.props;
        const { btnActionStatus, paymentPopupAction } = this.state;
        return (
            <IconButton
                aria-describedby={data.PaymentInforID}
                size={'small'}
                aria-label={"view"}
                className={classes.divIconItem}
                onClick={async (a) => {
                    // Dùng để chữa lỗi popup văng lên trời nhưng không phải vì Hover 3 chấm viết có vấn đề
                    if (this.paymentGrid.instance.hasEditData()) await this.paymentGrid.instance.saveEditData();
                    this.handleClick(a, data, "paymentPopupAction", e)
                }}
                disabled={!_.isNull(paymentPopupAction) || btnActionStatus}
            >
                <MoreVertIcon />
            </IconButton>
        );
    };

    onCopyPayment = async (dataSelected) => {
        const { dataPayment, btnSaveStatus } = this.state;
        const dataSelectedCopy = { ...dataSelected };
        dataSelectedCopy.keyData = this.countPaymentInforID++;
        dataSelectedCopy.PaymentInforID = _.toString(dataSelectedCopy.keyData);
        dataPayment.push(dataSelectedCopy);
        if (btnSaveStatus) this.setState({ btnSaveStatus: false });
    };

    showListAction = () => {
        const { classes } = this.props;
        const { currentRowSelected, paymentPopupAction, paymentLoaded, actionRow } = this.state;
        return (
            <>
                {paymentPopupAction !== null && <PopoverGrid
                    id={currentRowSelected.QuotationItemID}
                    anchorEl={paymentPopupAction}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: Config.isMobile ? 'right' : 'left',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: Config.isMobile ? 'left' : 'right',
                    }}
                    onClose={() => this.handleClose("paymentPopupAction")}
                    itemProps={
                        <div style={{ padding: 4 }}>
                            <Tooltip title={Config.lang("ERP_Sao_chep")}>
                                <span>
                                    <IconButton
                                        disabled={paymentLoaded}
                                        aria-label={"copy"}
                                        className={classes.divIconItem}
                                        onClick={() => {
                                            this.handleClose("paymentPopupAction");
                                            if (!_.isEmpty(actionRow.data)) {
                                                this.onCopyPayment(actionRow.data);
                                            }
                                        }}
                                    >
                                        <Icons name={"copy"} />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip title={Config.lang("ERP_Xoa")}>
                                <span>
                                    <IconButton
                                        disabled={paymentLoaded}
                                        aria-label={"delete"}
                                        className={classes.divIconItem}
                                        onClick={() => {
                                            Config.popup.show("YES_NO", Config.lang("ERP_Ban_co_chac_chan_muon_xoa_thong_tin_thanh_toan_nay_khong"), async () => {
                                                this.setState({ paymentPopupAction: null, btnSaveStatus: false }, () => {
                                                    if (_.isEmpty(actionRow)) return;
                                                    setTimeout(() => {
                                                        actionRow.component.deleteRow(actionRow.rowIndex);
                                                    }, 300);
                                                });
                                            });
                                        }}
                                    >
                                        <Icons name={"delete"} />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </div>
                    }
                />}
            </>
        )
    };

    onAddNewRow = async () => {
        // const { defaultPaymentMethodData } = this.state;
        // const { ContractID } = this.filterPayment;
        if (this.paymentGrid) {
            this.setState({ btnActionStatus: true }, () => {
                this.paymentGrid.instance.addRow()
            });
            // if (_.isEmpty(defaultPaymentMethodData)) {
            //     await this.getPaymentMethodDefault(ContractID, (error, data) => {
            //         this.setState({ defaultPaymentMethodData: data, btnActionStatus: true }, () => {
            //             setTimeout(() => { this.paymentGrid.instance.addRow() }, 300);
            //         });
            //     });
            // }
            // else if (!_.isEmpty(defaultPaymentMethodData)) {
            //     this.setState({ btnActionStatus: true }, () => {
            //         setTimeout(() => { this.paymentGrid.instance.addRow() }, 300);
            //     });
            // }
        }
    };

    renderBtnMoreAction = (e, mode) => {
        return (
            mode === "mobile" ?
                this.renderIconAction(e)
                : <GridActionBar>
                    {this.renderIconAction(e)}
                </GridActionBar>
        );
    }

    render() {
        const { dataPayment, totalPayment, paymentLoaded, btnActionStatus, btnSaveStatus } = this.state;
        const { onOpenPaymentModal, onClosePaymentModal, classes, getCboPaymentMenthod, getFlexCaptionName, getFlexCaption, rowData, isView } = this.props;
        const { ContractID, QuotationID, QuotationItemID } = this.filterPayment;
        let rowValidation = {};

        return (
            <Modal
                open={onOpenPaymentModal}
                maxWidth={"lg"}
                fullWidth={true}
                position={"center"}
            >
                <Modal.Title disableTypography>
                    <div className={"display_row align-center align-between flex-wrap"} style={{ width: "100%", margin: '4px 0' }}>
                        <div className={"display_row align-center"}>
                            <Typography variant="h6" className={"mgr10"}>{Config.lang("ERP_Thong_tin_thanh_toan")}</Typography>
                        </div>
                        <div>
                            <IconButton
                                disabled={paymentLoaded || btnActionStatus || btnSaveStatus}
                                aria-label={"save"}
                                size={"small"}
                                className={"mgr10"}
                                onClick={async () => {
                                    if (this.state.btnActionStatus) this.setState({ btnActionStatus: false })
                                    await this.onSavePayment();
                                    onClosePaymentModal(); //CLOSE Popup
                                }}
                            >
                                <Icons name={"save_filled"} />
                            </IconButton>
                            <IconButton
                                aria-label={"close"}
                                size={"small"}
                                onClick={async () => onClosePaymentModal()}
                            >
                                <Icons name={"cancel_filled"} />
                            </IconButton>
                        </div>
                    </div>
                </Modal.Title>
                <Modal.Content style={{ marginBottom: '-6px' }}>
                    <div className={classes.lotCode}>
                        <Label className={classes.divHeaderTitle}
                            {...(!Config.isMobile ? { style: { paddingRight: 8 } } : {})}>
                            {`${Config.lang("ERP_Ma_lot")}:`}
                        </Label>
                        <Label className={classes.divHeaderItem}
                            {...(!Config.isMobile ? { style: { paddingRight: 8 } } : {})}>
                            {Config.lang(rowData.LotNo)}
                        </Label>
                    </div>
                    <GridContainer
                        totalItems={totalPayment}
                        reference={(ref) => (this.paymentGrid = ref)}
                        dataSource={dataPayment}
                        loading={paymentLoaded}
                        showBorders={false}
                        showRowLines={false}
                        columnAutoWidth={true}
                        showColumnLines={false}
                        pagerFullScreen={false}
                        hoverStateEnabled={true}
                        allowCellSelection={true}
                        allowColumnResizing={true}
                        rowAlternationEnabled={true}
                        typeShort={window.innerWidth < 768}
                        typePaging={"normal"}
                        keyExpr={"PaymentInforID"}
                        elementAttr={{ style: 'min-height: 300px; max-height: 520px', class: 'noBGSelect' }}
                        sorting={{ mode: btnActionStatus ? "none" : "single" }}
                        editing={{
                            mode: "cell",
                            refreshMode: "reshape",
                            texts: { confirmDeleteMessage: "" },
                            allowUpdating: !isView,
                            startEditAction: "click",
                        }}
                        onKeyDown={(e) => {
                            Config.onEnterAndTabKeyNavigation(e, this.selectedRange, this.paymentGrid,
                                (rowIndex) => this.selectedRange.startRowIndex = rowIndex
                                , (columnIndex) => this.selectedRange.startColumnIndex = columnIndex);
                        }}
                        onCellSelectionChanged={(e) => {
                            if (e.selectedRange) this.selectedRange = e.selectedRange;
                        }}
                        // onContentReady={(e) => {
                        //     if (!_.isEmpty(rowValidation)) {
                        //         const checkValidateRowAdded = dataPayment.includes(rowValidation.PaymentInforID);
                        //         if (!checkValidateRowAdded && btnActionStatus) this.setState({ btnActionStatus: false }) // Enable Add and Save Btn
                        //     }
                        // }}
                        onRowValidating={(e) => {
                            if (!e.isValid) { //isValidation
                                if (_.isEmpty(rowValidation)) rowValidation = e.newData;
                            } else { //noValidation
                                rowValidation = {};
                            }
                        }}
                        onRowUpdated={(e) => {
                            if (btnActionStatus || btnSaveStatus) this.setState({ btnActionStatus: false, btnSaveStatus: false });
                        }}
                        onRowInserted={(e) => {
                            if (btnActionStatus || btnSaveStatus) this.setState({ btnActionStatus: false, btnSaveStatus: false });
                        }}
                        onInitNewRow={(eMethod) => {
                            eMethod.data.ContractID = ContractID;
                            eMethod.data.QuotationID = QuotationID;
                            eMethod.data.QuotationItemID = QuotationItemID;
                            eMethod.data.keyData = this.countPaymentInforID++;
                            eMethod.data.PaymentInforID = _.toString(eMethod.data.keyData);
                            eMethod.data.PaymentMethodID =  rowData?.PaymentMethodID;
                            if (getFlexCaptionName.length > 0) {
                                getFlexCaptionName.forEach(fieldName => {
                                    const fieldNameObj = getFlexCaption.find(item => item.FieldName === fieldName);
                                    if (fieldName === fieldNameObj.FieldName) {
                                        eMethod.data[fieldName] = fieldNameObj.DataType === 'D' ?
                                            Config.isEmpty(fieldNameObj.ValueDefaultU) ? null : fieldNameObj.ValueDefaultU : fieldNameObj.ValueDefaultU;
                                    }
                                })
                            }
                        }}
                    >
                        <Column
                            dataField={"keyData"}
                            allowSorting={false}
                            sortOrder={"desc"}
                            visible={false}
                            allowEditing={false}
                        />
                        <Column
                            caption={Config.lang("ERP_Hanh_dong")}
                            fixed={true}
                            allowEditing={false}
                            alignment={"center"}
                            visible={Config.isMobile}
                            cellRender={(e) => this.renderBtnMoreAction(e, 'mobile')}
                        />
                        <Column
                            caption={Config.lang("ERP_Ten_thong_tin_thanh_toan")}
                            headerCellRender={(header) => (<> {Config.lang("ERP_Ten_thong_tin_thanh_toan")}
                                <span style={{ color: 'red' }}>&nbsp;*</span> </>)}
                            dataField={"PaymentName"}
                            width={220}>
                            <RequiredRule />
                        </Column>
                        <Column
                            caption={Config.lang("ERP_Phuong_thuc_thanh_toan")}
                            headerCellRender={(header) =>
                            (<> {Config.lang("ERP_Phuong_thuc_thanh_toan")}
                                <span style={{ color: 'red' }}>&nbsp;*</span>
                            </>)}
                            dataField={"PaymentMethodID"}
                            lookup={{
                                dataSource: getCboPaymentMenthod,
                                valueExpr: "PaymentMethodID",
                                displayExpr: "PaymentMethodName",
                            }}
                            width={220}
                            editorOptions={{
                                placeholder: Config.lang("ERP_Chon") + "...",
                            }}>
                            <RequiredRule />
                        </Column>
                        {getFlexCaption.length > 0 && this.renderPaymentColumn()}
                        <Column
                            alignment={"right"}
                            fixed={true}
                            allowEditing={false}
                            fixedPosition={"right"}
                            visible={!Config.isMobile}
                            cellRender={(e) => this.renderBtnMoreAction(e, 'desktop')}
                        />
                    </GridContainer>
                    {dataPayment.length > 0 && this.showListAction()}
                    <IconButton
                        aria-label={"add"}
                        size={"small"}
                        style={{ transform: 'translateY(4px)', margin: '10px 0' }}
                        disabled={btnActionStatus}
                        onClick={() => this.onAddNewRow()}
                    >
                        <Icons name={"plus_filled"} />
                    </IconButton>
                </Modal.Content>
            </Modal>
        );
    }
}

W05F0005PaymentModal.propsTypes = {
    isView: PropTypes.bool,
    onOpenPaymentModal: PropTypes.bool,
    rowData: PropTypes.object,
    reLoadDataSource: PropTypes.func,
    onClosePaymentModal: PropTypes.func,
};

export default compose(connect(state => ({
    getDecimal: state.W05F0005.getDecimal,
    getFlexCaption: state.W05F0005.getFlexCaption,
    getFlexCaptionName: state.W05F0005.getFlexCaptionName,
    getCboPaymentMenthod: state.W05F0005.getCboPaymentMenthod,
}), (dispatch) => ({
    w05f0005Actions: bindActionCreators(w05f0005Actions, dispatch)
})), withStyles(styles))(W05F0005PaymentModal);
